import { isGuid } from 'owa-guid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrentBuilding } from '../utils/setCurrentBuilding';

export function useNavigateBuilding(origin: string) {
    const navigate = useNavigate();

    const navigateToBuilding = React.useCallback((buildingId: string | null | undefined) => {
        if (!buildingId) {
            return false;
        }
        if (isGuid(buildingId)) {
            navigate(`/buildings/${buildingId}`, { state: { origin } });
        } else {
            setCurrentBuilding(buildingId);
        }
        return true;
    }, []);

    return navigateToBuilding;
}
