export const workgroupsTeamsNotAllowedNS = "p6zvme";
export const workgroupsLocationErrorMessageNS = "Mu73ye";
export const workgroupsAtleastOneHostErrorMessageNS = "pB0VRb";
export const workgroupsUserHasToBeHostOrCoordinatorErrorMessageNS = "tL9qK";
export const workgroupsTitleErrorMessageNS = "d_tTd";
export const workgroupsFilesPendingErrorMessageNS = "ziF3Nb";
export const workgroupsInvalidCustomJoinUrlErrorMessageNS = "AsqUwd";
export const workgroupsNoDLsAcceptedInRoleWellsNS = "zVkN4b";
export const workgroupsWarnAboutExternalEmailsInWellNS = "OUymo";
export const workgroupsWarnAboutInvalidEmailsInWellNS = "Ylcsjb";
export const workgroupsWarnAboutTooManyInRoleInWellNS = "meW8dc";
export const workgroupsNameErrorMessageNS = "Ah_oJd";
export const workgroupsAtleastOneAdministratorErrorMessageNS = "EnvAh";
export const workgroupsAtLeastTwoAdministratorsErrorMessageNS = "TeNm5b";
export const workgroupsUserHasToBeAdministratorErrorMessageNS = "L2iKjb";
export const workgroupsDescriptionLengthTooLongErrorMessageNS = "TVky0";
export const xUsersCouldNotBeAddedBecauseOfAdministrator = "DvWDCe";
export default {workgroupsTeamsNotAllowedNS,workgroupsLocationErrorMessageNS,workgroupsAtleastOneHostErrorMessageNS,workgroupsUserHasToBeHostOrCoordinatorErrorMessageNS,workgroupsTitleErrorMessageNS,workgroupsFilesPendingErrorMessageNS,workgroupsInvalidCustomJoinUrlErrorMessageNS,workgroupsNoDLsAcceptedInRoleWellsNS,workgroupsWarnAboutExternalEmailsInWellNS,workgroupsWarnAboutInvalidEmailsInWellNS,workgroupsWarnAboutTooManyInRoleInWellNS,workgroupsNameErrorMessageNS,workgroupsAtleastOneAdministratorErrorMessageNS,workgroupsAtLeastTwoAdministratorsErrorMessageNS,workgroupsUserHasToBeAdministratorErrorMessageNS,workgroupsDescriptionLengthTooLongErrorMessageNS,xUsersCouldNotBeAddedBecauseOfAdministrator};