import type { CollaboratorGroup } from 'owa-graph-schema';
import { savedCollaboratorTable, suggestedCollaboratorTable } from '../database';

export default function getCollaboratorTable(collaboratorType: CollaboratorGroup) {
    switch (collaboratorType) {
        case 'Saved':
            return savedCollaboratorTable;
        case 'Suggested':
            return suggestedCollaboratorTable;
        default:
            throw new Error('No collaborator table found for collaborator type'); // throw an error so future developer can catch an invalid input easily
    }
}
