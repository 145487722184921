import { getNavigationState } from 'accelerator-router';
import { lazyHybridspaceAgendaPanelModule } from 'hybridspace-agenda-panel';
import { MAX_CALENDAR_VIEW_LIMIT } from 'hybridspace-common/lib/constants';
import { lazyLoadWorkspaceReservations } from 'hybridspace-desk-booking';
import { logPlacesEvent } from 'hybridspace-telemetry';
import { today } from 'owa-datetime';
import { createDateRange } from 'owa-datetime-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { type UTPageType, type UTPlacesBuildingNavigationOrigin } from 'owa-unified-telemetry';
import { loadCalendarView } from 'places-calendar-view-store';
import { lazyPlacesBuildingModule } from 'places-explore';
import {
    getCurrentDate,
    getWorkLocationInfo,
    setCurrentBuilding,
    setRecentBuildingsFromCache,
} from 'places-explore-app-store';
import {
    invokeLoadPlace,
    loadCollaboratorsLocations,
    loadFWH,
    loadPeopleInMeetings,
    loadWorkLocationInfo,
} from 'places-explore-operations';
import { placesFwkPageLoadAction } from 'places-fwk-actions';
import { getPlaces, setTenantHasBuildingsCacheValue } from 'places-place-store';
import { setCurrentDate } from 'places-user-session-stores';
import { getTenantBuildingsExist } from './sharedLoaderUtils/getTenantBuildingsExist';

import type { LoaderFunction } from 'react-router-dom';
import { loadBuildingAppDefinitionsCache } from '././sharedLoaderUtils/loadBuildingAppDefinitionsCache';

// Loader for the homepage building route (/buildings/buildingId)
export const placesBuildingLoader: LoaderFunction = async ({ params }) => {
    placesFwkPageLoadAction('Explore', 'start', 'Started');
    // Reset the Date to Today to undo any date changes from People tab.
    setCurrentDate(today());

    const buildingId = params.buildingId;
    const navigationState = getNavigationState();
    const origin = navigationState?.origin;
    //We have this check to make the compiler happy, we wont hit this loader if this is true
    if (buildingId === undefined) {
        return;
    }
    const currentDate = getCurrentDate();
    const todayDateRange = createDateRange(currentDate, 1);

    // Check if the tenant has places
    setTenantHasBuildingsCacheValue();
    const tenantHasPlacesPromise = getTenantBuildingsExist();

    // Define critical data: places building module and load place
    const criticalData: Promise<any>[] = [
        lazyPlacesBuildingModule.import(),
        lazyHybridspaceAgendaPanelModule.import(),
    ];

    /**
     * Define & Execute  noncritical data: reservations, places, work location info,
     * collaborators, people in meetings, events
     */
    loadFWH(todayDateRange);

    lazyLoadWorkspaceReservations.import().then(loadWorkspaceReservations => {
        loadWorkspaceReservations(currentDate);
    });

    if (isFeatureEnabled('msplaces-dev-call-calendar-view')) {
        loadCalendarView(createDateRange(currentDate, 2), 'Explore', MAX_CALENDAR_VIEW_LIMIT, true);
    }

    // FWH is actively being modified in the UX via the set work location button, so FWH should only be called the first load
    // Calling every time we switch building can cause old data overwriting new data
    // ex: building switch => call loadWorklocationInfo => update to new location => update store with new data => loadWorkLocationInfo updates store with old data
    if (getWorkLocationInfo() === null) {
        loadWorkLocationInfo(todayDateRange);
    }
    loadCollaboratorsLocations(todayDateRange);
    loadPeopleInMeetings(todayDateRange, todayDateRange.start);

    // If tenant doesn't have places, return null before we continue
    const tenantHasPlaces = await tenantHasPlacesPromise;
    if (!tenantHasPlaces) {
        return null;
    }

    // skip critical data (loading a place)
    // If we have the building object cached, we don't need to load it again
    // And add the ID to our cache
    if (getPlaces()?.get(buildingId)) {
        setCurrentBuilding(buildingId);
        logBuildingNavigation(buildingId, origin ?? 'directLink');

        // Load the services apps definitions for the current building in the cache, if not yet in the cache.
        // This call can be a noop here, already have the building object cached and then maybe apps also cached.
        loadBuildingAppDefinitionsCache();
        return null;
    }

    // Try to load the place before adding to the cache
    // If it fails the loader will catch the error
    //Add to our MRU browser cache
    try {
        await invokeLoadPlace(buildingId);
        setCurrentBuilding(buildingId);
        // If we are here, this means we are deep linked and should throw away old origins
        logBuildingNavigation(buildingId, origin ?? 'directLink');

        // Get the services apps definitions for the current building, if not yet in the cache, likely the case when reached here.
        loadBuildingAppDefinitionsCache();
    } catch (e) {
        // checking the cache if we have bad building ID
        setRecentBuildingsFromCache();
        if (e.message.includes('NotFound') || e.message.includes('Invalid')) {
            throw new Response(e.message, { status: 404 });
        } else {
            throw new Response(e.message, { status: 500 });
        }
    }
    return Promise.all(criticalData);
};

function logBuildingNavigation(buildingId: string, origin: string) {
    logPlacesEvent({
        eventName: 'PlacesBuildingNavigation',
        data: {
            BuildingId: buildingId,
            Origin: getOrigin(origin),
        },
    });
}

function getOrigin(origin: string): UTPlacesBuildingNavigationOrigin {
    switch (origin) {
        case 'buildingSearch':
            return 'BuildingSearch';
        case 'buildingPicker':
            return 'BuildingPicker';
        case 'emptyBuildingPicker':
            return 'EmptyBuildingPicker';
        case 'NearbyPlaces':
            return 'NearbyPlaceCard';
        case 'SuggestedPlaces':
            return 'SuggestedPlaceCard';
        case 'inOfficeSummaryDetails':
            return 'InOfficeSummaryDetails';
        case 'directLink':
        default:
            return 'DirectLink';
    }
}
