/**
 * The roaming time zone structure used for secondary and tertiary timezones
 */
export default interface RoamingTimezone {
    /**
     * Determines whether a timezone will show up in different places (like the surface)
     */
    isVisible: boolean;

    /**
     * The timezone id (e.g. Pacific Standard Time)
     */
    timezoneId: string;

    /**
     * The timezone label displayed to the user; may be truncated when displayed
     */
    timezoneLabel?: string;
}

export const ROAMING_TIMEZONE_IS_VISIBLE_PROP = 'isVisible';
export const ROAMING_TIMEZONE_TIMEZONE_ID_PROP = 'timezoneId';
export const ROAMING_TIMEZONE_TIMEZONE_LABEL_PROP = 'timezoneLabel';
export const ROAMING_TIMEZONE_PROP_LENGTH_MAX = 3;
export const ROAMING_TIMEZONE_PROP_LENGTH_MIN = 2;
export const MAX_COUNT_ROAMING_TIMEZONES = 20;
