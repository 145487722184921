import { isFeatureEnabled } from 'owa-feature-flags';
import { trace } from 'owa-trace';

export function debugLog(eventName: string, ...args: any) {
    if (isDebugLog()) {
        const logstr = `[ObserverPresence] ${eventName}${convertToString(args)}`;
        trace.info(logstr);
    }
}

function isDebugLog() {
    return isFeatureEnabled('mon-presence-debug');
}

function convertToString(args: any[]) {
    let converted = '';
    args?.forEach((arg: any) => {
        if (arg instanceof Map) {
            converted += ' ' + JSON.stringify(Object.fromEntries(arg));
        } else {
            converted += ' ' + JSON.stringify(arg);
        }
    });
    return converted;
}
