import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { createObservableOperation } from 'owa-observable-operation';
import { getPlacesWorkLocationInfo } from '../services/getPlacesWorkLocationInfo';

import type { DateRange } from 'owa-datetime-utils';
const {
    start: loadWorkLocationInfo,
    onSucceeded: loadWorkLocationInfoSucceeded,
    onEnded: loadWorkLocationInfoEnded,
    startAsync: loadWorkLocationInfoAsync,
    isInProgress: loadWorkLocationInfoInProgress,
    getLastError: getWorkLocationInfoLastError,
} = createObservableOperation(
    'PLACES_LOAD_WORK_LOCATION_INFO',
    (dateRange: DateRange, isRetry?: boolean) => ({ dateRange, isRetry }),
    ({ dateRange }) => getDateRangeISOString(dateRange),
    ({ dateRange, isRetry }) => getPlacesWorkLocationInfo(dateRange, isRetry)
);

export {
    loadWorkLocationInfo,
    loadWorkLocationInfoEnded,
    loadWorkLocationInfoSucceeded,
    loadWorkLocationInfoAsync,
    loadWorkLocationInfoInProgress,
    getWorkLocationInfoLastError,
};
