import { createObservableOperation } from 'owa-observable-operation';
import { getPlacesService } from 'places-service';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';

import type { DateRange } from 'owa-datetime-utils';

const {
    getLastError: getCollaboratorsLocationsLastError,
    start: loadCollaboratorsLocations,
    isInProgress: loadCollaboratorsLocationsInProgress,
    onStarted: loadCollaboratorsLocationsStarted,
    onSucceeded: loadCollaboratorsLocationsSucceeded,
    onFailed: loadCollaboratorsLocationsFailed,
    onEnded: loadCollaboratorsLocationsEnded,
} = createObservableOperation(
    'PLACES_LOAD_SAVED_COLLABORATORS_LOCATIONS',
    (dateRange: DateRange) => ({
        dateRange,
    }),
    ({ dateRange }) => getDateRangeISOString(dateRange),
    ({ dateRange }) => getPlacesService().getCollaboratorsLocations(dateRange)
);

export {
    getCollaboratorsLocationsLastError,
    loadCollaboratorsLocations,
    loadCollaboratorsLocationsEnded,
    loadCollaboratorsLocationsFailed,
    loadCollaboratorsLocationsStarted,
    loadCollaboratorsLocationsInProgress,
    loadCollaboratorsLocationsSucceeded,
};
