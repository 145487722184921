import {
    getListUserFeatureAccessLastError,
    loadListUserFeatureAccessInProgress,
} from 'places-analytics-app-store-operations/lib/operations/loadListUserFeatureAccess';
import { ListUserFeatureAccessKey } from 'places-analytics-app-store-operations';
import { getListUserFeatureAccess } from '../store';
import useListUserFeatureAccess from 'hybridspace-graphql/lib/hooks/useListUserFeatureAccess';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * This function retrieves the user feature access data from the store.
 * It is used to provide a consistent interface for accessing this data,
 * including the loading state and any errors that may have occurred fronm the operations module.
 * TODO: Remove the hook and use the store directly during cleanup of FF 'msplaces-enable-api-call-optimization'
 */
export function getListUserFeatureAccessFromStore() {
    return {
        featureAccess: getListUserFeatureAccess(),
        loading: loadListUserFeatureAccessInProgress(ListUserFeatureAccessKey),
        error: getListUserFeatureAccessLastError(ListUserFeatureAccessKey),
    };
}

/**
 * This hook is used to select the user feature access data from the store or directly from the API.
 * The decision to use the store or the API is based on the application setting `msplaces-enable-api-call-optimization`.
 * In the future, this can be refactored to use get the data from store directly and remove the usage of hook.
 */
export function useListUserFeatureAccessSelector() {
    const enableApiCallOptimization = isFeatureEnabled('msplaces-enable-api-call-optimization');

    const storeData = getListUserFeatureAccessFromStore();
    const hookData = useListUserFeatureAccess(enableApiCallOptimization /* skip */);

    const featureAccess = enableApiCallOptimization
        ? storeData.featureAccess
        : hookData.featureAccess;
    const error = enableApiCallOptimization ? storeData.error : hookData.error;
    const loading = enableApiCallOptimization ? storeData.loading : hookData.loading;

    return {
        featureAccess,
        error,
        loading,
    };
}
