// extracted by mini-css-extract-plugin
export var accTab = "rTv2a";
export var accTabContent = "QCRCo";
export var accTabContentResponsive = "jSiZu";
export var accTabList = "spylp";
export var accTabListResponsive = "aof9Q";
export var iconStyle = "gwVjO";
export var moreButtonIcon = "_C9JE";
export var overflowButton = "Aoy4Z";
export var overflowButtonWithText = "s4jZ1";
export var toolTipStyle = "hrnCC";