import { placesMapsTable } from '../database';
import type { PlacesMapsTableEntry } from '../database';
import { now, getTimestamp } from 'owa-datetime';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

export async function getFloorPlanFromCache(
    floorId: string
): Promise<PlacesMapsTableEntry | undefined> {
    let floor: PlacesMapsTableEntry | undefined;

    try {
        floor = await placesMapsTable.get(floorId);
    } catch (e) {
        debugErrorThatWillShowErrorPopupOnly('getFloorFromCache', e);
    }

    // Will ignore the entry if it's expired. useFloorPlanWithcache hook will subsequently
    // make a new network call to overwrite the stale data.
    if (!floor || floor.expirationTime < getTimestamp(now())) {
        return undefined;
    }

    return floor;
}
