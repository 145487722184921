import { createObservableOperation } from 'owa-observable-operation';
import getUpsLocationForCollaboratorsInBuildingToday from '../services/getUpsLocationForCollaboratorsInBuildingToday';

import type { CollaboratorSource } from 'hybridspace-common/lib/serviceTypes';

const {
    getLastError: getUpsCollaboratorsBuildingLocationsLastError,
    start: loadUpsCollaboratorsBuildingLocations,
    isInProgress: loadUpsCollaboratorsBuildingLocationsInProgress,
    onSucceeded: loadUpsCollaboratorsBuildingLocationsSucceeded,
} = createObservableOperation(
    'PLACES_LOAD_UPS_BUILDING_LOCATIONS',
    (collaboratorsEmails: string[], collaboratorCategory: CollaboratorSource) => ({
        collaboratorsEmails,
        collaboratorCategory,
        key: collaboratorCategory,
    }),
    ({ key }) => key,
    ({ collaboratorsEmails }) => getUpsLocationForCollaboratorsInBuildingToday(collaboratorsEmails)
);

export {
    getUpsCollaboratorsBuildingLocationsLastError,
    loadUpsCollaboratorsBuildingLocations,
    loadUpsCollaboratorsBuildingLocationsInProgress,
    loadUpsCollaboratorsBuildingLocationsSucceeded,
};
