import type { MailboxInfo } from 'owa-client-types';
import getShortenEventScopeCloudSetting from '../selectors/getShortenEventScopeCloudSetting';
import { ShortenEventScopeType } from '../store/schema/ShortenEventScopeType';

const DEFAULT_SHORTEN_EVENT_SCOPE_FALLBACK = ShortenEventScopeType.None;

/**
 * Gets the default value for the shorten events duration option
 */
export default function getDefaultShortenEventScope(
    mailboxInfo: MailboxInfo
): ShortenEventScopeType | null {
    const shortenEventScope = getShortenEventScopeCloudSetting(mailboxInfo);

    return shortenEventScope != null ? shortenEventScope : DEFAULT_SHORTEN_EVENT_SCOPE_FALLBACK;
}
