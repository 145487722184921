import { isFeatureEnabled } from 'owa-feature-flags';
import { WorkLocationSurfaceType } from './types';
import type { FlexibleWorkingHours } from 'owa-graph-schema';

/**
 * This util checks if the work plans onboarding surface item should be displayed
 * @param recurringFWH The recurring work hours and location info
 * @returns Whether the work plans onboarding surface item should be displayed
 */
export function shouldDisplayWorkPlansOnboarding(recurringFWH: FlexibleWorkingHours[]): boolean {
    return (
        isFeatureEnabled('cal-surface-places-callout') &&
        isFeatureEnabled('cal-surface-places-callout-onboarding') &&
        isFeatureEnabled('cal-hybrid-working-hours') &&
        recurringFWH.length > 0 &&
        areAllWorkLocationsUnspecified(recurringFWH)
    );
}

/**
 * This util iterates through all segments for the recurring pattern and checks
 * if work location is 'Unspecified' for each segment
 * @param recurringFWH The recurring work hours and location info
 * @returns Whether all work locations are unspecified for the recurring pattern
 */
function areAllWorkLocationsUnspecified(recurringFWH: FlexibleWorkingHours[]): boolean {
    return recurringFWH.every((segment: FlexibleWorkingHours) => {
        return segment.workLocationType === WorkLocationSurfaceType.Unspecified;
    });
}
