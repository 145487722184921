import { deletePlacesMapsCache } from 'hybridspace-map-cache';
import { lazyDeletePlacesDatabase } from 'hybridspace-persistent-store';
import { logGreyError } from 'owa-analytics';
import { clearSessionStorage } from 'owa-session-storage';
import { deletePlacesCacheDatabase } from 'places-people-store';
import { deleteTenantPlacesCache } from 'places-place-store';
import { deleteServiceCache } from 'places-service';
import { deletePlacesSessionDatabase } from 'places-user-session-stores/lib/sessionDatabase';

export function onSignoutClick() {
    // localstorage clean up
    deleteTenantPlacesCache();

    // clear session storage. currently MSAL uses this in Places web.
    clearSessionStorage(window);

    // IndexDB clean up
    Promise.all([
        lazyDeletePlacesDatabase.importAndExecute(),
        deletePlacesCacheDatabase(),
        deletePlacesMapsCache(),
        deleteServiceCache,
        deletePlacesSessionDatabase,
    ]).catch(e => {
        logGreyError('PlacesSignoutCleanupError', e);
    });
}
