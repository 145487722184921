import Dexie from 'dexie';

const INDEXED_DB_NAME = 'placesServiceCache'; // Cache to help places service recover from network errors
const TABLE_NAME = 'placesBuildings';

// Buildings Cache
export interface BuildingsCache {
    uniqueId: string; // building ID
    owner: string;
    building: string;
    timestamp: number;
}

export const db = new Dexie(INDEXED_DB_NAME);

db.version(1).stores({
    placesBuildings: 'uniqueId,owner',
});

export const buildingsTable = db.table(TABLE_NAME);

export async function deleteServiceCache() {
    if (await Dexie.exists(INDEXED_DB_NAME)) {
        await Dexie.delete(INDEXED_DB_NAME);
    }
}
