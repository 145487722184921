import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { createObservableOperation } from 'owa-observable-operation';

import fetchAllPeopleInsights from './fetchAllPeopleInsights';

import type { DateRange } from 'owa-datetime-utils';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

const { onSucceeded: loadAllPeopleInsightsSucceeded, start: loadAllPeopleInsights } =
    createObservableOperation(
        'PLACES_LOAD_ALL_PEOPLE_INSIGHTS',
        (dateRange: DateRange, collaborators: UserEmailAddress[]) => ({
            dateRange,
            collaborators,
        }),
        ({ dateRange }) => getDateRangeISOString(dateRange),
        ({ dateRange, collaborators }) => fetchAllPeopleInsights(dateRange, collaborators)
    );

export { loadAllPeopleInsightsSucceeded, loadAllPeopleInsights };
