import React from 'react';
import type { FlexibleWorkingHours } from 'owa-graph-schema';

// Context used to track the changes made on the settings modal
export const SettingsContext = React.createContext<{
    initialFlexibleWorkingHours: Map<string, FlexibleWorkingHours>;
    setInitialFlexibleWorkingHours: (
        initialFlexibleWorkingHours: Map<string, FlexibleWorkingHours>
    ) => void;
    dirtyFlexibleWorkingHours: Map<string, FlexibleWorkingHours>;
    setDirtyFlexibleWorkingHours: (
        initialFlexibleWorkingHours: Map<string, FlexibleWorkingHours>
    ) => void;
}>({
    initialFlexibleWorkingHours: new Map<string, FlexibleWorkingHours>(),
    setInitialFlexibleWorkingHours: () => {},
    dirtyFlexibleWorkingHours: new Map<string, FlexibleWorkingHours>(),
    setDirtyFlexibleWorkingHours: () => {},
});

export const useSettingsContext = () => React.useContext(SettingsContext);
