import { action } from 'satcheljs';

import type { Collaborator } from '../serviceTypes/Collaborator';

/**
 * Action called when any changes to a collaborator are made
 */
export const collaboratorUpdatedAction = action(
    'collaboratorUpdatedAction',
    (collaborator: Collaborator, actionType: 'added' | 'removed') => ({
        collaborator,
        actionType,
    })
);
