import { getCdnUrl } from 'owa-config';
import loc from 'owa-localize';
import {
    themeImage0AriaText,
    themeImage1AriaText,
    themeImage2AriaText,
    themeImage3AriaText,
    themeImage4AriaText,
    themeImageEmoticonsAriaText,
    themeImageNotesAriaText,
    themeImageWorkAriaText,
    themeImageScreensAriaText,
    themeImageCubesAriaText,
    themeImageMountainAriaText,
    themeImageForestAriaText,
    themeImageCoffeeAriaText,
    themeImageBreakfastAriaText,
    themeImageBalloonsAriaText,
    themeImageDesktopAriaText,
    themeImageSphereAriaText,
    themeImageWindowAriaText,
    themeImagePinkAriaText,
    themeImageYellowAriaText,
} from './getThemeImages.locstring.json';

export interface ThemeImageSearchItem {
    fullImageUrl: string;
    thumbnailUrl: string;
    ariaLabel: string;
    aspectRatio: number;
}

export function getThemeImages(): ThemeImageSearchItem[] {
    return [
        {
            url: getThemeImageUrl('000.jpg'),
            ariaLabel: loc(themeImage0AriaText),
        },
        {
            url: getThemeImageUrl('001.jpg'),
            ariaLabel: loc(themeImage1AriaText),
        },
        {
            url: getThemeImageUrl('002.jpg'),
            ariaLabel: loc(themeImage2AriaText),
        },
        {
            url: getThemeImageUrl('003.jpg'),
            ariaLabel: loc(themeImage3AriaText),
        },
        {
            url: getThemeImageUrl('004.jpg'),
            ariaLabel: loc(themeImage4AriaText),
        },
        {
            url: getThemeImageUrl('emoticons.jpg'),
            ariaLabel: loc(themeImageEmoticonsAriaText),
        },
        {
            url: getThemeImageUrl('notes.jpg'),
            ariaLabel: loc(themeImageNotesAriaText),
        },
        {
            url: getThemeImageUrl('work.jpg'),
            ariaLabel: loc(themeImageWorkAriaText),
        },
        {
            url: getThemeImageUrl('screens.jpg'),
            ariaLabel: loc(themeImageScreensAriaText),
        },
        {
            url: getThemeImageUrl('cubes.jpg'),
            ariaLabel: loc(themeImageCubesAriaText),
        },
        {
            url: getThemeImageUrl('mountain.jpg'),
            ariaLabel: loc(themeImageMountainAriaText),
        },
        {
            url: getThemeImageUrl('forest.jpg'),
            ariaLabel: loc(themeImageForestAriaText),
        },
        {
            url: getThemeImageUrl('coffee.jpg'),
            ariaLabel: loc(themeImageCoffeeAriaText),
        },
        {
            url: getThemeImageUrl('breakfast.jpg'),
            ariaLabel: loc(themeImageBreakfastAriaText),
        },
        {
            url: getThemeImageUrl('balloons.jpg'),
            ariaLabel: loc(themeImageBalloonsAriaText),
        },
        {
            url: getThemeImageUrl('desktop.jpg'),
            ariaLabel: loc(themeImageDesktopAriaText),
        },
        {
            url: getThemeImageUrl('sphere.jpg'),
            ariaLabel: loc(themeImageSphereAriaText),
        },
        {
            url: getThemeImageUrl('window.jpg'),
            ariaLabel: loc(themeImageWindowAriaText),
        },
        {
            url: getThemeImageUrl('pink.jpg'),
            ariaLabel: loc(themeImagePinkAriaText),
        },
        {
            url: getThemeImageUrl('yellow.jpg'),
            ariaLabel: loc(themeImageYellowAriaText),
        },
    ].map(urlObject => {
        return {
            fullImageUrl: urlObject.url,
            thumbnailUrl: urlObject.url,
            aspectRatio: 16 / 9,
            ariaLabel: urlObject.ariaLabel,
        };
    });
}

function getThemeImageUrl(name: string): string {
    return `https:${getCdnUrl()}assets/eventify/themeImages/16_9/${name}`;
}
