import { fwhUpdatedLocalNotifyAction } from 'hybridspace-common/lib/shared-actions';
import { orchestrator } from 'satcheljs';
import { getCurrentSegmentLocation } from 'places-service';
import { setWorkLocationInfo } from '../store';
import { getPlaces } from 'places-place-store';

import { owaDate, isSameDay, today } from 'owa-datetime';

orchestrator(fwhUpdatedLocalNotifyAction, actionMessage => {
    const places = getPlaces();
    const place =
        places && actionMessage.buildingId ? places.get(actionMessage.buildingId) : undefined;

    const segmentDate = owaDate(
        actionMessage.segment.start.timeZone.name,
        actionMessage.segment.start.dateTime
    );

    const fwh = actionMessage.fwh;

    fwh.map(item => {
        item.id = actionMessage.segment.id;
    });

    const todayDate = today();
    const currentSegment = getCurrentSegmentLocation(fwh, todayDate);

    // Check that the current segment is the same as the one that was updated and that it is today's segment
    if (isSameDay(todayDate, segmentDate)) {
        const workLocationInfo = {
            relativeLocation: currentSegment.workLocation ?? 'Unspecified',
            place,
            fwh,
            /**
             * TODO workspaceId and name can be removed all together, see work item
             * https://outlookweb.visualstudio.com/MicrosoftPlaces/_backlogs/backlog/FC%20-%20Places%20Web%20App/Features/?showParents=true&workitem=246073
             */
            workspaceId: undefined,
            workspaceName: undefined,
        };
        setWorkLocationInfo(workLocationInfo);
    }
});
