import { createLazyComponent, LazyModule } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceAdminRoute" */ './lazyIndex')
);

export const AdminAnalyticsScreen = createLazyComponent(
    lazyModule,
    m => m.AdminAnalyticsScreen,
    Loading,
    NetworkError
);

export const AdminManagementScreen = createLazyComponent(
    lazyModule,
    m => m.AdminManagementScreen,
    Loading,
    NetworkError
);
