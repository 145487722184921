import type CalendarCloudSetting from './schema/CalendarCloudSetting';
import type CalendarCloudSettingsStore from './schema/CalendarCloudSettingsStore';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type { CalendarCloudSettingStoreMap } from './schema/CalendarCloudSettingStoreMap';

const defaultStore: CalendarCloudSettingStoreMap = {
    accounts: new ObservableMap<string, CalendarCloudSettingsStore>(),
};

export const getAccountDefaultSettings = (): CalendarCloudSettingsStore => ({
    settings: new ObservableMap<string, CalendarCloudSetting>(),
    isInitialized: false,
});

const calendarCloudSettingsStore = createStore('CalendarCloudSettings', defaultStore)();
export const getStore = () => calendarCloudSettingsStore;
