import type { OwaDate } from 'owa-datetime';
import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { logGreyError } from 'owa-analytics';
import { getTimestamp, startOfDay } from 'owa-datetime';

import { placesPeopleTable } from '../database';

/**
 *
 * @param cutOffDate: Any values in the cache for data
 * before this date will be deleted. Example: if today is
 * 4/15/24 and the cuttOffDate is 4/14/24, all entries with
 * startOfDay < 4/14/24 will be deleted.
 */
export async function cleanPlacesPeopleCache(cutOffDate: OwaDate) {
    try {
        // Get all entries with a date before the cutOffDate
        // and delete those entries
        const ownerId = getUserExternalDirectoryId();
        await placesPeopleTable
            .where('startOfDay')
            .below(getTimestamp(startOfDay(cutOffDate)))
            .and(row => row.owner === ownerId)
            .primaryKeys()
            .then(keys => {
                placesPeopleTable.bulkDelete(keys);
            });
    } catch (e) {
        logGreyError('PlacesPeopleCache_cleanPlacesPeopleCache', e);
    }
}
