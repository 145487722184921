import type { AppDefinition, M365Acquisition } from 'owa-graph-schema';
import { getClientTitleId } from '../utils/getClientTitleId';

export function convertAcquisitionToAppDefinition(acquisition: M365Acquisition): AppDefinition {
    const title = acquisition.titleDefinition;
    const staticTabs = title.elementDefinitions?.staticTabs ?? [];
    const inputExtensions = title.elementDefinitions?.composeExtensions ?? [];

    const appDefinition: AppDefinition = {
        __typename: 'AppDefinition',
        id: getClientTitleId(acquisition),
        manifestVersion: title.manifestVersion ?? '1.13',
        version: title.version ?? '0.0.0',
        categories: title.categories ?? [],
        developerName: title.developerName ?? '',
        developerUrl: title.developerUrl ?? '',
        termsOfUseUrl: title.termsOfUseUrl ?? '',
        privacyUrl: '',
        validDomains: title.validDomains ?? [],
        connectors: [], // TODO: Integrate Connector ElementDefinition
        isCoreApp: false,
        isFullScreen: title.isFullScreen,
        devicePermissions: [],
        isFullTrust: title.isFullTrust,
        permissions: title.permissions,
        activities: undefined, // TODO: Integrate Activity ElementDefinition
        staticTabs,
        webApplicationInfo: title.webApplicationInfo,
        galleryTabs: [],
        inputExtensions,
        showLoadingIndicator: !!title.showLoadingIndicator,
        shortDescription: title.shortDescription,
        longDescription: title.longDescription,
        largeImageUrl: title.iconLarge?.uri ?? '',
        smallImageUrl: title.iconSmall?.uri,
        name: title.name ?? 'No name',
        state: acquisition.acquisitionState,
        titleId: acquisition.titleId ?? undefined,
    };
    return appDefinition;
}
