import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import getBuildingMosAppsDefinitions from './getBuildingMosAppsDefinitions';
import {
    getBuildingAppDefinitions,
    getCurrentPlace,
    setBuildingAppDefinitions,
} from 'places-explore-app-store';

// Load building app definitions cache, try catch for potential API errors is in the callee, so we don't need to handle it here.
export async function loadBuildingAppDefinitionsCache() {
    const currentPlace = getCurrentPlace();
    if (currentPlace && getBuildingAppDefinitions().get(currentPlace.id) === undefined) {
        const apps = await getBuildingMosAppsDefinitions(
            getModuleContextMailboxInfo(),
            currentPlace
        );
        setBuildingAppDefinitions(currentPlace.id, apps);
    }
}
