import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';
import type { ObservableMap } from 'mobx';
import type { CalendarFiltersTypes } from '../store/schema/CalendarEventsFilters';

export default function getCalendarFilterCloudSettings(
    mailboxInfo: MailboxInfo
): ObservableMap<CalendarFiltersTypes, string[]> | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);

    const calendarFiltersSetting = accountSettings.get(
        CalendarCloudSettingType.CalendarFilters.toLowerCase()
    );

    return calendarFiltersSetting != null
        ? (calendarFiltersSetting.value as ObservableMap<CalendarFiltersTypes, string[]>)
        : undefined;
}
