import { placesFwkBootStartAction } from 'hybridspace-performance-datapoints';
import { initializePlacesFinderStore } from 'hybridspace-places-finder-store';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import { registerLPCInitializeOrchestrator } from 'owa-lpc-initialize';
import loadPlacesSettings from 'places-settings/lib/loadPlacesSettings';
import {
    getModuleState,
    initializeWeekPickerViewState,
    setModuleStateSafe,
} from 'places-user-session-stores';
import { handleMetaOSDeepLinkRedirection } from './utils/handleMetaOSDeepLinkRedirection';

import type { SessionData } from 'owa-service/lib/types/SessionData';
import { lazyLoadPlacesAnalyticsCoreOperations } from 'places-analytics-app-store';
import { writePlaceCacheToApollo } from 'places-service';

export async function initializeState(sessionData?: SessionData | undefined) {
    placesFwkBootStartAction(sessionData?.isOffline ? 'OfflineSessionData' : 'OnlineSessionData');
    loadPlacesSettings(
        getGlobalSettingsAccountMailboxInfo(),
        true /** shouldUseMainThread to send via main thread to avoid worker thread set up cost */
    );

    // This function pre-loads necessary API calls (fire and forget) to optimize load time for PlacesAnalytics.
    // It takes the current page route into account to determine which APIs to preload.
    // For the Analytics homepage:
    //    - Preload core analytics APIs to optimize load time.
    // For other pages (e.g., Employee view):
    //    - Preload only the UserFeatureAccess (RBAC) API initially.
    //    - If RBAC confirms Admin access, proceed to load the remaining analytics core APIs.
    lazyLoadPlacesAnalyticsCoreOperations.importAndExecute();

    setModuleStateSafe('boot');
    setTimeout(() => {
        // TODO: remove this when we improve appView FSM for error handling and other modules etc.
        // forces us to remove loading screen if we don't complete in time to maintain similar behavior as of developing.
        if (getModuleState() !== 'complete') {
            setModuleStateSafe('complete');
        }
    }, 5000 /** 5s */);

    registerLPCInitializeOrchestrator();

    // We would have timezone initialized by now
    // so initializing date here would ensure application layer
    // to use user's timezone instead of UTC
    initializeWeekPickerViewState();
    initializePlacesFinderStore();

    writePlaceCacheToApollo();

    // Handles MetaOS deep link redirection
    await handleMetaOSDeepLinkRedirection();
    return null;
}
