import type { MailboxInfo } from 'owa-client-types';
import getCalendarCloudSettings from '../selectors/getCalendarCloudSettings';
import { CalendarCloudSettingSourceType } from '../store/schema/CalendarCloudSettingSourceType';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';

/**
 * Checks if the speedy meetings feature is set by tenant admin.
 */
export default function isSpeedyMeetingSetByTenant(mailboxInfo: MailboxInfo): boolean {
    const calendarCloudSettings = getCalendarCloudSettings(mailboxInfo);
    const shortenEventScopeSetting = calendarCloudSettings.get(
        CalendarCloudSettingType.ShortenEventScope.toLowerCase()
    );
    const endShortEventsBySetting = calendarCloudSettings.get(
        CalendarCloudSettingType.EndShortEventsBy.toLowerCase()
    );
    const endLongEventsBySetting = calendarCloudSettings.get(
        CalendarCloudSettingType.EndLongEventsBy.toLowerCase()
    );

    return (
        shortenEventScopeSetting?.source === CalendarCloudSettingSourceType.TenantDefault &&
        endShortEventsBySetting?.source === CalendarCloudSettingSourceType.TenantDefault &&
        endLongEventsBySetting?.source === CalendarCloudSettingSourceType.TenantDefault
    );
}
