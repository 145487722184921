import type { ExtendedDateTime, WorkLocationType } from 'owa-graph-schema';
import { WorkLocationSurfaceType } from './types';
import { isSegmentEncapsulated } from 'owa-calendar-working-hours-utils';
import {
    WORKLOCATIONTYPE_REMOTE_STRING,
    WORKLOCATIONTYPE_OFFICE_STRING,
    WORKLOCATIONTYPE_TIMEOFF_STRING,
    WORKLOCATIONTYPE_UNSPECIFIED_STRING,
} from './workLocationConstants';
/**
 * This function takes in an array of work locations and returns a work location
 * type to be used for rendering any viewable merged work location.
 *
 * @param workLocations An array of work locations to be parsed.
 * @returns A WorkLocationType to be used for rendering a merged work location.
 */
export function parseMergedWorkLocationType(
    workLocations:
        | Array<{
              start: ExtendedDateTime;
              end: ExtendedDateTime;
              workLocationType: WorkLocationType;
          }>
        | null
        | undefined
) {
    if (!workLocations || workLocations.length === 0) {
        return null;
    }

    const outOfOfficeViewSegments = workLocations.filter(workLocation => {
        return workLocation.workLocationType === WORKLOCATIONTYPE_TIMEOFF_STRING;
    });

    // We filter for unique work locations that are not Unspecified, TimeOff, or
    // encapsulated by OOF segments.
    const filteredWorkLocations = new Set<WorkLocationType>();
    const excludedWorkLocationTypes = [
        WORKLOCATIONTYPE_TIMEOFF_STRING,
        WORKLOCATIONTYPE_UNSPECIFIED_STRING,
    ];
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    workLocations.forEach(workLocation => {
        if (
            !excludedWorkLocationTypes.includes(workLocation.workLocationType) &&
            !isSegmentEncapsulated(workLocation, outOfOfficeViewSegments)
        ) {
            filteredWorkLocations.add(workLocation.workLocationType);
        }
    });

    if (filteredWorkLocations.size > 1) {
        return WorkLocationSurfaceType.Hybrid;
    } else if (filteredWorkLocations.size === 1) {
        const [workLocation] = filteredWorkLocations;
        return getWorkLocationSurfaceType(workLocation);
    } else if (outOfOfficeViewSegments.length > 0) {
        return WorkLocationSurfaceType.TimeOff;
    } else {
        return WorkLocationSurfaceType.Unspecified;
    }
}

function getWorkLocationSurfaceType(workLocation: WorkLocationType): WorkLocationSurfaceType {
    switch (workLocation) {
        case WORKLOCATIONTYPE_REMOTE_STRING:
            return WorkLocationSurfaceType.Remote;
        case WORKLOCATIONTYPE_OFFICE_STRING:
            return WorkLocationSurfaceType.Office;
        case WORKLOCATIONTYPE_TIMEOFF_STRING:
            return WorkLocationSurfaceType.TimeOff;
        default:
            return WorkLocationSurfaceType.Unspecified;
    }
}
