import { lazyGetContext } from 'owa-metaos-app-bootstrap';

/**
 * If we are running in MetaOS, we will check the context for a deep link
 * We always assume the content of the subpage ID is a deep link
 * If its invalid, react router will catch it and redirect to the 404 page
 * Example of deep linked metaOS link in (teams): https://teams.microsoft.com/l/entity/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc?context={"subEntityId":"/buildings/0ea98efb-958a-4e86-9392-800fbc859ca9"}
 */
export async function getMetaOSDeepLinkSubPath() {
    const context = await lazyGetContext.importAndExecute();
    return context.page.subPageId;
}
