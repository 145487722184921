import { getPreviouslyVisitedBuildings } from '../store';
import { getCurrentPlace } from './getCurrentPlace';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

export function isRareVisit(emailAddress: UserEmailAddress): boolean {
    const pastVisitedBuildings = getPreviouslyVisitedBuildings().get(emailAddress);
    if (!pastVisitedBuildings || pastVisitedBuildings.length === 0) {
        // if there is no data for that collaborator, then we cannot determine rare visit yet
        return false;
    }
    const currentPlace = getCurrentPlace();
    const currentPlaceId = currentPlace?.id;

    if (currentPlaceId) {
        return !pastVisitedBuildings.includes(currentPlaceId);
    } else {
        return false;
    }
}
