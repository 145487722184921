import { getCurrentDate } from '../store';
import { createDateRange } from 'owa-datetime-utils';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import type { DateRange } from 'owa-datetime-utils';

// Check if date range is for current date range
export function isCurrentDateRange(dateRange: DateRange) {
    const key = getDateRangeISOString(dateRange);
    const currentDateRangeKey = getDateRangeISOString(createDateRange(getCurrentDate(), 1));
    // We make sure the result is for the current date range
    return key === currentDateRangeKey;
}
