import { orchestrator } from 'satcheljs';

import { loadCollaboratorsLocationsSucceeded } from 'places-explore-operations';
import { isCurrentDateRange } from '../utils/isCurrentDateRange';
import { setCollaboratorsLocationsValue } from '../utils/setCollaboratorsLocationsValue';

orchestrator(loadCollaboratorsLocationsSucceeded, ({ result, dateRange }) => {
    if (isCurrentDateRange(dateRange)) {
        // setting to store
        setCollaboratorsLocationsValue(result.collaboratorScheduleWithLocation);
    }
});
