import { ObservableMap, toJS } from 'mobx';
import { onPreCoprincipalAccountRemoved } from 'owa-account-source-list-store';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { createStore, mutator } from 'satcheljs';

import type { MailboxInfo } from 'owa-client-types';

type MailboxInfoKey = string;
type StoreKey = string;
type StoreType = any;

/**
 * Store mapping a mailboxInfo key to a set of multi-account aware stores.
 */
const multiAccountStores = createStore(
    'MULTI_ACCOUNT_STORES',
    new ObservableMap<MailboxInfoKey, ObservableMap<StoreKey, StoreType>>()
)();

/**
 * When an account is removed, delete all stores associated with it that were
 * created with createMultiAccountStore.
 */
mutator(onPreCoprincipalAccountRemoved, ({ removedAccount }) => {
    const accountKey = getAccountKeyForMailboxInfo(removedAccount.mailboxInfo);
    multiAccountStores.delete(accountKey);
});

/**
 * Creates a store that supports multiple accounts.
 *
 * @param storeKey Key for the store.
 * @param initialAccountState Object that represents the initial state for each account.
 * @returns Functions to retrieve the store associated with a particular account in read-only or read-write mode.
 * NOTE: The write function MUST be called from a mutator or mutatorAction.
 *
 * @example
 * Suppose you have a store with data, selectors and mutators, for example:
 *
 * ```ts
 * import { createStore, mutatorAction } from 'satcheljs';
 *
 * const getStore = createStore('HELLO', { name: '' });
 * function getName() {
 *     return getStore().name;
 * }
 * const setName = mutatorAction('SET_NAME', (newName: string) => {
 *     getStore().name = newName;
 * })
 * ```
 *
 * This store supports a single account since it does not associate a name
 * with any mailbox information or other account-specific data.
 *
 * In order to support multiple accounts, we need to associate data with a `MailboxInfo`,
 * since that is how different accounts are passed around the client-web codebase.
 * Essentially, we want to be able to call `getName(mailboxInfo)`
 * and `setName(mailboxInfo, newName)` instead of `getName()` and `setName(newName)`.
 *
 * With `createMultiAccountStore`, we can easily convert the store to support
 * multi-account scenarios, as shown below:
 *
 * ```ts
 * import createMultiAccountStore from 'owa-viewstate-store/lib/createMultiAccountStore';
 * import { mutatorAction } from 'satcheljs';
 * import type { MailboxInfo } from 'owa-client-types';
 *
 * const [read, write] = createMultiAccountStore('HELLO', { name: '' });
 * function getName(mailboxInfo: MailboxInfo) {
 *     return read(mailboxInfo).name;
 * }
 * const setName = mutatorAction('SET_NAME', (mailboxInfo: MailboxInfo, newName: string) => {
 *     write(mailboxInfo).name = newName;
 * })
 * ```
 *
 * `createMultiAccountStore` replaces `createStore` and and returns `read` and `write` functions in a tuple.
 * Selectors use the `read` function, which tries to read the store for the given account
 * and returns a copy of the initial state if data for the account was never written.
 * Mutators use the `write` function, ensuring that the store for the given account is
 * allocated and ready to be updated.
 *
 * You can name the `read` and `write` functions whatever you like when you decompose the return value.
 *
 * ---
 * ### Details
 *
 * Internally, `createMultiAccountStore` creates a single satcheljs store that is a double-map,
 * first from an account key to a set of stores, and then from "store" keys to the actual data.
 * Accounts and data stores are allocated when the application writes to such stores using the
 * returned `write` function.
 *
 * With this setup we can have a single static mutator listening to `onPreCoprincipalAccountRemoved`
 * that deletes the main entry for an account when the account is removed from Monarch.
 */
export default function createMultiAccountStore<T>(
    storeKey: string,
    initialAccountState: T
): [(mailboxInfo: MailboxInfo) => Readonly<T>, (mailboxInfo: MailboxInfo) => T] {
    const initialState = Object.freeze(toJS(initialAccountState));

    function read(mailboxInfo: MailboxInfo): Readonly<T> {
        const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
        return multiAccountStores.get(accountKey)?.get(storeKey) ?? initialState;
    }

    function write(mailboxInfo: MailboxInfo): T {
        const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);

        let accountStores = multiAccountStores.get(accountKey);
        if (!accountStores) {
            multiAccountStores.set(accountKey, new ObservableMap<StoreKey, StoreType>());
            accountStores = multiAccountStores.get(accountKey);
        }

        if (accountStores && !accountStores.has(storeKey)) {
            accountStores.set(storeKey, initialState);
        }

        return multiAccountStores.get(accountKey)?.get(storeKey);
    }

    return [read, write];
}
