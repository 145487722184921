import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the EnableOnlineMeetingsByDefault cloud setting
 */
export default function getHybridRSVPEnabledSetting(mailboxInfo: MailboxInfo): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const hybridRSVPEnabled = accountSettings.get(
        CalendarCloudSettingType.HybridRSVPEnabled.toLowerCase()
    );

    return hybridRSVPEnabled != null ? !!hybridRSVPEnabled.value : undefined;
}
