import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import { GlobalCalendarType } from 'owa-datetime';
import getCalendarCloudSettings from './getCalendarCloudSettings';
import type { MailboxInfo } from 'owa-client-types';

export default function getGlobalCalendarTypeCloudSetting(
    mailboxInfo: MailboxInfo
): GlobalCalendarType {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const globalCalendarTypeSetting = accountSettings.get(
        CalendarCloudSettingType.GlobalCalendarType.toLowerCase()
    );

    return globalCalendarTypeSetting
        ? (globalCalendarTypeSetting.value as GlobalCalendarType)
        : GlobalCalendarType.Gregorian;
}
