import type { MailboxInfo } from 'owa-client-types';
import getRoamingTimezonesCloudSetting from '../selectors/getRoamingTimezonesCloudSetting';
import type RoamingTimezone from '../store/schema/RoamingTimezone';

const DEFAULT_ROAMING_TIMEZONES_FALLBACK: RoamingTimezone[] = [];

/**
 * Gets the default value for the roaming timezones setting
 */
export default function getDefaultRoamingTimezones(mailboxInfo: MailboxInfo): RoamingTimezone[] {
    const roamingTimezones = getRoamingTimezonesCloudSetting(mailboxInfo);

    return roamingTimezones != null ? roamingTimezones : DEFAULT_ROAMING_TIMEZONES_FALLBACK;
}
