import { createLazyComponent, LazyModule, LazyImport } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

import type { ComponentProps } from 'react';
import type { LazyModuleType } from 'owa-bundling';

import type { HybridspaceWorkWeekCalendarSurface as HybridspaceWorkWeekCalendarSurfaceType } from './lazyIndex';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceWorkWeekCalendarSurface" */ './lazyIndex')
);

export const HybridspaceWorkWeekCalendarSurface = createLazyComponent<
    ComponentProps<typeof HybridspaceWorkWeekCalendarSurfaceType>,
    LazyModuleType<typeof lazyModule>,
    any
>(lazyModule, m => m.HybridspaceWorkWeekCalendarSurface, Loading, NetworkError);

export const HybridspaceWorkWeekCalendarSurfaceNoSpinner = createLazyComponent<
    ComponentProps<typeof HybridspaceWorkWeekCalendarSurfaceType>,
    LazyModuleType<typeof lazyModule>,
    any
>(lazyModule, m => m.HybridspaceWorkWeekCalendarSurface, undefined, NetworkError);

export const lazyHybridspaceWorkWeekCalendarSurfaceModule = new LazyImport(lazyModule, m => m);
