import { action } from 'satcheljs';
import type { FlexibleWorkingHoursView, FlexibleWorkingHours } from 'owa-graph-schema';

export const fwhUpdatedLocalNotifyAction = action(
    'fwhUpdatedLocalNotifyAction',
    (
        segment: FlexibleWorkingHoursView | FlexibleWorkingHours,
        fwh: FlexibleWorkingHoursView[] | FlexibleWorkingHours[],
        buildingId: string | undefined
    ) => ({
        segment,
        fwh,
        buildingId,
    })
);
