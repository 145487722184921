import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { DateRange } from 'owa-datetime-utils';
import getScheduleWithLocation from 'hybridspace-common/lib/services/getScheduleWithLocation';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { buildChunks } from 'hybridspace-common/lib/utils';

import type {
    HybridspaceGetScheduleFlexibleWorkingHoursView,
    HybridspaceScheduleInformation,
} from 'owa-graph-schema';

export default async function fetchAllPeopleInsights(
    dateRange: DateRange,
    collaborators: UserEmailAddress[]
): Promise<Map<UserEmailAddress, string[]>> {
    const perfDatapoint = new PerformanceDatapoint('PlacesFetchPeopleInsights');

    perfDatapoint.addCustomData({
        dateRange: getDateRangeISOString(dateRange),
    });

    // Initialize the map that will store <collaborator's email, array of building Ids>
    const collaboratorToBuildingMap = new Map<UserEmailAddress, string[]>();
    const collaboratorsWithInvalidSchedules: string[] = [];
    try {
        // Break collaborators into chunks
        const batchSize = 20;

        const collaboratorChunks = buildChunks(collaborators, batchSize) as UserEmailAddress[][];

        // Fetch schedules in batches of collaborators at a time
        const schedulesChunks = await Promise.all(
            collaboratorChunks.map(chunk => getScheduleWithLocation(chunk, dateRange))
        );

        // Flatten the array of schedules into one list
        const schedules = schedulesChunks
            .filter((result): result is HybridspaceScheduleInformation[] => result !== null)
            .flatMap(result => result);

        collaborators.map(collaborator => {
            // Filter out any undefined or null schedule options before finding the correct schedule
            const validSchedules = schedules?.filter(
                (scheduleOption): scheduleOption is HybridspaceScheduleInformation =>
                    scheduleOption !== undefined && scheduleOption !== null
            );

            // If there are no valid schedules, return early bc we dont have enough information
            // to determine a rare visit
            if (validSchedules === undefined || validSchedules.length === 0) {
                collaboratorsWithInvalidSchedules.push(collaborator);
                return;
            }

            const schedule = validSchedules?.find(
                (scheduleOption: HybridspaceScheduleInformation) =>
                    scheduleOption.scheduleId === collaborator
            );

            const schedulesCorrupted = schedule?.error != undefined;
            if (!schedulesCorrupted) {
                // Collect building IDs from the collaborator's flexible working hours
                const buildingIds: string[] =
                    schedule?.flexibleWorkingHours
                        ?.map(
                            (flexibleWorkingHour: HybridspaceGetScheduleFlexibleWorkingHoursView) =>
                                flexibleWorkingHour.building?.id
                        )
                        .filter((id): id is string => !!id) || []; // Filter out undefined and ensure the result is a string[]

                const uniqueBuildingIds = new Set(buildingIds);
                const lowerCaseCollaborator = collaborator.toString().toLowerCase();
                collaboratorToBuildingMap.set(
                    lowerCaseCollaborator as UserEmailAddress,
                    Array.from(uniqueBuildingIds)
                );
            }
        });
    } catch (error) {
        // Handle any errors that occur during the scheduling fetch process
        perfDatapoint.endWithError(DatapointStatus.ServerError, error);
        throw error;
    }

    perfDatapoint.addCustomData({
        collaboratorsWithInvalidSchedules: collaboratorsWithInvalidSchedules.length,
    });
    perfDatapoint.end();
    return collaboratorToBuildingMap;
}
