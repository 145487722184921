import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import { GlobalCalendarLanguage } from 'owa-datetime';
import type { MailboxInfo } from 'owa-client-types';
import getCalendarCloudSettings from './getCalendarCloudSettings';

export default function getGlobalCalendarLanguageCloudSetting(
    mailboxInfo: MailboxInfo
): GlobalCalendarLanguage {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const globalCalendarLanguageSetting = accountSettings.get(
        CalendarCloudSettingType.GlobalCalendarLanguage.toLowerCase()
    );

    return globalCalendarLanguageSetting
        ? (globalCalendarLanguageSetting.value as GlobalCalendarLanguage)
        : GlobalCalendarLanguage.English;
}
