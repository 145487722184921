import type CalendarCloudSetting from '../store/schema/CalendarCloudSetting';
import { getAccountDefaultSettings, getStore } from '../store/store';
import type { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

/**
 * Gets the value for the enableOnlineMeetingsByDefault cloud setting
 */
export default function getCalendarCloudSettings(
    mailboxInfo: MailboxInfo
): ObservableMap<string, CalendarCloudSetting> {
    const mapKey = getIndexerValueForMailboxInfo(mailboxInfo);
    if (!getStore().accounts.get(mapKey)) {
        getStore().accounts.set(mapKey, getAccountDefaultSettings());
    }
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return getStore().accounts.get(mapKey)!.settings;
}
