/**
 * Internal list of dialog providers, shared between DialogProvider and showDialog.
 *
 * The list itself is exported (as opposed to add/remove/read methods) to ensure that the bundle
 * linking to DialogProvider has only code that writes to the list, while the bundle using showDialog
 * has only code reading from the list. This ensures each bundle contains only the necessary code.
 */
export const dialogProviders: {
    add: (dialog: JSX.Element) => void;
    remove: (dialog: JSX.Element) => void;
}[] = [];
