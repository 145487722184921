import type { FlexibleWorkingHoursView } from 'owa-graph-schema';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { lazyGetWorkingHoursViewGraphQL } from 'owa-calendar-surface-work-locations';
import { addDays, isBefore } from 'owa-datetime';
import { getDevErrorPageState } from 'places-diagnostics';

import type { DateRange } from 'owa-datetime-utils';

export default async function getPlacesEmployeeFWH(
    currentCalendarDateRangeView: DateRange
): Promise<FlexibleWorkingHoursView[]> {
    // Manually throw an error to test error handling
    if (getDevErrorPageState() === 'UserFWH') {
        throw new Error('could not get user flexible working hours');
    }
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    const queryStartDate = currentCalendarDateRangeView.start;
    const queryEndDate = addDays(
        currentCalendarDateRangeView.end,
        1 /* add one day to create the range */
    );

    const invalidRange = isBefore(queryEndDate, queryStartDate);
    if (invalidRange) {
        const message = `Invalid dateRange - start: ${queryStartDate}, end: ${queryEndDate}`;
        logUsage('PlacesGetEmployeeFlexibleWorkingHours_rangeError', {
            message,
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            stack: new Error(message).stack,
        });
    }

    const employeeFWH = await lazyGetWorkingHoursViewGraphQL.importAndExecute(
        mailboxInfo,
        queryStartDate,
        queryEndDate
    );

    const workingHoursPerDay = employeeFWH?.workingHoursPerDay;
    // if there is an error in the response, throw and observable operation will catch and report it
    if (employeeFWH === null || !workingHoursPerDay || !workingHoursPerDay[0]) {
        const message = `Cannot Get Flexible Working Hours Info for - start: ${queryStartDate}, end: ${queryEndDate}`;
        logUsage('PlacesGetEmployeeFlexibleWorkingHours_doesNotExistError', {
            message,
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            stack: new Error(message).stack,
        });
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(message);
    }

    const flexibleWorkingHours: FlexibleWorkingHoursView[] = workingHoursPerDay[0].segments ?? [];

    return flexibleWorkingHours;
}
