//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { LocationFieldsFragmentDoc } from 'owa-calendar-events-graphql-documents/lib/graphql/shared/__generated__/SharedFullEventFragment.interface';
export type CalendarViewQueryVariables = Types.Exact<{
    input: Types.GetCalendarViewRequest;
}>;
export type CalendarViewQuery = {
    __typename?: 'Query';
    calendarView: {
        __typename?: 'GetCalendarViewResponse';
        calendarEvents: Array<{
            __typename?: 'CalendarEvent';
            id: string;
            isOrganizer?: boolean | null;
            subject?: string | null;
            isAllDayEvent?: boolean | null;
            freeBusyType?: Types.BusyType | null;
            meetingDetails?: {
                __typename?: 'MeetingDetails';
                isCancelled?: boolean | null;
                isSeriesCancelled?: boolean | null;
                responseType?: Types.ResponseType | null;
                doNotForwardMeeting?: boolean | null;
                resources?: Array<{
                    __typename?: 'CalendarParticipant';
                    userIdentity?: {
                        __typename?: 'ParticipantIdentity';
                        emailAddress?: string | null;
                        name?: string | null;
                    } | null;
                    responseStatus?: {
                        __typename?: 'ResponseStatus';
                        responseType?: Types.ResponseType | null;
                    } | null;
                } | null> | null;
                requiredAttendees?: Array<{
                    __typename?: 'CalendarParticipant';
                    userIdentity?: {
                        __typename?: 'ParticipantIdentity';
                        emailAddress?: string | null;
                    } | null;
                    responseStatus?: {
                        __typename?: 'ResponseStatus';
                        responseType?: Types.ResponseType | null;
                    } | null;
                } | null> | null;
                optionalAttendees?: Array<{
                    __typename?: 'CalendarParticipant';
                    userIdentity?: {
                        __typename?: 'ParticipantIdentity';
                        emailAddress?: string | null;
                    } | null;
                    responseStatus?: {
                        __typename?: 'ResponseStatus';
                        responseType?: Types.ResponseType | null;
                    } | null;
                } | null> | null;
            } | null;
            organizer?: {
                __typename?: 'CalendarParticipant';
                userIdentity?: {
                    __typename?: 'ParticipantIdentity';
                    emailAddress?: string | null;
                } | null;
                responseStatus?: {
                    __typename?: 'ResponseStatus';
                    responseType?: Types.ResponseType | null;
                } | null;
            } | null;
            start?: {
                __typename?: 'ExtendedDateTime';
                dateTime: string;
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            } | null;
            end?: {
                __typename?: 'ExtendedDateTime';
                dateTime: string;
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            } | null;
            locations?: Array<{
                __typename?: 'Location';
                displayName?: string | null;
                annotation?: string | null;
                id?: string | null;
                idType?: Types.LocationIdType | null;
                locationType?: Types.LocationEntityType | null;
                locationUri?: string | null;
                locationSource?: Types.LocationSourceType | null;
                postalAddress?: {
                    __typename?: 'PersonaPostalAddress';
                    street?: string | null;
                    city?: string | null;
                    state?: string | null;
                    country?: string | null;
                    postalCode?: string | null;
                    postOfficeBox?: string | null;
                    type?: Types.PostalAddressType | null;
                    formattedAddress?: string | null;
                } | null;
                coordinates?: {
                    __typename?: 'Coordinates';
                    latitude?: number | null;
                    longitude?: number | null;
                    accuracy?: number | null;
                    altitude?: number | null;
                    altitudeAccuracy?: number | null;
                } | null;
            } | null> | null;
        } | null>;
    };
};
export const CalendarViewDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CalendarView' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GetCalendarViewRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendarView' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'request' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarEvents' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'meetingDetails' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isCancelled',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isSeriesCancelled',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responseType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'resources',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userIdentity',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'emailAddress',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'responseStatus',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'responseType',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'requiredAttendees',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userIdentity',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'emailAddress',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'responseStatus',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'responseType',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'optionalAttendees',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userIdentity',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'emailAddress',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'responseStatus',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'responseType',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'doNotForwardMeeting',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'organizer' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userIdentity',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'emailAddress',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responseStatus',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'responseType',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isOrganizer' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subject' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'start' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dateTime',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'timeZone',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'end' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dateTime',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'timeZone',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isAllDayEvent' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'freeBusyType' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'locations' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'LocationFields',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...LocationFieldsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<CalendarViewQuery, CalendarViewQueryVariables>;
