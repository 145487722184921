import { owaDate } from 'owa-datetime';
import { isPastTime } from 'owa-observable-datetime';
import isMultiDayEvent from './isMultiDayEvent';

import type { CalendarEvent, WorkLocationType } from 'owa-graph-schema';

// This util decides if we should suggest adding rooms for a given event.
// It currently shows in 2 places: Add room button in places agenda view and everywhere quickbook shows up (Daily peek in calendar and places, agenda view in places)
export default function doesEventNeedRoom(
    calendarEvent: CalendarEvent,
    workLocationType: WorkLocationType,
    allowAppointments: boolean = false
): boolean {
    if (!calendarEvent) {
        // If calendarEvent is null, return false
        return false;
    }

    if (isPastTime(owaDate(calendarEvent.end?.timeZone.name, calendarEvent.end?.dateTime))) {
        // If event is in past, don't suggest adding room to event
        return false;
    }

    if (workLocationType != 'Office') {
        // Only suggest rooms if user is in office
        return false;
    }

    if (!allowAppointments && isAppointment(calendarEvent)) {
        // If event is an appointment, don't suggest adding room to event
        return false;
    }

    if (calendarEvent.isAllDayEvent) {
        // If event is an all day event, don't suggest adding room to event
        return false;
    }

    if (isMultiDayEvent(calendarEvent)) {
        // If event spans multiple days, don't suggest adding room to event
        return false;
    }

    if (!!calendarEvent.meetingDetails?.doNotForwardMeeting && !calendarEvent.isOrganizer) {
        // If meeting forward is disabled and user is not organizer, don't suggest adding room to event
        return false;
    }

    if (
        calendarEvent.meetingDetails?.isCancelled ||
        calendarEvent.meetingDetails?.isSeriesCancelled
    ) {
        // If event is cancelled, don't suggest adding room to event
        return false;
    }

    if (calendarEvent.meetingDetails?.responseType == 'Decline') {
        // If user has declined event, don't suggest adding room to event
        return false;
    }

    if (calendarEvent.freeBusyType != 'Busy' && calendarEvent.freeBusyType != 'Tentative') {
        // Only suggest adding room to event if freeBusyType is Busy or Tentative. So return false if freeBusyType is not busy and not tentative
        return false;
    }

    // If none of the conditions above are met, suggest adding room to event
    return true;
}

function isAppointment(calendarEvent: CalendarEvent): boolean {
    const requiredAttendees = calendarEvent.meetingDetails?.requiredAttendees?.filter(
        attendee => attendee?.responseStatus?.responseType !== 'Organizer'
    );

    return (
        (!requiredAttendees || requiredAttendees.length == 0) &&
        (!calendarEvent?.meetingDetails?.optionalAttendees ||
            calendarEvent.meetingDetails.optionalAttendees?.length == 0)
    );
}
