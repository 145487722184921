//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { RecurringFlexibleWorkingHoursFragmentFragmentDoc } from './recurringFlexibleWorkingHoursFragment.interface';
export type RecurringFlexibleWorkingHoursQueryQueryVariables = Types.Exact<{
    input: Types.ListFlexibleWorkingHoursRequest;
}>;
export type RecurringFlexibleWorkingHoursQueryQuery = {
    __typename?: 'Query';
    flexibleWorkingHours?: {
        __typename?: 'ListFlexibleWorkingHoursResponse';
        segments?: Array<{
            __typename?: 'FlexibleWorkingHours';
            id: string;
            workLocationType: Types.WorkLocationType;
            start: {
                __typename?: 'ExtendedDateTime';
                dateTime: string;
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            };
            end: {
                __typename?: 'ExtendedDateTime';
                dateTime: string;
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            };
            recurrenceDetailsWH?: {
                __typename?: 'RecurrenceDetailsWH';
                recurrence: {
                    __typename?: 'PatternedRecurrenceWH';
                    recurrencePattern?: {
                        __typename?: 'RecurrencePatternWH';
                        weeklyRecurrencePattern: {
                            __typename?: 'WeeklyRecurrencePatternWH';
                            interval: number;
                            firstDayOfWeek: Types.DayOfWeek;
                            daysOfWeek: Array<Types.DayOfWeek>;
                        };
                    } | null;
                    recurrenceRange?: {
                        __typename?: 'RecurrenceRangeWH';
                        endDateRecurrenceRange?: {
                            __typename?: 'EndDateRecurrenceRangeWH';
                            start?: {
                                __typename?: 'ExtendedDateTime';
                                dateTime: string;
                                timeZone: {
                                    __typename?: 'TimeZoneWH';
                                    name: string;
                                };
                            } | null;
                            end?: {
                                __typename?: 'ExtendedDateTime';
                                dateTime: string;
                                timeZone: {
                                    __typename?: 'TimeZoneWH';
                                    name: string;
                                };
                            } | null;
                        } | null;
                        noEndRecurrenceRange?: {
                            __typename?: 'NoEndRecurrenceRangeWH';
                            start?: {
                                __typename?: 'ExtendedDateTime';
                                dateTime: string;
                                timeZone: {
                                    __typename?: 'TimeZoneWH';
                                    name: string;
                                };
                            } | null;
                        } | null;
                    } | null;
                };
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            } | null;
            workLocationDetails?: {
                __typename?: 'Location';
                id?: string | null;
                idType?: Types.LocationIdType | null;
                displayName?: string | null;
                annotation?: string | null;
                locationType?: Types.LocationEntityType | null;
                locationUri?: string | null;
                locationSource?: Types.LocationSourceType | null;
            } | null;
        }> | null;
    } | null;
};
export const RecurringFlexibleWorkingHoursQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'RecurringFlexibleWorkingHoursQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ListFlexibleWorkingHoursRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flexibleWorkingHours' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'request' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'segments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RecurringFlexibleWorkingHoursFragment',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...RecurringFlexibleWorkingHoursFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<
    RecurringFlexibleWorkingHoursQueryQuery,
    RecurringFlexibleWorkingHoursQueryQueryVariables
>;
