import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the EndLongEventsBy cloud setting
 */
export default function getEndLongEventsByCloudSetting(
    mailboxInfo: MailboxInfo
): number | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const endLongEventsBySetting = accountSettings.get(
        CalendarCloudSettingType.EndLongEventsBy.toLowerCase()
    );

    return endLongEventsBySetting != null ? +endLongEventsBySetting.value : undefined;
}
