import React from 'react';
import Loading from './Loading';
import NetworkError from './NetworkError';
import { observer } from 'owa-mobx-react';

export default observer(function Interstitial({
    succeeded,
    failed,
    errorMessage,
    onRetry,
    onCancel,
    onRenderError,
    children,
}: {
    succeeded: boolean;
    failed: boolean;
    errorMessage?: string;
    onRetry?: () => void;
    onCancel?: () => void;
    onRenderError?: () => React.ReactElement;
    children: React.ReactElement;
}) {
    if (succeeded) {
        return children;
    } else if (failed) {
        if (onRenderError) {
            return onRenderError();
        } else {
            return (
                <NetworkError errorMessage={errorMessage} onRetry={onRetry} onCancel={onCancel} />
            );
        }
    } else {
        return <Loading />;
    }
},
'Interstitial');
