import MoreHorizontalRegular from 'owa-fluent-icons-svg/lib/icons/MoreHorizontalRegular';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import {
    Button,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
    Tooltip,
} from '@fluentui/react-components';
import { Icon } from '@fluentui/react/lib/Icon';
import MoreMenuListItems from './MoreMenuListItems';
import { moreButtonLabel } from './PlacesMoreDropdown.locstring.json';
import { placesMoreButton, placesMoreButtonMargin } from './PlacesMoreDropdown.scss';

const MoreHorizontalIcon = <Icon iconName={MoreHorizontalRegular} />;

export default observer(function PlacesMoreDropdown({
    isStandaloneApp,
}: {
    isStandaloneApp: boolean;
}) {
    const telemetryId = 'SettingsAndMoreButton';

    return (
        <Menu defaultOpen={false}>
            <Tooltip
                content={loc(moreButtonLabel)}
                relationship="label"
                positioning="below"
                withArrow={true}
            >
                <MenuTrigger disableButtonEnhancement={true}>
                    <Button
                        className={
                            isStandaloneApp
                                ? mergeClasses(placesMoreButton, placesMoreButtonMargin)
                                : placesMoreButton
                        }
                        icon={MoreHorizontalIcon}
                        appearance="subtle"
                        data-telemetry-id={telemetryId}
                        aria-label={loc(moreButtonLabel)}
                        tabIndex={0}
                    ></Button>
                </MenuTrigger>
            </Tooltip>

            <MenuPopover>
                <MenuList>
                    <MoreMenuListItems telemetryParentId={telemetryId} />
                </MenuList>
            </MenuPopover>
        </Menu>
    );
},
'PlacesMoreDropdown');
