import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { makePostRequest } from 'owa-ows-gateway';

import getPlace from './getPlace';

import type { DeskPoolSpace, Place } from 'owa-graph-schema';

export default async function getPlaceFromWorkspace(workspaceId: string): Promise<Place> {
    const perfDatapoint = new PerformanceDatapoint('PlacesGetPlaceFromWorkspace');
    perfDatapoint.addCustomData({ workspaceId });
    let place;
    const endpoint =
        "locations/odata/api/v2.0/Places('')/Microsoft.Exchange.LocationsAndTime.GetSpaces";

    try {
        const workspaceResponse = await makePostRequest(
            endpoint,
            {
                PlaceIds: [workspaceId],
            },
            undefined, // correlationId
            true, // returnFullResponse
            undefined, // customHeaders
            undefined, // throwServiceError
            true, // sendPayloadAsBody
            undefined, // includeCredentials
            'space', // actionName
            undefined, // datapoint
            getGlobalSettingsAccountMailboxInfo()
        );
        const workspacesResult = await workspaceResponse.json();
        const workspaces = workspacesResult.value
            ? (workspacesResult.value as DeskPoolSpace[])
            : undefined;

        const buildingId = workspaces?.[0].buildingId;
        if (workspaces && buildingId) {
            perfDatapoint.addCustomData({ buildingId });
            place = await getPlace(buildingId);
        }
    } catch (error) {
        perfDatapoint.endWithError(DatapointStatus.ServerError, error);
    }

    if (place == undefined) {
        const message = `Place information undefined for this workspace Id: ${workspaceId}`;
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const error = new Error(message);
        perfDatapoint.endWithError(DatapointStatus.ClientError, error);
        throw error;
    }

    perfDatapoint.end();
    return place;
}
