import React from 'react';
import { centered } from './styles.scss';

import { observer } from 'owa-mobx-react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

export default observer(function Loading() {
    return (
        <div className={centered}>
            <Spinner size={SpinnerSize.large} />
        </div>
    );
}, 'Loading');
