import { PerformanceCoreDatapoint, DatapointStatus } from 'owa-analytics';
import type { LogModules } from './enum';
import { type Status } from './enum';
import type { PropertiesType } from './types/PropertiesType';
import { getPropsWithCompliantTitleId } from './appIdUtils';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';

export class PerfMarker extends PerformanceCoreDatapoint {
    constructor(
        metricName: AnalyticsCoreEventNames,
        logModule: LogModules,
        correlationId: string,
        source?: string
    ) {
        super(metricName);
        this.addCustomData({
            LogModule: logModule,
            CorrelationId: correlationId ? correlationId : '',
            source: source || '',
        });
    }
    /**
     * Event Type: client_event,
     * Log perf end marker in case of Success.
     */
    logMetricEnd(metricProps?: PropertiesType) {
        this.addMetricProps({ ...metricProps });
        this.end();
    }

    /**
     * Event Type: client_event,
     * Log perf end marker in case of Failure.
     */
    logMetricEndWithError(status: Status, errorMessage: string, metricProps?: PropertiesType) {
        this.addCustomData({
            ErrorMessage: errorMessage,
        });
        this.addMetricProps({ ...metricProps });
        this.endWithError(
            status == 'ServerError'
                ? DatapointStatus.ServerError
                : status == 'ClientError'
                ? DatapointStatus.ClientError
                : DatapointStatus.Timeout
        );
    }

    /**
     * Event Type: client_event,
     * Log additional metric properties.
     */
    addMetricProps(metricProps: PropertiesType) {
        const propsWithCompliantTitleId: PropertiesType = getPropsWithCompliantTitleId(metricProps);
        this.addCustomData({ ...propsWithCompliantTitleId });
    }
}
