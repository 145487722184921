import getDefaultShortenEventScope from './getDefaultShortenEventScope';
import getDefaultShouldEventsEndEarly from './getDefaultShouldEventsEndEarly';
import { ShortenEventScopeType } from '../store/schema/ShortenEventScopeType';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Gets whether the event should end early or not
 */
export default function getDefaultShouldShortenEventDurationEndEarly(
    mailboxInfo: MailboxInfo
): boolean {
    const shortenEventScope = getDefaultShortenEventScope(mailboxInfo);

    return shortenEventScope
        ? shortenEventScope === ShortenEventScopeType.EndEarly
        : getDefaultShouldEventsEndEarly(mailboxInfo);
}
