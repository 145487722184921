import { LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { onInitializeLPC } from 'owa-nova-actions/lib/actions/onInitializeLPC';
import { onOpenLPC } from 'owa-nova-actions/lib/actions/onOpenLPC';
import { AppHostServiceEvents } from 'owa-app-host-events';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaLpcInitialize" */ './lazyIndex'),
    {
        name: 'OwaLpcInitialize',
    }
);

let lpcOrcherstratorsRegistered = false;

export function registerLPCInitializeOrchestrator() {
    if (lpcOrcherstratorsRegistered) {
        return;
    }
    registerLazyOrchestrator(onInitializeLPC, lazyModule, m => m.onInitializeLPCOrchestrator);
    registerLazyOrchestrator(onOpenLPC, lazyModule, m => m.onOpenLPCOrchestrator);
    // Pre-initialize LPC
    onInitializeLPC(
        AppHostServiceEvents.initializeLPC(() => ({
            viewerMailboxInfo: getGlobalSettingsAccountMailboxInfo(),
        }))
    );
    lpcOrcherstratorsRegistered = true;
}
