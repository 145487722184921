import { getQueryStringParameter, hasQueryStringParameter, removeQuery } from 'owa-querystring';
import {
    updateExploreDiagnosticsErrorState,
    updateExploreDiagnosticsPageState,
    updateExploreDiagnosticsComponentState,
} from '../store/explorePageDiagnotsticStoreMutators';

export function getDefaultValue(query: string): string | undefined {
    if (hasQueryStringParameter(query)) {
        return getQueryStringParameter(query);
    } else {
        return undefined;
    }
}

export function setExploreComponentState(value: string) {
    if (value === 'None') {
        removeQueryParam('ecomponentState');
        updateExploreDiagnosticsComponentState('None');
    } else {
        updateQueryParam('ecomponentState', value);
        updateExploreDiagnosticsComponentState(value);
    }
}

export function setExplorePageState(value: string) {
    if (value === 'None') {
        removeQueryParam('epageState');
        updateExploreDiagnosticsPageState('None');
    } else {
        updateQueryParam('epageState', value);
        updateExploreDiagnosticsPageState(value);
    }
}

export function setExploreErrorPageState(value: string) {
    if (value === 'None') {
        removeQueryParam('epageError');
        updateExploreDiagnosticsErrorState('None');
    } else {
        updateQueryParam('epageError', value);
        updateExploreDiagnosticsErrorState(value);
    }
}

function updateQueryParam(query: string, key: string) {
    // Parse the current URL
    const url = new URL(window.location.href);
    // Get the current query parameters
    const params = new URLSearchParams(url.search);
    // Update the specified parameter
    params.set(query, key);
    // Set the search property of the URL object
    url.search = params.toString();
    // Update the browser's history
    window.history.pushState({ path: url.href }, '', url.href);
}

function removeQueryParam(query: string) {
    const newURL = removeQuery(location.search, query);
    history.replaceState(history.state, '/', location.pathname + newURL);
}
