import type { PropertiesType } from './types/PropertiesType';

export function getPropsWithCompliantTitleId(metricProps: PropertiesType): PropertiesType {
    const titleIdKey = Object.keys(metricProps).find(key => key.toLowerCase() === 'titleid');
    if (titleIdKey) {
        const titleId = metricProps[titleIdKey];
        if (typeof titleId === 'string') {
            if (titleId.startsWith('P_')) {
                metricProps[titleIdKey] = titleId;
            } else if (titleId.startsWith('T_')) {
                metricProps[titleIdKey] = 'T_';
            } else if (titleId.startsWith('U_')) {
                metricProps[titleIdKey] = 'U_';
            } else {
                metricProps[titleIdKey] = '';
            }
        }
    }
    return metricProps;
}
