import type ThemeImageSearchViewState from 'eventify-schema/lib/ThemeImageSearchViewState';
import { getThemeImages } from 'accelerator-common/lib/utils/getThemeImages';

export default function createEmptyThemeImageSearchViewState(): ThemeImageSearchViewState {
    return {
        keyword: '',
        showLoadMoreButton: false,
        images: getThemeImages(),
    };
}
