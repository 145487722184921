import { savedCollaboratorTable, suggestedCollaboratorTable } from '../database';

/**
 * Fetch all saved and suggested collaborators, sorted by orderIndex
 */
export async function fetchCollaborators() {
    const [savedCollaborators, suggestedCollaborators] = await Promise.all([
        savedCollaboratorTable.toArray(),
        suggestedCollaboratorTable.toArray(),
    ]);

    const sortedSavedCollaborators = savedCollaborators
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map(c => c.uniqueId);

    const sortedSuggestedCollaborators = suggestedCollaborators
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map(c => c.uniqueId)
        .filter(email => !sortedSavedCollaborators.includes(email));

    return [...sortedSavedCollaborators, ...sortedSuggestedCollaborators];
}
