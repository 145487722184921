import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

import type { UTPlaceReservationOriginType } from 'owa-unified-telemetry';
import type { PlaceType } from 'hybridspace-common/lib/constants';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceGlobal" */ './lazyIndex')
);

export const GlobalActionButton = createLazyComponent(lazyModule, m => m.GlobalActionButton);

const lazyShowGlobalActionButtonPlacesFinder = new LazyAction(
    lazyModule,
    m => m.showGlobalActionButtonPlacesFinder
);
export function showGlobalActionButtonPlacesFinder(
    placeType: PlaceType,
    buildingId?: string,
    entrypoint?: UTPlaceReservationOriginType
) {
    return lazyShowGlobalActionButtonPlacesFinder.importAndExecute(
        placeType,
        buildingId,
        entrypoint
    );
}
