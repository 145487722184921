import { isRunningInMetaOSHub } from 'owa-config';

export default function usePlacesRouteLayout() {
    const isHosted = isRunningInMetaOSHub();
    const isStandalone = !isHosted;

    const showCompactAppHeader = isStandalone;

    return {
        showCompactAppHeader,
    };
}
