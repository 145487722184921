import { logCoreUsage, logGreyError } from 'owa-analytics';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { lazyNotifyAppLoaded, lazyNotifySuccess } from 'owa-metaos-app-bootstrap';
import { useMetaOSAppLoaded } from 'owa-react-hooks/lib/useMetaOSAppLoaded';
import { setModuleStateSafe } from 'places-user-session-stores';
import React from 'react';
import { getMetaOSDeepLinkSubPath } from './getMetaOSDeepLinkSubPath';

import type { ResumeContext } from '@microsoft/teams-js';
import type { NavigateFunction } from 'react-router-dom';

/**
 * hubsdk's navigate function will be called with the url of the cached link.
 * In this case, we received: "/hosted/places/home?hostApp=teams "
 * So, we're passing this string into react-router's navigate function, which internally appends from the basename of the app.
 * We would be getting "/hosted/places/https:/outlook.office.com/hosted/places/home?hostApp=teams "
 * to fix this, this strip function was introduced to clean off the basename. the solution would work if it's /hosted/places and /places.
 */
function stripUrlToPlaces(urlWithoutHostname: string) {
    const placesBasename = '/places';
    const startIndex = urlWithoutHostname.indexOf(placesBasename);
    return startIndex !== -1
        ? urlWithoutHostname.substring(startIndex + placesBasename.length)
        : '/'; // fallback to root if not found
}

export default function useAppCaching(navigate: NavigateFunction) {
    useMetaOSAppLoaded(
        {
            key: 'hybridSpace',
            handler: React.useCallback(
                (context?: ResumeContext) => {
                    lazyNotifyAppLoaded.importAndExecute();
                    lazyNotifySuccess.importAndExecute();

                    if (!context?.contentUrl) {
                        return;
                    }
                    const { contentUrl, entityId } = context;
                    // contentUrl consist of the url that hub calls of the app, which is defined in Manifest's staticTab.
                    // constructing pathname+search to remove protocol+hostname.
                    const cleanContentUrl = stripUrlToPlaces(
                        contentUrl.pathname + contentUrl.search
                    );

                    // Places Deeplink scenario pass in subPath via subPageId/subEntityId(legacy name) to navigate to the specific pages.
                    // Example: https://teams.microsoft.com/l/entity/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc?context= {"subEntityId": "/buildings/727c494d-cb83-4e7b-9e02-71a020b5c444"}
                    getMetaOSDeepLinkSubPath()
                        .then(deeplinkSubPath => {
                            // If subPath is provided (DeepLink), navigate to the subPath, otherwise navigate to the cleanUrl.
                            const navigateToUrl = deeplinkSubPath || cleanContentUrl;
                            if (navigateToUrl) {
                                navigate(navigateToUrl);

                                // marking this as core because it's critical to log this event to keep track of app cache hit rate.
                                logCoreUsage('PlacesAppCacheSuccess', {
                                    contentUrl: scrubForPii(navigateToUrl),
                                    entityId,
                                    urlType: deeplinkSubPath ? 'deeplink' : 'contentUrl',
                                });
                            }
                        })
                        .catch(e => {
                            logGreyError('[AppCaching OnLoad] Failed to get deeplink subpath', e);
                        })
                        .finally(() => {
                            setModuleStateSafe('complete');
                        });
                },
                [navigate]
            ),
        },
        {
            key: 'hybridSpace',
            handler: React.useCallback(() => {
                // marking this as core because it's critical to log this event to
                // understand when does the user leave, and when they leave, where are they at.
                // TODO when Places rollout to GA, and on the day we get high usage, we'll remove isCore.
                logCoreUsage('PlacesAppCacheBeforeUnload', {
                    contentUrl: scrubForPii(window.location.pathname),
                });
                setModuleStateSafe('metaOsAppCache');
            }, []),
        }
    );
}
