export enum HomeRouteId {
    ROOT = 'root',
    ROOT_SPLAT = 'root-splat',
    HOME = 'home',
    HOME_REDIRECT = 'home-redirect',
    BUILDINGS_DEEP_LINK = 'building-deep-link',
    WORK_PLAN = 'workplan',
    PEOPLE = 'people',
    WORKPLAN_HOME_REDIRECT = 'workplan-home-redirect',
    PEOPLE_HOME_REDIRECT = 'people-home-redirect',
    WORK_GROUPS = 'workgroups',
    ADMIN = 'admin',
    ADMIN_SPACE_MANAGEMENT = 'space-management',
    ADMIN_SPACE_ANALYTICS = 'space-analytics',
    DEV_PANEL = 'devpanel',
}
