import { action } from 'satcheljs';
import type { OwaDate } from 'owa-datetime';

// In the map tab, flip the agenda view to the places finder
export const switchToPlacesFinder = action(
    'switchToPlacesFinder',
    (placesType: 'Room' | 'DeskPool') => ({
        placesType,
    })
);

// In the map tab, fip the places finder back to the agenda view
export const backToAgendaView = action('backToAgendaView');

export const onOpenMapTab = action('onOpenMapTab');

export const onCloseMapTab = action('onCloseMapTab');

export const onOpenAgendaPanel = action('onOpenAgendaPanel');

export const onChangeCurrentDate = action('onChangeCurrentDate', (newDate: OwaDate) => ({
    newDate,
}));
