import { getUserExternalDirectoryIdWithPrefix } from 'accelerator-aad/lib/getUserExternalDirectoryIdWithPrefix';
import { getGqlDateTime } from 'hybridspace-graphql/lib/utils/getGqlDateTime';
import { queryWithError } from 'hybridspace-graphql/lib/utils/queryWithError';
import { getEntryPoint } from 'hybridspace-telemetry';
import { logUsage } from 'owa-analytics';
import { getDevErrorPageState } from 'places-diagnostics';
import { MAX_COLLABORATORS_LIMIT } from '../constants/apiConstants';
import { GetCollaboratorsDocument } from '../graphql/__generated__/CollaboratorsQuery.interface';

import type { DateRange } from 'owa-datetime-utils';
import type { CollaboratorGroup, Collaborator as GQLCollaborator } from 'owa-graph-schema';
import type { Collaborator } from '../serviceTypes/Collaborator';

export default async function getCollaborators(
    dateRange: DateRange,
    groupsToInclude?: CollaboratorGroup[]
) {
    let filteredAddresses;
    try {
        // Manually throw an error to test error handling
        if (getDevErrorPageState() === 'Collaborators') {
            throw new Error('could not get collaborators');
        }

        /**
         * In case we need to override fetch policy to go to network.
         * most likely we'll use cache-and-network first, so we read from cache first,
         * then it fires a network call as well, so it'll update Apollo cache, and next will continue wit cache-first
         *  */
        const { data } = await queryWithError(GetCollaboratorsDocument, {
            variables: {
                input: {
                    mailboxId: getUserExternalDirectoryIdWithPrefix(),
                    timeRange: {
                        start: getGqlDateTime(dateRange.start),
                        end: getGqlDateTime(dateRange.end),
                    },
                    groupsToInclude: (groupsToInclude as CollaboratorGroup[]) || undefined,
                    maximumCount: MAX_COLLABORATORS_LIMIT,
                },
            },
        });

        if (data?.listCollaborators) {
            const collaboratorsHasDataNeeded = (
                data?.listCollaborators?.collaborators || []
            ).filter(collaborator => {
                const hasDataNeeded =
                    !!collaborator?.mailboxId && collaborator?.primarySmtpAddress !== '';
                if (!hasDataNeeded) {
                    logUsage('PlacesEmptyCollaboratorFound', {
                        hasMailboxId: false,
                        from: 'general',
                        entryPoint: getEntryPoint(),
                    });
                }
                return hasDataNeeded;
            }) as GQLCollaborator[];

            filteredAddresses = collaboratorsHasDataNeeded.map(collaborator => {
                // Removed Es lint disable no non null assertion because don't need to map the data to a new object after properly typing the filter above.
                const mappedCollaborator: Collaborator = {
                    mailboxId: collaborator.mailboxId || '',
                    isSaved: collaborator.source == 'Saved',
                    source: collaborator.source,
                    suggestedReasons: collaborator.suggestedReasons,
                    primarySmtpAddress: collaborator.primarySmtpAddress || '',
                    sortKey: collaborator.sortKey ? Number(collaborator.sortKey) : undefined,
                };
                return mappedCollaborator;
            });
        } else {
            throw new Error('PlacesGetCollaborators throw error - no listCollaborators');
        }
    } catch (error) {
        throw error;
    }
    return filteredAddresses;
}
