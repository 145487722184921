import React from 'react';
import { Portal } from '@fluentui/react-components';
import { dialogProviders } from './dialogProviders';
import removeItem from './removeItem';
import { useSimpleList } from './useSimpleList';

/**
 * DialogProvider enables the rendering of dialogs passed to `showDialog`.
 *
 * These dialogs are rendered by the DialogProvider using Portals,
 * ensuring they inherit React context values, such as the FluentProvider.
 * This allows Fluent controls within the dialogs to use the correct theme.
 *
 * It is recommended to place a DialogProvider near the root of the application,
 * typically as a child of the application's FluentProvider.
 */
export function DialogProvider() {
    const dialogProvider = useSimpleList<JSX.Element>();

    React.useEffect(() => {
        dialogProviders.push(dialogProvider);
        return () => removeItem(dialogProviders, dialogProvider);
    }, [dialogProvider]);

    return dialogProvider.map(dialog => <Portal key={dialog.key}>{dialog}</Portal>);
}
