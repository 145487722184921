import { createObservableOperation } from 'owa-observable-operation';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import type { DateRange } from 'owa-datetime-utils';
import fetchFWHForWeek from '../services/fetchFWHForWeek';

const {
    onSucceeded: loadFWHSucceeded,
    startAsync: loadFWHAsync,
    start: loadFWH,
} = createObservableOperation(
    'PLACES_LOAD_FWH',
    (dateRange: DateRange) => ({ dateRange }),
    ({ dateRange }) => getDateRangeISOString(dateRange),
    ({ dateRange }) => fetchFWHForWeek(dateRange)
);

export { loadFWHSucceeded, loadFWHAsync, loadFWH };
