import type { MailboxInfo } from 'owa-client-types';
import getShouldEventsEndEarlyCloudSetting from '../selectors/getShouldEventsEndEarlyCloudSetting';

const DEFAULT_SHOULD_EVENT_END_EARLY_FALLBACK = false;

/**
 * Gets the default value for the end events early option
 */
export default function getDefaultShouldEventsEndEarly(mailboxInfo: MailboxInfo): boolean {
    const shouldEventsEndEarly = getShouldEventsEndEarlyCloudSetting(mailboxInfo);

    return shouldEventsEndEarly != null
        ? shouldEventsEndEarly
        : DEFAULT_SHOULD_EVENT_END_EARLY_FALLBACK;
}
