import type { GuidelineDayInfo, DayOfWeek } from 'owa-graph-schema';
import React from 'react';

// Context used to track the current employee guideline.
export const GuidelineEmployeeContext = React.createContext<{
    officeDayPerPeriod: number;
    description: string;
    updateTime: string;
    createTime: string;
    creator: string;
    startDay: DayOfWeek;
    days: (GuidelineDayInfo | null)[];
    name: string;
    id: string;
}>({
    officeDayPerPeriod: 0,
    description: '',
    updateTime: '',
    createTime: '',
    creator: '',
    startDay: 'None',
    days: [],
    name: '',
    id: '',
});

export const useGuidelineEmployeeContext = () => React.useContext(GuidelineEmployeeContext);
