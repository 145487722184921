import { getBrowserHeight, getBrowserWidth } from 'owa-config';
import { useEffect, useMemo } from 'react';
import { createStore, mutatorAction } from 'satcheljs';
import {
    FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_SMALL_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS,
    FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS,
} from '../constants/responsivenessBreakpoints';

const store = createStore('windowDimensions', {
    width: getBrowserWidth(true /** skipCache */),
    height: getBrowserHeight(true /** skipCache */),
})();

const handleResize = mutatorAction('updateWindowDimensions', () => {
    store.width = getBrowserWidth(true /** skipCache */);
    store.height = getBrowserHeight(true /** skipCache */);
});

let useCount = 0;
let resizeObserver: ResizeObserver | undefined;

/**
 * Use this hook to get window innerWidth and innerHeight.
 * We are using ResizeObserver where possible and only using resize event as a fallback.
 * If checking for specific breakpoints use useIsWindowBreakpoint.
 */
export function useWindowDimensions() {
    useEffect(() => {
        if (useCount++ == 0) {
            window.addEventListener('resize', handleResize);
            if ('ResizeObserver' in window) {
                resizeObserver = new ResizeObserver(handleResize);
                resizeObserver.observe(document.body);
            }
        }
        return () => {
            if (--useCount == 0) {
                resizeObserver?.unobserve(document.body);
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    return { width: store.width, height: store.height };
}

/**
 * Check if the window is at a specific breakpoint.
 * Breakpoints follow the same pattern as Fluent UI. isScreenMediumUp is the same as Fluent UI's ms-screen-md-up, etc.
 * isScreenSmallUp doesn't have a Fluent UI counterpart as it is the smallest breakpoint so everything shuold be Small Up.
 * isScreenSmallUp >= 320
 * isScreenSmallDown < 480
 * isScreenMediumUp >= 480
 * isScreenMediumDown < 640
 * isScreenLargeUp >= 640
 * isScreenLargeDown < 1024
 * isScreenXLargeUp >= 1024
 * isScreenXLargeDown < 1366
 * isScreenXXLargeUp >= 1366
 * isScreenXXLargeDown < 1920
 * isScreenXXXLargeUp >= 1920
 * @returns Object with boolean values for each breakpoint.
 */
export const useWindowBreakpoints = (): {
    isScreenSmallUp: boolean;
    isScreenSmallDown: boolean;
    isScreenMediumUp: boolean;
    isScreenMediumDown: boolean;
    isScreenLargeUp: boolean;
    isScreenLargeDown: boolean;
    isScreenXLargeUp: boolean;
    isScreenXLargeDown: boolean;
    isScreenXXLargeUp: boolean;
    isScreenXXLargeDown: boolean;
    isScreenXXXLargeUp: boolean;
} => {
    const { width } = useWindowDimensions();

    return useMemo(() => {
        return {
            isScreenSmallUp: width >= FLUENT_SMALL_SCREEN_WIDTH_IN_PIXELS,
            isScreenSmallDown: width < FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS,
            isScreenMediumUp: width >= FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS,
            isScreenMediumDown: width < FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenLargeUp: width >= FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenLargeDown: width < FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXLargeUp: width >= FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXLargeDown: width < FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXXLargeUp: width >= FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXXLargeDown: width < FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS,
            isScreenXXXLargeUp: width >= FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS,
        };
    }, [width]);
};
