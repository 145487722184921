import { createObservableOperation } from 'owa-observable-operation';

import { getPlacesService } from 'places-service';

const emptyKey = '';

const {
    isInProgress,
    getLastError,
    start: loadPlaces,
    onSucceeded: loadPlacesSucceeded,
    onFailed: loadPlacesFailed,
    startAsync: loadPlacesAsync,
} = createObservableOperation(
    'PLACES_LOAD_LOCATIONS',
    () => ({}),
    ({}) => emptyKey,
    () => getPlacesService().getPlaces()
);

const loadPlacesInProgress = isInProgress.bind(null, emptyKey);
const getPlacesLastError = getLastError.bind(null, emptyKey);

export {
    loadPlacesInProgress,
    getPlacesLastError,
    loadPlaces,
    loadPlacesSucceeded,
    loadPlacesFailed,
    loadPlacesAsync,
};
