import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesPeople" */ './lazyIndex')
);

const lazyLoadPlacesPeopleModule = new LazyImport(lazyModule, m => m);

export function loadPlacesPeopleModule() {
    return lazyLoadPlacesPeopleModule.import();
}

export const HomeDashboard = createLazyComponent(lazyModule, m => m.HomeDashboard);

export const HybridspaceHomeDashboardContent = createLazyComponent(
    lazyModule,
    m => m.HybridspaceHomeDashboardContent
);

export const MergedWorkGroupsModule = createLazyComponent(
    lazyModule,
    m => m.MergedWorkGroupsModule
);
