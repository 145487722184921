import { lazyGetAppDefinition } from 'owa-app-definitions';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import type { AppDefinition } from 'owa-graph-schema';
import { convertAcquisitionToAppDefinition } from 'owa-m365-acquisitions/lib/transform/convertAcquisitionToAppDefinition';
import { TitleLaunchInfoDocument } from '../graphql/queries/__generated__/titleLaunchInfo.interface';
import { type LogModules, PerfMarker, type Status, logInfo } from 'owa-message-extension-analytics';
import { query } from 'owa-apollo';
import type { MailboxInfo } from 'owa-client-types';

export async function getAppDefinition(
    appId?: string,
    correlationId?: string,
    mailboxInfo?: MailboxInfo
) {
    let appInfo: AppDefinition | undefined | null = undefined;
    if (!!appId) {
        appInfo = await getAcquiredAppDefinition(appId, correlationId, mailboxInfo);
        if (isFeatureEnabled('mos-titleLaunchInfo') && !appInfo) {
            appInfo = await getAppFromTitleLaunchInfo(
                appId,
                isAddinMultiAccountEnabled() ? mailboxInfo : undefined
            );
        }
    }
    return appInfo;
}

async function getAcquiredAppDefinition(
    id: string,
    correlationId?: string,
    mailboxInfo?: MailboxInfo
): Promise<AppDefinition | undefined | null> {
    try {
        const appDefPayload = await lazyGetAppDefinition.importAndExecute(
            id,
            false,
            isAddinMultiAccountEnabled() ? mailboxInfo : undefined
        );
        return appDefPayload?.appDefinition;
    } catch (error) {
        logInfo('CardRendering', 'Unable to get app info for app.', '', correlationId ?? '');
    }

    return null;
}

async function getAppFromTitleLaunchInfo(
    id: string,
    mailboxInfo?: MailboxInfo,
    correlationId?: string
): Promise<AppDefinition | undefined> {
    const perfMarker = new PerfMarker(
        'message_extension_metrics_getAppFromTitleLaunchInfo',
        'MessageExtensionApps',
        correlationId ?? ''
    );
    try {
        const titleInfoResponse = await query(TitleLaunchInfoDocument, {
            variables: { titleId: id, mailboxInfo },
            fetchPolicy: 'cache-first',
        });
        const titleInfo = titleInfoResponse.data?.m365TitleLaunchInfo;
        if (!!titleInfo) {
            const appDefinition = convertAcquisitionToAppDefinition(titleInfo);

            perfMarker.logMetricEnd({
                titleId: appDefinition.titleId,
            });
            return appDefinition;
        }

        perfMarker.logMetricEndWithError('ClientError', 'No valid titleInfo found');
    } catch (error) {
        perfMarker.logMetricEndWithError('ClientError', error?.message);
    }

    return;
}
