import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import { CalendarViewDocument } from 'hybridspace-common/lib/graphql/__generated__/CalendarViewQuery.interface';
import { isMultiDayEvent } from 'hybridspace-room-booking-helpers';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { query } from 'owa-apollo';
import { getISOString, owaDate } from 'owa-datetime';

import type { OwaDate } from 'owa-datetime';
import type { CalendarEvent } from 'owa-graph-schema';
export default async function fetchCalendarView(
    startDate: OwaDate,
    endDate: OwaDate,
    origin: string,
    maxResults = 25,
    filterAllDayEvents = false
): Promise<CalendarEvent[]> {
    const perfDatapoint = new PerformanceDatapoint('PlacesFetchCalendarView');

    perfDatapoint.addCustomData({
        startDate: getISOString(startDate),
        endDate: getISOString(endDate),
        maxResults,
        filterAllDayEvents,
        origin,
    });

    const smtpAddress = getUserEmailAddress();
    const { data, error } = await query(CalendarViewDocument, {
        variables: {
            input: {
                dateRange: {
                    start: getISOString(startDate),
                    end: getISOString(endDate),
                },
                calendarEntryData: {
                    calendarId: {
                        id: 'HybridspaceCalendarView', // this var is not required on the server side, but it needs to be non-nullable
                        mailboxInfo: {
                            mailboxSmtpAddress: smtpAddress,
                            userIdentity: smtpAddress,
                        },
                    },
                },
                maxResults,
            },
        },
        fetchPolicy: 'network-only',
    });

    // If there is an error or no calendar events, throw an error
    if (error || !data?.calendarView?.calendarEvents) {
        const errorToThrow = new Error('Could not fetch calendar view data');
        perfDatapoint.endWithError(DatapointStatus.ServerError, errorToThrow);
        throw errorToThrow;
    }

    // Explicitly type the calendarEvents as an array of CalendarEvent or null
    const calendarEvents = data.calendarView.calendarEvents as (CalendarEvent | null)[];

    // Filter out null values
    let filteredEvents = calendarEvents.filter((event): event is CalendarEvent => event !== null);

    if (filterAllDayEvents) {
        filteredEvents = filteredEvents.filter(meeting => {
            const isAllDayMeeting = meeting?.isAllDayEvent; /*remove all day meetings */
            const isFreeMeeting = meeting?.freeBusyType == 'Free'; /*remove free meetings */
            const isMultiDay = isMultiDayEvent(meeting); /*remove multi day meetings */
            return !(isAllDayMeeting || isFreeMeeting || isMultiDay);
        });
    }

    // Sort by time
    filteredEvents = filteredEvents.sort(
        (objA, objB) =>
            Number(owaDate(objA?.start?.timeZone.name, objA?.start?.dateTime)) -
            Number(owaDate(objB?.start?.timeZone.name, objB?.start?.dateTime))
    );

    perfDatapoint.end();
    return filteredEvents;
}
