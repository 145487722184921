import { onSendErrorFeedback } from 'hybridspace-graphql/lib/utils/useManagedQueryWithError';
import { renderOCVFeedbackPanel } from 'accelerator-ocv';
import { getLogicalRing } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';
import { orchestrator } from 'satcheljs';
import renderFeedbackPanel from './FeedbackPanel';

orchestrator(onSendErrorFeedback, ({ errorState }) => {
    const isSdfUser = getLogicalRing() === 'Dogfood';
    const isOCVEnabled = !isSdfUser || isFeatureEnabled('msplaces-dev-OCVFeedback');
    isOCVEnabled ? renderOCVFeedbackPanel() : renderFeedbackPanel(errorState);
});
