import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { logGreyError } from 'owa-analytics';
import { addMinutes, getTimestamp, now, startOfDay } from 'owa-datetime';

import { placesPeopleTable } from '../database';
import { addTimezoneSettingsToCache } from './addTimezoneSettingsToCache';

import type { PeopleInfo } from '../database';
/**
 * @param peopleInfo: the list of peopleInfo to add to the cache
 */
export function addPeopleInfoToCache(peopleInfo: PeopleInfo[]): void {
    try {
        const ownerId = getUserExternalDirectoryId();
        addTimezoneSettingsToCache();
        const input = peopleInfo.map(info => {
            // We are using the email and start of date as the uniqueId to avoid duplicates that
            // changing collaboratorType or location might cause
            const uniqueId = `${info.email}-${getTimestamp(startOfDay(info.date))}`;

            return {
                uniqueId,
                email: info.email,
                location: info.location,
                collaboratorType: info.collaboratorType,
                startOfDay: getTimestamp(startOfDay(info.date)),
                expirationTime: getTimestamp(addMinutes(now(), 10)),
                owner: ownerId,
            };
        });

        // bulkPut here will overwrite any existing entries with the same uniqueId
        // for every object in the input array
        placesPeopleTable.bulkPut(input);
    } catch (e) {
        logGreyError('PlacesPeopleCache_addPeopleInfoToCache', e);
    }
}
