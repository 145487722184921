import { lazyRegisterAddDiagnosticCallback } from 'owa-diagnostic-report';
import loc from 'owa-localize';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { KnownIssuesFeedbackForm, KnownIssuesFeedbackFormHeader } from 'owa-smiley-feedback';
import React from 'react';
import { Panel } from '@fluentui/react/lib/Panel';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import strings from './FeedbackPanel.locstring.json';

interface FeedbackPanelProps {
    message?: string;
}

function FeedbackPanel(props: FeedbackPanelProps) {
    const [isOpen, setIsOpen] = React.useState(true);
    const dismissPanel = React.useCallback(() => setIsOpen(false), []);

    const dashboardLinkCallback = React.useCallback(() => {
        return {
            key: 'DiagnosticLogs',
            value: 'https://dataexplorer.azure.com/dashboards/1ed5406d-8837-4e8c-90b9-e0d184c00991?p-_startTime=2days&p-_endTime=now',

            /** TODO add later to navigate directly to data in dashboard using SessionId and user configuration paramters.
             * &overrides=[{%22query%22:%22//*[id=%27sid%27]%22,%22key%22:%22value%22,%22replacement%22:%22${getSessionId()}%22},
             * {%22query%22:%22//*[id=%27pid%27]%22,%22key%22:%22value%22,%22replacement%22:%22${
             * getUserConfiguration()?.SessionSettings?.UserPuid}%22}]%20`
             * */
        };
    }, []);

    React.useEffect(() => {
        lazyRegisterAddDiagnosticCallback.import().then(registerAddDiagnosticCallback => {
            registerAddDiagnosticCallback(dashboardLinkCallback);
        });
    }, [dashboardLinkCallback]);

    return (
        <Panel
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel={loc(strings.feedbackPanelCloseButtonAriaLabel)}
        >
            <KnownIssuesFeedbackFormHeader />
            <KnownIssuesFeedbackForm onSendFeedback={dismissPanel} message={props.message} />
        </Panel>
    );
}

export default function renderFeedbackPanel(message?: string) {
    const feedbackDiv = document.createElement('div');
    window.document.body.appendChild(feedbackDiv);

    render(
        <ThemeProvider role={'complementary'} tabIndex={-1}>
            <FeedbackPanel message={message} />
        </ThemeProvider>,
        feedbackDiv
    );
}
