import { isGuid } from 'owa-guid';

import { getRecentBuildings, getWorkLocationInfo } from '../store';

/**
 * Returns the default current place to be used when the page is opened
 * If we have a building from fwh, we use that
 * Otherwise, we use the first building from the recent buildings cache
 * @returns the default current place id
 */
export function getDefaultCurrentPlace(): string | undefined {
    const workLocationInfo = getWorkLocationInfo();
    if (workLocationInfo?.place && isGuid(workLocationInfo.place.id)) {
        return workLocationInfo.place.id;
    } else {
        const recentBuildings = getRecentBuildings();
        if (recentBuildings && recentBuildings.length > 0) {
            return recentBuildings[0];
        }
    }
    return undefined;
}
