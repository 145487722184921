import type { MailboxInfo } from 'owa-client-types';
import getEventSetReminderCloudSetting from '../selectors/getEventSetReminderCloudSetting';

const EVENT_SET_REMINDERS_FALLBACK = true;

/**
 * Gets default value for event set reminder option
 */
export default function getDefaultEventSetReminder(mailboxInfo: MailboxInfo): boolean {
    const setReminder = getEventSetReminderCloudSetting(mailboxInfo);

    return setReminder != null ? setReminder : EVENT_SET_REMINDERS_FALLBACK;
}
