import { isSameRawDay, owaDate } from 'owa-datetime';

import { getCurrentDate, setCollaboratorsLocations } from '../store';

import type { CollaboratorScheduleWithLocation } from 'hybridspace-common/lib/serviceTypes';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type {
    CollaboratorGroup,
    HybridspaceGetScheduleFlexibleWorkingHoursView,
} from 'owa-graph-schema';

export function setCollaboratorsLocationsValue(
    collaboratorsData: CollaboratorScheduleWithLocation[] | null | undefined
) {
    const currentDate = getCurrentDate();

    if (!collaboratorsData) {
        return;
    }

    const collaboratorMap = new Map<string, Map<CollaboratorGroup, UserEmailAddress[]>>();

    collaboratorsData.forEach(({ schedule, collaborator }) => {
        if (!schedule || !collaborator) {
            return;
        }

        const fwhList = schedule.flexibleWorkingHours;
        const collaboratorSource = collaborator.source || 'Suggested';
        const collaboratorEmail = collaborator.primarySmtpAddress as UserEmailAddress;

        if (!fwhList || !collaboratorEmail) {
            return;
        }

        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        fwhList.forEach((fwh: HybridspaceGetScheduleFlexibleWorkingHoursView) => {
            const buildingId = fwh.building?.id;
            const collaboratorDate = owaDate(fwh.start.timeZone.name, fwh.start.dateTime);
            if (!buildingId || !isSameRawDay(currentDate, collaboratorDate)) {
                return;
            }

            initializeBuildingId(
                collaboratorMap,
                buildingId,
                collaboratorSource,
                collaboratorEmail
            );
        });
    });

    setCollaboratorsLocations(collaboratorMap);
}

function initializeBuildingId(
    collaboratorMap: Map<string, Map<CollaboratorGroup, UserEmailAddress[]>>,
    buildingId: string,
    collaboratorSource: CollaboratorGroup,
    collaboratorEmail: UserEmailAddress
) {
    if (!collaboratorMap.has(buildingId)) {
        collaboratorMap.set(buildingId, new Map<CollaboratorGroup, UserEmailAddress[]>());
    }

    const innerMap = collaboratorMap.get(buildingId);

    if (innerMap && !innerMap.has(collaboratorSource)) {
        innerMap.set(collaboratorSource, []);
    }

    const emailList = innerMap?.get(collaboratorSource);

    if (emailList && !emailList.includes(collaboratorEmail)) {
        emailList.push(collaboratorEmail);
    }
}
