import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { useLiveQuery } from 'dexie-react-hooks';
import { logGreyError } from 'owa-analytics';
import { getTimestamp, startOfDay } from 'owa-datetime';

import { placesPeopleTable } from '../database';

import type { OwaDate } from 'owa-datetime';

import type { CollaboratorGroup } from 'owa-graph-schema';

/**
 * @param collaboratorType: the people group you want to read from cache
 * @param location: the location of the collaborator, can be buildingId, or WorkLocationType
 * @param date: the date for which we want the people
 * @returns the list of people in the cache that correspond
 *          to the given collaboratorType and building
 */
export function usePeopleFromCache(
    collaboratorType: CollaboratorGroup | 'Meeting',
    location: string,
    date: OwaDate,
    filterPeopleDataFunc?: (email: string) => boolean
): UserEmailAddress[] {
    /** We check the current owner to ensure if multiple accounts are
     * logged in, we only get the data relevant for the current account
     */
    const currentOwner = getUserExternalDirectoryId();

    return useLiveQuery(
        () =>
            placesPeopleTable
                .where({
                    owner: currentOwner,
                    startOfDay: getTimestamp(startOfDay(date)),
                    collaboratorType,
                    location,
                })
                .toArray()
                .then(
                    data => {
                        const emails = data
                            .map(row => row.email)
                            .filter(filterPeopleDataFunc ?? (() => true));

                        return Array.from(new Set(emails));
                    },
                    e => {
                        logGreyError('PlacesPeopleCache_usePeopleFromCache', e);
                        return [];
                    }
                ),
        [] /** deps */,
        [] /** default return value */
    );
}
