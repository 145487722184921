import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';
import type { MailboxInfo } from 'owa-client-types';

export default function getHijriOffsetCloudSetting(mailboxInfo: MailboxInfo): number {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const hijriOffsetSetting = accountSettings.get(
        CalendarCloudSettingType.HijriOffset.toLowerCase()
    );

    return hijriOffsetSetting ? Number(hijriOffsetSetting.value) : 0;
}
