import { queryWithError } from 'hybridspace-graphql/lib/utils/queryWithError';
import { GetListUserFeatureAccessDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getListUserFeatureAccessQuery.interface';

export default async function fetchListUserFeatureAccess() {
    const { data } = await queryWithError(GetListUserFeatureAccessDocument, {
        context: {
            noBatching: true,
        },
        fetchPolicy: 'cache-first',
    });

    return data;
}
