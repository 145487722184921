import getDefaultShortenEventScope from './getDefaultShortenEventScope';
import { ShortenEventScopeType } from '../store/schema/ShortenEventScopeType';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Gets whether the event should start late or not
 */
export default function getDefaultShouldShortenEventDurationStartLate(
    mailboxInfo: MailboxInfo
): boolean {
    return getDefaultShortenEventScope(mailboxInfo) === ShortenEventScopeType.StartLate;
}
