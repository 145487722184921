import type { MailboxInfo } from 'owa-client-types';
import getShouldShowWorkingHoursOnSurfaceCloudSetting from '../selectors/getShouldShowWorkingHoursOnSurfaceCloudSetting';

const DEFAULT_SHOULD_SHOW_WORKING_HOURS_ON_SURFACE = true;

/**
 * Gets the default value for the show working hours on surface option
 */
export default function getDefaultShouldShowWorkingHoursOnSurface(
    mailboxInfo: MailboxInfo
): boolean {
    const shouldShowWorkingHoursOnSurface =
        getShouldShowWorkingHoursOnSurfaceCloudSetting(mailboxInfo);

    return shouldShowWorkingHoursOnSurface != null
        ? shouldShowWorkingHoursOnSurface
        : DEFAULT_SHOULD_SHOW_WORKING_HOURS_ON_SURFACE;
}
