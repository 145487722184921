import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the ShouldEventsEndEarly cloud setting
 */
export default function getShouldEventsEndEarlyCloudSetting(
    mailboxInfo: MailboxInfo
): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const shouldEventsEndEarlySetting = accountSettings.get(
        CalendarCloudSettingType.ShouldEventsEndEarly.toLowerCase()
    );

    return shouldEventsEndEarlySetting != null ? !!shouldEventsEndEarlySetting.value : undefined;
}
