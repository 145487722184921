import type { MailboxInfo } from 'owa-client-types';
import getDefaultShouldShortenEventDurationEndEarly from './getDefaultShouldShortenEventDurationEndEarly';
import getDefaultShouldShortenEventDurationStartLate from './getDefaultShouldShortenEventDurationStartLate';

/**
 * Gets whether the event duration should be shorten or not
 */
export default function getDefaultShouldShortenEventDuration(mailboxInfo: MailboxInfo): boolean {
    return (
        getDefaultShouldShortenEventDurationStartLate(mailboxInfo) ||
        getDefaultShouldShortenEventDurationEndEarly(mailboxInfo)
    );
}
