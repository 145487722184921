import { getAccountScopeUserSettings } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';

const DEFAULT_REMINDER_FALLBACK = 15;
const DEFAULT_ALL_DAY_REMINDER = 60 * 7;

export default function getDefaultReminder(
    mailboxInfo: MailboxInfo,
    isAllDayEvent: boolean | undefined
): number {
    if (isAllDayEvent) {
        // If the event is all day event, we set reminder to the default -> 5 PM previous day
        // Since start time will be set to midnight on saving, we need to go back 7 hours for the reminder time
        return DEFAULT_ALL_DAY_REMINDER;
    } else {
        const optionsReminderTime =
            getAccountScopeUserSettings(mailboxInfo)?.UserOptions?.DefaultReminderTimeInMinutes;
        if (optionsReminderTime === undefined) {
            return DEFAULT_REMINDER_FALLBACK;
        }

        return optionsReminderTime;
    }
}
