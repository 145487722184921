import { format } from 'owa-localize';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { makePostRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Performs a request to the Places API to fetch the place
 */
export function getPlace(id: string, mailboxInfo?: MailboxInfo): Promise<Response> {
    const queryTemplate: string = "Locations/odata/api/v1.0/Users('{0}')/Places/Places.GetPlace()";

    const requestMailboxInfo: MailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();

    // calculateEndPoint
    const endpoint = format(
        queryTemplate,
        getAccountScopeUserSettings(requestMailboxInfo).SessionSettings?.UserEmailAddress
    );

    const request = {
        Id: id,
    };
    // make server call
    return makePostRequest(
        endpoint,
        request,
        undefined /* correlationId */,
        true /* returnFullResponse */,
        undefined /* customHeaders */,
        undefined /* throwServiceError */,
        true /* sendPayloadAsBody */,
        undefined /* includeCredentials */,
        'getPlace' /* actionName */,
        undefined /* datapoint */,
        requestMailboxInfo
    );
}
