import type { MailboxInfo } from 'owa-client-types';
import getEndLongEventsByCloudSetting from '../selectors/getEndLongEventsByCloudSetting';

const DEFAULT_END_LONG_EVENTS_BY_FALLBACK = 10;

/**
 * Gets the default value for the duration to reduce the long events (one hour or more)
 */
export default function getDefaultEndLongEventsBy(mailboxInfo: MailboxInfo): number {
    const endLongEventsBy = getEndLongEventsByCloudSetting(mailboxInfo);

    return endLongEventsBy != null ? endLongEventsBy : DEFAULT_END_LONG_EVENTS_BY_FALLBACK;
}
