import type { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';
import { placesFwkPageLoadAction } from 'places-fwk-actions';

import type { PlacesPageLoaderType } from 'hybridspace-performance-datapoints';

export async function trackLoaderPerf(
    loaderFunction: LoaderFunction,
    loaderName: PlacesPageLoaderType,
    args: LoaderFunctionArgs<any>
) {
    placesFwkPageLoadAction(loaderName, 'start', 'Started');

    try {
        const result = await loaderFunction(args);
        placesFwkPageLoadAction(loaderName, 'end', 'Stopped');
        return result;
    } catch (error) {
        placesFwkPageLoadAction(
            loaderName,
            'end',
            'Stopped',
            JSON.stringify(error) ?? 'Stopped with error.'
        );
        throw error;
    }
}
