import { type UTPageLoadStatus, type UTPageType } from 'owa-unified-telemetry';
import { logPlacesEvent } from 'hybridspace-telemetry';
import { placesFwkPageLoadAction, placesFwkRootComponentMountedAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';

// Orchestrator for Explore page load
// perf and telemetry markers
orchestrator(placesFwkPageLoadAction, ({ page, status, result, errorMessage }) => {
    // If the page is not Explore, we don't care to respond to the action
    if (page != 'Explore') {
        return;
    }

    let actionSuccess: boolean | undefined = undefined;
    let pageStatus: UTPageLoadStatus | undefined = undefined;
    const failureInfo = errorMessage ?? '';

    // UT Logging
    switch (result) {
        case 'Success':
            actionSuccess = true;
            pageStatus = 'Success';
            break;
        case 'Error':
            actionSuccess = false;
            pageStatus = 'Error';
            break;
        case 'NoAccess':
            break;
        case 'NotFound':
            actionSuccess = false;
            pageStatus = 'NotFound';
            break;
        case 'TenantNoBuildings':
            actionSuccess = false;
            pageStatus = 'Empty';
            break;
        case 'UserNoBuildings':
            actionSuccess = true;
            pageStatus = 'Empty';
            break;
    }

    if (actionSuccess != undefined && pageStatus != undefined) {
        logPlacesEvent({
            eventName: 'PlacesPageLoad',
            data: {
                ActionSuccess: actionSuccess,
                PageStatus: pageStatus,
                PageType: 'Explore',
                ActionFailureInfo: failureInfo,
            },
        });
    }

    // If the status is 'end', we call the proper mounted actions for perf
    if (status === 'end') {
        // Empty or success states will render root content
        if (result === 'Success') {
            placesFwkRootComponentMountedAction('PlacesExplorePageRootContent');
        } else if (pageStatus === 'Empty') {
            placesFwkRootComponentMountedAction('PlacesExplorePageEmptyContent');
        } else {
            placesFwkRootComponentMountedAction('PlacesExplorePageRoot', {
                name: 'CouldNotLoadExplore',
                message: 'Could not load explore page',
                stack: errorMessage,
            } as Error);
        }
    }
});
