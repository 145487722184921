import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import type { Place } from 'owa-graph-schema';
import { logGreyError } from 'owa-analytics';
import { buildingsTable } from './database';
import { getTimestamp, now } from 'owa-datetime';

export async function addPlaceToCache(place: Place): Promise<void> {
    try {
        const ownerId = getUserExternalDirectoryId();
        const uniqueId = place.id;
        const input = {
            uniqueId,
            owner: ownerId,
            building: JSON.stringify(place),
            timestamp: getTimestamp(now()),
        };

        // Retrieve all items in the table
        const items = await buildingsTable.toArray();

        // Check if there are more than 10 items
        if (items.length >= 10) {
            // Find the oldest item by sorting by timestamp
            const oldestItem = items
                .sort((a, b) => a.timestamp - b.timestamp)
                .find(item => item.owner === ownerId);

            // If an oldest item exists, delete it
            if (oldestItem) {
                await buildingsTable.delete(oldestItem.uniqueId);
            }
        }

        // Add the new item to the cache
        await buildingsTable.put(input);
    } catch (e) {
        logGreyError('PlacesBuildingsCache_addPlacesToCache', e);
    }
}
