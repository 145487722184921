import DismissRegular from 'owa-fluent-icons-svg/lib/icons/DismissRegular';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';

import { Icon } from '@fluentui/react';
import {
    Button,
    Toast,
    Toaster,
    ToastTitle,
    ToastTrigger,
    useId,
    useToastController,
} from '@fluentui/react-components';

import {
    closeNetworkButtonArialLabel,
    noConnectionMessage,
    noConnectionTitle,
} from './AccNetworkLostToast.locstring.json';
import { toastErrorContainer, toasterStyle, toastTitleStyles } from './AccNetworkLostToast.scss';

import { useOnlineStatusChange } from 'owa-react-hooks/lib/useOnlineStatusChange';

const dismissIcon = <Icon iconName={DismissRegular} />;
const toastOffset = { vertical: 50 };

export default observer(function AccNetworkLostToast() {
    const offlineToastId = useId('offlineToast');
    const toasterId = useId('NetworkToaster');

    const { dispatchToast, dismissToast } = useToastController(toasterId);

    const buttonAriaLabel = loc(closeNetworkButtonArialLabel);
    const closeButton = React.useMemo(
        () => (
            <ToastTrigger>
                <Button
                    icon={dismissIcon}
                    appearance="subtle"
                    size="small"
                    aria-label={buttonAriaLabel}
                    data-telemetry-id="AccCloseNetworkButton"
                />
            </ToastTrigger>
        ),
        [buttonAriaLabel]
    );

    const localizedOfflineToastTitle = loc(noConnectionTitle);
    const localizedOfflineToastMessage = loc(noConnectionMessage);

    const notify = React.useCallback(
        (online: boolean) => {
            if (online) {
                return;
            }
            dispatchToast(
                <Toast className={toastErrorContainer}>
                    <ToastTitle action={closeButton} className={toastTitleStyles}>
                        <p>
                            <strong>{localizedOfflineToastTitle}</strong>{' '}
                            {localizedOfflineToastMessage}
                        </p>
                    </ToastTitle>
                </Toast>,
                { timeout: -1, position: 'top', intent: 'error', toastId: offlineToastId }
            );
        },
        [
            closeButton,
            offlineToastId,
            localizedOfflineToastMessage,
            localizedOfflineToastTitle,
            dispatchToast,
        ]
    );

    const onNetworkChange = React.useCallback(
        (online: boolean) => {
            if (online) {
                dismissToast(offlineToastId);
            }
            notify(online);
        },
        [dismissToast, offlineToastId, notify]
    );

    useOnlineStatusChange(onNetworkChange);

    return <Toaster toasterId={toasterId} offset={toastOffset} className={toasterStyle} />;
}, 'AccNetworkLostToast');
