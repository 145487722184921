import type { Building, Location, WorkLocationType } from 'owa-graph-schema';
import { owaComputedFn } from 'owa-computed-fn';
import type { OwaDate } from 'owa-datetime';
import { OFFICE_OPTION_ID, REMOTE_OPTION_ID } from 'owa-building-selection/lib/constants';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';
import { isFeatureEnabled } from 'owa-feature-flags';

export const getAllSuggestedBuildings = owaComputedFn(
    (
        recurringFWHArray: Array<{
            date: OwaDate;
            workLocation: string | WorkLocationType;
            workLocationDetails?: Location[];
        }>,
        occurringFWHArray: Array<{
            date: OwaDate;
            workLocation: string | WorkLocationType;
            workLocationDetails?: Location[];
        }>
    ): Building[] => {
        return getSuggestedBuildings(
            recurringFWHArray,
            occurringFWHArray,
            0 /* numBuildings, return all */,
            undefined
        );
    }
);

/**
 * This function returns the suggested buildings based on the FWH data.
 * Buildings are returned based on most used. If two buildings have the same weight,
 * we sort by name. Recurring FWH buildings are placed first
 * and then the occurring FWH buildings are placed next.
 * @param recurringFWHArray - FWH data for recurring events
 * @param occurringFWHArray - FWH data for events within a specific date range
 * @param numBuildings - number to indicate how many buildings to return. Zero or negative will return all of the buildings.
 * @param selectedBuildingName - Name of the building that is selected
 */
export const getSuggestedBuildings = owaComputedFn(
    (
        recurringFWHArray: Array<{
            date: OwaDate;
            workLocation: string | WorkLocationType;
            workLocationDetails?: Location[];
        }>,
        occurringFWHArray: Array<{
            date: OwaDate;
            workLocation: string | WorkLocationType;
            workLocationDetails?: Location[];
        }>,
        numBuildings: number,
        selectedBuildingName: string | null | undefined
    ): Building[] => {
        const recurringFWHBuildingIds = new Set<string>();
        const buildingSuggestions: Building[] = [];
        let errorShown = false;

        const recurringFWHBuildings = getBuildingsFromFWH(recurringFWHArray, selectedBuildingName);
        recurringFWHBuildings.forEach(building => {
            if (building.id) {
                recurringFWHBuildingIds.add(building.id);
            }
        });
        buildingSuggestions.push(...sortBuildingsByFrequencyAndName(recurringFWHBuildings));

        const occurringFWHBuildings = getBuildingsFromFWH(
            occurringFWHArray,
            selectedBuildingName
        ).filter(building => {
            // Filter out the recurring FWH buildings
            if (!building.id) {
                return false;
            }
            return !recurringFWHBuildingIds.has(building.id);
        });
        buildingSuggestions.push(...sortBuildingsByFrequencyAndName(occurringFWHBuildings));

        // If we are seeing a building with one of these ids, we should not show them in the list.
        if (isFeatureEnabled('msplaces-block-custom-ids')) {
            buildingSuggestions.filter((building: Building) => {
                if (building.id === OFFICE_OPTION_ID || building.id === REMOTE_OPTION_ID) {
                    if (!errorShown) {
                        debugErrorThatWillShowErrorPopupOnly(
                            'Suggested building id is OFFICE_OPTION_ID or REMOTE_OPTION_ID'
                        );
                        errorShown = true;
                    }
                    return false;
                }
                return true;
            });
        }

        // If numBuildings is zero or negative, return all buildings
        if (numBuildings < 1) {
            return buildingSuggestions;
        }

        // Return the first numBuildings buildings
        return buildingSuggestions.slice(0, numBuildings);
    }
);

function getBuildingsFromFWH(
    fwhArray: Array<{
        date: OwaDate;
        workLocation: string;
        workLocationDetails?: Location[];
    }>,
    selectedBuildingName: string | null | undefined
): Building[] {
    const buildings: Building[] = [];
    fwhArray.forEach(fwh => {
        fwh.workLocationDetails?.forEach((location: Location) => {
            if (
                location.id &&
                location.displayName &&
                location.displayName !== selectedBuildingName &&
                location.locationType === 'Building'
            ) {
                buildings.push({
                    id: location.id,
                    name: location.displayName,
                } as Building);
            }
        });
    });
    return buildings;
}

/**
 * Sorts the buildings by most used and then by name
 * @param buildingsList
 */
function sortBuildingsByFrequencyAndName(buildingsList: Array<Building>): Building[] {
    // Create a map of building id and frequency
    const frequency: {
        [key: string]: number;
    } = {};
    buildingsList.forEach(building => {
        const buildingId = building.id;
        if (buildingId) {
            frequency[buildingId] = frequency[buildingId] + 1 || 1;
        }
    });

    // Convert the map to an array of arrays where the first element is the building id
    // and the second element is the frequency
    const frequencyBuildingArray = Object.entries(frequency);

    // Sort the array by frequency and then by name
    frequencyBuildingArray.sort((a, b) => {
        // If the frequency is the same, sort by name
        if (b[1] - a[1] === 0) {
            const buildingA = buildingsList.find(building => building.id === a[0]);
            const buildingB = buildingsList.find(building => building.id === b[0]);
            if (buildingA?.name && buildingB?.name) {
                return buildingA.name.localeCompare(buildingB.name);
            }
            return 0;
        }
        return b[1] - a[1];
    });

    const sortedBuildings: Building[] = [];

    // Create a new array of buildings sorted by frequency and then by name
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    frequencyBuildingArray.forEach(building => {
        const buildingId = building[0];
        const sortedBuilding = buildingsList.find(
            buildingNotSorted => buildingNotSorted.id === buildingId
        );
        if (sortedBuilding) {
            sortedBuildings.push(sortedBuilding);
        }
    });

    return sortedBuildings;
}
