import { getCurrentPlace } from '../selectors/getCurrentPlace';
import { getWorkLocationInfo } from '../store';

// Check if the currently selected place is the same as the work location
export function getIsWorkLocationCurrentPlace() {
    return (
        getWorkLocationInfo()?.place?.id === getCurrentPlace()?.id &&
        getWorkLocationInfo()?.relativeLocation !== 'Remote'
    );
}
