import getCalendarView from 'hybridspace-common/lib/services/getCalendarView';
import getScheduleWithLocation from 'hybridspace-common/lib/services/getScheduleWithLocation';
import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { getValidAttendeeSmtpAddressForGetSchedule } from 'hybridspace-common/lib/utils/getValidAttendeeSmtpAddressForGetSchedule';
import { isSegmentEncapsulated } from 'owa-calendar-working-hours-utils';
import { isSameRawDay, owaDate } from 'owa-datetime';

import type { OwaDate } from 'owa-datetime';

import type {
    Building,
    CalendarEvent,
    CalendarParticipant,
    ExtendedDateTime,
} from 'owa-graph-schema';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { DateRange } from 'owa-datetime-utils';
const PEOPLE_MEETING_WITH_LIMIT = 20;

/**
 *
 * @param dateRange date range to query
 * @param dateToCompareFWH the date to compare FWH information for if being used for a single day
 * @returns a map of buildingId to list of people in meetings
 * NOTE: Currently we are limiting the number of emails to pass into getScheduleWithLocation to PEOPLE_MEETING_WITH_LIMIT
 */
export default async function getPeopleInMeetingsFromCalendar(
    dateRange: DateRange,
    dateToCompareFWH?: OwaDate
) {
    const meetingInformation = await getCalendarView(dateRange);
    const events = meetingInformation.calendarView.calendarEvents;

    const peopleInMeetings = new Map<Building['id'], UserEmailAddress[]>();

    // filtering out required and optional attendees
    const requiredAttendees2DArray = events?.map(
        (event: CalendarEvent | null) => event?.meetingDetails?.requiredAttendees ?? []
    );
    const organizers = events?.map((event: CalendarEvent | null) => event?.organizer ?? '');
    // Need to add organizers here as they are part of the meeting but included as required attendee with responseType 'None'
    // casting as CalendarParticipant as type is being inferred as Maybe<CalendarParticipant>
    requiredAttendees2DArray.push(organizers as CalendarParticipant[]);

    const optionalAttendees2DArray = events?.map(
        (event: CalendarEvent | null) => event?.meetingDetails?.optionalAttendees ?? []
    );

    // convert 2D array of required and optional attendees to 1D array
    const requiredAttendees = ([] as CalendarParticipant[]).concat(
        ...(requiredAttendees2DArray as CalendarParticipant[][])
    );
    const optionalAttendees = ([] as CalendarParticipant[]).concat(
        ...(optionalAttendees2DArray as CalendarParticipant[][])
    );

    // get a list of all attendees that accepted a meeting from the list of events returned from user's calendar
    // casting as CalendarParticipant as type is being inferred as Maybe<CalendarParticipant>
    const { attendeeSmtpAddressWithLimit } = getValidAttendeeSmtpAddressForGetSchedule(
        requiredAttendees as CalendarParticipant[],
        optionalAttendees as CalendarParticipant[],
        true /* filterOutCurrentUser */,
        PEOPLE_MEETING_WITH_LIMIT
    );

    collaboratorsFetchedAction(attendeeSmtpAddressWithLimit as UserEmailAddress[], 'meeting');

    const scheduleWithLocation = await getScheduleWithLocation(
        attendeeSmtpAddressWithLimit,
        dateRange
    );

    const outofofficeSegments: Array<{
        start: ExtendedDateTime;
        end: ExtendedDateTime;
    }> = [];

    scheduleWithLocation?.forEach(schedule => {
        const currentEmail = schedule.scheduleId as UserEmailAddress;
        schedule.flexibleWorkingHours?.forEach(fwhSegment => {
            const currentWorkLocation = fwhSegment.workLocationType;
            const segmentTime = { start: fwhSegment.start, end: fwhSegment.end };
            const date = owaDate(fwhSegment.start.timeZone.name, fwhSegment.start.dateTime);
            const isSameDay = dateToCompareFWH ? isSameRawDay(dateToCompareFWH, date) : true;

            const workLocationIsOOF = currentWorkLocation === 'TimeOff';
            if (workLocationIsOOF) {
                outofofficeSegments.push(segmentTime);
            }

            // If the segment is encapsulated by an OOF segment, we can skip this segment
            const segmentIsOOF = isSegmentEncapsulated(segmentTime, outofofficeSegments);
            if (isSameDay && !workLocationIsOOF && !segmentIsOOF) {
                const buildingId = fwhSegment.building?.id;
                if (buildingId) {
                    const peopleInCurrentBuilding = peopleInMeetings.get(buildingId) ?? [];
                    const isCurrentPersonInList = peopleInCurrentBuilding.includes(currentEmail);
                    /**
                     * Need to check that the currentEmail is not already in the list as we are looping
                     * through all of the current user's flexible working hour segments. If all segments
                     * are in the same building, no need to add them to the list multiple times.
                     */
                    if (!isCurrentPersonInList) {
                        peopleInCurrentBuilding.push(currentEmail);
                        peopleInMeetings.set(buildingId, peopleInCurrentBuilding);
                    }
                }
            }
        });
    });

    return peopleInMeetings;
}
