import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the EndShortEventsBy cloud setting
 */
export default function getEndShortEventsByCloudSetting(
    mailboxInfo: MailboxInfo
): number | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const endShortEventsBySetting = accountSettings.get(
        CalendarCloudSettingType.EndShortEventsBy.toLowerCase()
    );

    return endShortEventsBySetting != null ? +endShortEventsBySetting.value : undefined;
}
