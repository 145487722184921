import getConfiguredEventEndTime from './getConfiguredEventEndTime';
import getConfiguredEventStartTime from './getConfiguredEventStartTime';
import type { OwaDate } from 'owa-datetime';
import type { OwaDateRange } from 'owa-datetime-utils/lib/OwaDateRange';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Update the event start time and end time when the 'End events early'/'Shorten events duration' setting is on
 * @param originalStart
 * @param originalEnd
 */
export default function getConfiguredEventStartTimeEndTime(
    originalStart: OwaDate,
    originalEnd: OwaDate,
    mailboxInfo: MailboxInfo
): OwaDateRange {
    return {
        start: getConfiguredEventStartTime(originalStart, originalEnd, mailboxInfo),
        end: getConfiguredEventEndTime(originalStart, originalEnd, mailboxInfo),
    };
}
