import { queryWithError } from 'hybridspace-graphql/lib/utils/queryWithError';
import { addDays, subDays } from 'owa-datetime';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

import { GetPlacesScheduleDocument } from '../graphql/__generated__/GetPlacesSchedule.interface';
import { getGqlDateTime } from '../utils/getGqlDateTime';
import { getDevErrorPageState } from 'places-diagnostics';
import { isFeatureEnabled } from 'owa-feature-flags';

import type { DateRange } from 'owa-datetime-utils';
import type { GetPlacesScheduleQueryVariables } from '../graphql/__generated__/GetPlacesSchedule.interface';
export default async function getScheduleWithLocation(
    smtpAddresses: string[],
    dateRange: DateRange
) {
    if (smtpAddresses.length === 0) {
        return [];
    }

    let scheduleWithLocation;
    try {
        // Manually throw an error to test error handling
        if (getDevErrorPageState() === 'Schedule') {
            throw new Error('could not get schedule');
        }
        const mailboxInfo = getModuleContextMailboxInfo();
        const scheduleVariables: GetPlacesScheduleQueryVariables | undefined = {
            input: {
                userId: mailboxInfo?.userIdentity ?? '',
                // The default interval in minutes.  Should not be less than 5 or greater than 1440.
                availabilityViewInterval: 30,
                startTime: getGqlDateTime(subDays(dateRange.start, 1)),
                endTime: getGqlDateTime(addDays(dateRange.end, 1)),
                schedules: [...smtpAddresses].sort(),
                mailboxInfo,
                viewType: isFeatureEnabled('cal-location-sharing-dropdown')
                    ? 'WorkPlansOnly'
                    : undefined,
            },
            // Explicit cast to ignore missing mailboxInfo due to schema mismatch
        } as GetPlacesScheduleQueryVariables;

        const { data } = await queryWithError(GetPlacesScheduleDocument, {
            variables: scheduleVariables,
        });

        if (data?.getScheduleWithLocation) {
            scheduleWithLocation = data.getScheduleWithLocation.schedules;
        } else {
            throw new Error('PlacesGetScheduleWithLocation throw error');
        }
    } catch (error) {
        throw new Error('PlacesGetScheduleWithLocation throw error');
    }
    return scheduleWithLocation;
}
