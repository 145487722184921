import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { createObservableOperation } from 'owa-observable-operation';

import fetchCalendarView from './fetchCalendarView';

import type { DateRange } from 'owa-datetime-utils';

const { onSucceeded: loadCalendarViewSucceeded, start: loadCalendarView } =
    createObservableOperation(
        'PLACES_LOAD_CALENDAR_VIEW',
        (dateRange: DateRange, origin, maxResults?: number, filterAllDayEvents?: boolean) => ({
            dateRange,
            origin,
            maxResults,
            filterAllDayEvents,
        }),
        ({ dateRange }) => getDateRangeISOString(dateRange),
        ({ dateRange, maxResults, filterAllDayEvents }) =>
            fetchCalendarView(
                dateRange.start,
                dateRange.end,
                origin,
                maxResults,
                filterAllDayEvents
            )
    );

export { loadCalendarViewSucceeded, loadCalendarView };
