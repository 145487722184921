import { createDateRange } from 'owa-datetime-utils';
import { orchestrator } from 'satcheljs';

import { loadWorkLocationInfoSucceeded } from 'places-explore-operations';
import { getCurrentDate, setWorkLocationInfo } from '../store';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';

orchestrator(loadWorkLocationInfoSucceeded, ({ result, dateRange }) => {
    const key = getDateRangeISOString(dateRange);
    const currentDateRangeKey = getDateRangeISOString(createDateRange(getCurrentDate(), 1));
    // We make sure the result is for the current date range
    if (key === currentDateRangeKey) {
        setWorkLocationInfo(result);
    }
});
