import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';
import type { MailboxInfo } from 'owa-client-types';

export default function getRokuyouDaysCloudSetting(mailboxInfo: MailboxInfo): number {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const rokuyouDaysSetting = accountSettings.get(
        CalendarCloudSettingType.RokuyouDays.toLowerCase()
    );

    return rokuyouDaysSetting ? Number(rokuyouDaysSetting.value) : 0;
}
