import getShortenEventsDurationBy from './getShortenEventsDurationBy';
import { getDefaultShouldShortenEventDurationEndEarly } from 'owa-calendar-cloud-settings';
import type { MailboxInfo } from 'owa-client-types';
import type { OwaDate } from 'owa-datetime';
import { differenceInMinutes, isBefore, subMinutes } from 'owa-datetime';

/**
 * Update the event end time when the 'End events early'/'Shorten events duration' setting is on
 * @param originalStart
 * @param originalEnd
 */
export default function getConfiguredEventEndTime(
    originalStart: OwaDate,
    originalEnd: OwaDate,
    mailboxInfo: MailboxInfo
): OwaDate {
    if (!getDefaultShouldShortenEventDurationEndEarly(mailboxInfo)) {
        return originalEnd;
    }

    const eventDuration = differenceInMinutes(originalEnd, originalStart);
    const shortenEventsDurationBy = getShortenEventsDurationBy(eventDuration, mailboxInfo);
    const updatedEndTime = shortenEventsDurationBy
        ? subMinutes(originalEnd, shortenEventsDurationBy)
        : originalEnd;

    if (isBefore(originalStart, updatedEndTime)) {
        return updatedEndTime;
    }

    return originalEnd;
}
