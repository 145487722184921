export const themeImage0AriaText = "OcjNzb";
export const themeImage1AriaText = "X2BzK";
export const themeImage2AriaText = "AAZLEe";
export const themeImage3AriaText = "JogyPd";
export const themeImage4AriaText = "Sczk0c";
export const themeImageEmoticonsAriaText = "PmKctc";
export const themeImageNotesAriaText = "$yjFZc";
export const themeImageWorkAriaText = "dX8Rte";
export const themeImageScreensAriaText = "D1uCe";
export const themeImageCubesAriaText = "eDQ$pe";
export const themeImageMountainAriaText = "tZNFZc";
export const themeImageForestAriaText = "B$quD";
export const themeImageCoffeeAriaText = "U5VVpc";
export const themeImageBreakfastAriaText = "pyeZD";
export const themeImageBalloonsAriaText = "YNSj7b";
export const themeImageDesktopAriaText = "i0jHBc";
export const themeImageSphereAriaText = "pzDtP";
export const themeImageWindowAriaText = "gR0jb";
export const themeImagePinkAriaText = "oySRXb";
export const themeImageYellowAriaText = "ETxO0d";
export default {themeImage0AriaText,themeImage1AriaText,themeImage2AriaText,themeImage3AriaText,themeImage4AriaText,themeImageEmoticonsAriaText,themeImageNotesAriaText,themeImageWorkAriaText,themeImageScreensAriaText,themeImageCubesAriaText,themeImageMountainAriaText,themeImageForestAriaText,themeImageCoffeeAriaText,themeImageBreakfastAriaText,themeImageBalloonsAriaText,themeImageDesktopAriaText,themeImageSphereAriaText,themeImageWindowAriaText,themeImagePinkAriaText,themeImageYellowAriaText};