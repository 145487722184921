import getUserTimeZone from 'owa-session-store/lib/selectors/getUserTimeZone';
import getUserMailboxTimeZoneOffset from 'owa-session-store/lib/selectors/getUserMailBoxTimeZoneOffset';
import { type UTInvalidTimeZoneReason } from 'owa-unified-telemetry';

// Function to check if user(OWA/mailbox) timezone is set
// If user timezone is undefined/null/UTC OR mailbox timezone offset is undefined/null, return false
// Otherwise return true
export function isUserTimeZoneSet(
    logFunction?: (reason: UTInvalidTimeZoneReason) => void
): boolean {
    const userTimeZone = getUserTimeZone();
    if (!userTimeZone || userTimeZone === 'UTC') {
        logFunction?.(
            userTimeZone == undefined ? 'Empty' : userTimeZone == null ? 'None' : 'Invalid'
        );
        return false;
    }
    const mailboxTimeZoneOffset = getUserMailboxTimeZoneOffset();
    if (!mailboxTimeZoneOffset || mailboxTimeZoneOffset.length === 0) {
        logFunction?.('Empty');
        return false;
    }
    return true;
}
