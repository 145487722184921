import { getUserCulture } from 'owa-session-store';
import getSingularPluralExpressions from 'owa-measurementunit-formatter/lib/getSingularPluralExpressions';
import loc from 'owa-localize';
import type { ResourceId } from 'owa-localize';

/**
 * Gets a pluralized string, given a zero, singular, first plural and second plural forms of a string.
 * @param value The value being formatted.
 * @param zeroResourceId The resource ID of a string to be used when the value is zero.
 * @param singularResourceId The resource ID of a string to be used when the value is one (or singular, in some languages, like 21 in Russian).
 * @param firstPluralResourceId The resource ID of a string to be used when the value is plural, representing "few".
 * @param secondGenitivePlural The resource ID of a string to be used when the value is plural, representing "many".
 * @returns The formatted string
 */
export default function getPluralizedString(
    value: number,
    zeroResourceId: ResourceId,
    singularResourceId: ResourceId,
    firstPluralResourceId: ResourceId,
    secondGenitivePlural: ResourceId,
    ...locArgs: any[]
) {
    const culture = getUserCulture();
    const { singular, firstPlural } = getSingularPluralExpressions(culture);
    var s = value.toString(10);

    const resourceId = !value
        ? zeroResourceId
        : singular.test(s)
        ? singularResourceId
        : firstPlural.test(s)
        ? firstPluralResourceId
        : secondGenitivePlural;

    return loc(resourceId, s, ...locArgs);
}
