import React from 'react';
import { useIconState } from '@fluentui/react-icons';

// See https://github.com/microsoft/fluentui-system-icons/blob/main/packages/react-icons/src/utils/createFluentIcon.ts

type FluentIconsProps<
    TBaseAttributes extends
        | React.SVGAttributes<SVGElement>
        | React.HTMLAttributes<HTMLElement> = React.SVGAttributes<SVGElement>,
    TRefType extends HTMLElement | SVGSVGElement = SVGSVGElement
> = TBaseAttributes &
    React.RefAttributes<TRefType> & {
        primaryFill?: string;
        className?: string;
        filled?: boolean;
        title?: string;
    };

type FluentIcon = {
    (props: FluentIconsProps): JSX.Element;
    displayName?: string;
};

export default function createFluentIcon(
    displayName: string,
    width: string,
    paths: string[]
): FluentIcon {
    const viewBoxWidth = width === '1em' ? '20' : width;
    const Icon = React.forwardRef((props: FluentIconsProps, ref: any) => {
        const state = {
            ...useIconState(props),
            ref,
            width,
            height: width,
            viewBox: `0 0 ${viewBoxWidth} ${viewBoxWidth}`,
            xmlns: 'http://www.w3.org/2000/svg',
        };
        return React.createElement(
            'svg',
            state,
            ...paths.map(d =>
                React.createElement('path', {
                    d,
                    fill: state.fill,
                })
            )
        );
    }) as FluentIcon;
    Icon.displayName = displayName;
    return Icon;
}
