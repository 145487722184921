import { setItem } from 'owa-local-storage';
import { getRecentBuildings, setRecentBuildings } from '../store';
import { getBuildingCacheKey } from './getBuildingCacheKey';

export function setCurrentBuilding(buildingId: string) {
    // update current place id
    const newRecentBuildingArray = [...(getRecentBuildings() ?? [])];
    // add the new value to the array
    if (newRecentBuildingArray) {
        // Check if the buildingId already exists in the array
        const existingIndex = newRecentBuildingArray.indexOf(buildingId);
        // If the buildingId already exists in the array, remove it
        if (existingIndex !== -1) {
            newRecentBuildingArray.splice(existingIndex, 1);
        }
        // If the array already has two elements, remove the second one
        if (newRecentBuildingArray.length >= 2) {
            newRecentBuildingArray.splice(1, 1);
        }
        // Add the buildingId at the front of the array
        newRecentBuildingArray.unshift(buildingId);
    }

    // convert the array to a string and store it in local storage
    const serializedArray = JSON.stringify(newRecentBuildingArray);
    setItem(window, getBuildingCacheKey(), serializedArray);
    // update the store
    setRecentBuildings(newRecentBuildingArray);
}
