import type { Room } from 'owa-room-selection-types';
import { convertEwsIdToRestId } from 'owa-identifiers';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { makePatchRequest, makePostRequest } from 'owa-ows-gateway';
import addRoomToAttendeeArray from './addRoomToAttendeeArray';
import addRoomToLocationsArray from './addRoomToLocationsArray';

import type CalendarEvent from 'owa-calendar-types/lib/types/CalendarEvent';
import type EnhancedLocation from 'owa-service/lib/contract/EnhancedLocation';
import type AttendeeType from 'owa-service/lib/contract/AttendeeType';

export default async function addRoomToEvent(
    eventId: string,
    calendarEvent: CalendarEvent,
    room: Room
): Promise<boolean> {
    const modifiedEventId = convertEwsIdToRestId(eventId);

    // If the user is an organizer, we can just update the event
    // If the user is not an organizer, we need to forward the event
    if (calendarEvent?.IsOrganizer) {
        const modifiedLocations = addRoomToLocationsArray(
            calendarEvent?.Locations as EnhancedLocation[],
            room
        );
        const modifiedAttendees = addRoomToAttendeeArray(
            calendarEvent?.RequiredAttendees as AttendeeType[],
            calendarEvent?.OptionalAttendees as AttendeeType[],
            calendarEvent?.Resources as AttendeeType[],
            room
        );

        const endpoint = `/api/v2.0/me/calendar/events('${modifiedEventId}')`;
        const body = {
            Locations: modifiedLocations,
            Attendees: modifiedAttendees,
        };
        const response = await makePatchRequest(
            endpoint,
            body, // requestObject
            undefined, // correlationId
            true, // returnFullResponse
            {
                Prefer: 'IdType="ImmutableId"',
            }, // customHeaders
            false, // throwServiceError
            undefined, // includeCredentials
            'updatePlacesEvent', // actionName
            getModuleContextMailboxInfo()
        );

        return isSuccessStatusCode(response.status);
    } else {
        const endpoint = `/api/v2.0/me/calendar/events('${modifiedEventId}')/forward`;
        const body = {
            ToRecipients: [
                {
                    EmailAddress: {
                        Address: room.email,
                        Name: room.name,
                    },
                },
            ],
        };
        await makePostRequest(
            endpoint,
            body, // requestObject
            undefined, // correlationId
            false, //returnRawResponse
            {
                Prefer: 'IdType="ImmutableId"',
            }, // customHeaders
            undefined, // throwServiceError
            undefined, // sendPayloadAsBody
            undefined, // includeCredentials
            'forwardPlacesEvent', // actionname
            undefined, // datapoint
            getModuleContextMailboxInfo()
        );
        return true;
    }
}
