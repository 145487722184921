import { lazyHandleSupportPaneEvents } from 'diagnostics-and-support';
import { getSessionId } from 'owa-config';
import { getIsDarkTheme } from 'owa-fabric-theme';
import loc, { getCurrentLanguage } from 'owa-localize';
import { stringify } from 'owa-querystring';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { render } from 'owa-react-dom';
import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import React from 'react';
import { Panel } from '@fluentui/react/lib/Panel';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { container, content, commands, scrollableContent } from './HelpPane.scss';
import { helpButtonMenuLabel } from './PlacesMoreDropdown.locstring.json';

interface HelpPanelProps {
    iframeUrl: string;
}

const panelStyles = {
    content,
    scrollableContent,
    commands,
};

const supportUrl: string = 'https://support.office.com/client/results?';

function HelpPanel(props: HelpPanelProps) {
    const [isOpen, setIsOpen] = React.useState(true);
    const dismissPanel = React.useCallback(() => setIsOpen(false), []);
    useWindowEvent(
        'message',
        (messageEvent: any) => {
            lazyHandleSupportPaneEvents.importAndExecute(messageEvent);
        },
        'HelpPanel'
    );
    return (
        <Panel
            headerText={loc(helpButtonMenuLabel)}
            isOpen={isOpen}
            isBlocking={false}
            styles={panelStyles}
            onDismiss={dismissPanel}
        >
            <iframe src={props.iframeUrl} height={'100%'} width={'100%'} className={container} />
        </Panel>
    );
}
export default function renderHelpPanel() {
    const helpPaneDiv = document.createElement('div');
    window.document.body.appendChild(helpPaneDiv);
    const url = getPlacesHelpIframeUrl();
    render(
        <ThemeProvider role={'complementary'} tabIndex={-1}>
            <HelpPanel iframeUrl={url} />
        </ThemeProvider>,
        helpPaneDiv
    );
}

function getPlacesHelpIframeUrl(): string {
    const locale: string = getCurrentLanguage();
    const sessionId = getSessionId();

    const contextParams = {
        ThemeId: getIsDarkTheme() ? '4' : '0',
        SessionId: sessionId,
        IsWebShell: 'true',
    };

    const queryParams = {
        NS: 'hybridspace',
        Context: JSON.stringify(contextParams),
        Locale: locale,
        showNav: 'true',
        moveSupportCard: '0',
    };
    return supportUrl + stringify(queryParams);
}
