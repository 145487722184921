import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DiagnosticReport"*/ './lazyIndex'),
    { name: 'DiagnosticReport' }
);

export const lazyAppendDiagnosticReportToMessage = new LazyAction(
    lazyModule,
    m => m.appendDiagnosticReportToMessage
);
export const lazyGenerateDiagnosticReport = new LazyAction(
    lazyModule,
    m => m.generateDiagnosticReport
);
export const lazyRegisterAddDiagnosticCallback = new LazyImport(
    lazyModule,
    m => m.registerAddDiagnosticCallback
);
