import { today } from 'owa-datetime';
import { createAccessors } from 'owa-viewstate-store';
import { createStore, mutatorAction } from 'satcheljs';

import type { OwaDate } from 'owa-datetime';

const store = createStore<{
    agendaOrPlacesFinder: 'agenda' | 'placesFinder';
    isInMapTab: boolean;
    currentDate: OwaDate;
    placesType: 'Room' | 'DeskPool';
    selectedCollaborators: string[];
    hasFloorPlan: boolean;
    selectedOrDefaultBuildingId: string;
}>('hybridspace-places-finder-store', {
    agendaOrPlacesFinder: 'agenda',
    isInMapTab: false,
    currentDate: today(),
    placesType: 'Room',
    selectedCollaborators: [],
    hasFloorPlan: false,
    selectedOrDefaultBuildingId: 'none',
});

export const {
    getAgendaOrPlacesFinder,
    setAgendaOrPlacesFinder,
    getIsInMapTab,
    setIsInMapTab,
    getCurrentDate,
    setCurrentDate,
    getPlacesType,
    setPlacesType,
    setSelectedCollaborators,
    getSelectedCollaborators,
    setHasFloorPlan,
    getHasFloorPlan,
    getSelectedOrDefaultBuildingId,
    setSelectedOrDefaultBuildingId,
} = createAccessors('hybridspace-places-finder-store', store);

export const initializePlacesFinderStore = mutatorAction('initializePlacesFinderStore', () => {
    const createdStore = store();
    createdStore.currentDate = today();
});
