import { action } from 'satcheljs';
import type { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Announces that calendar cloud settings has been updated and includes
 * which settings were updated
 */
export const calendarAccountCloudSettingUpdated = action(
    'calendarAccountCloudSettingSaved',
    (mailboxInfo: MailboxInfo, calendarCloudSettingTypes: CalendarCloudSettingType[]) => ({
        mailboxInfo,
        calendarCloudSettingTypes,
    })
);
