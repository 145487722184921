import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import type { ShortenEventScopeType } from '../store/schema/ShortenEventScopeType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the ShortenEventScope cloud setting
 */
export default function getShortenEventScopeCloudSetting(
    mailboxInfo: MailboxInfo
): ShortenEventScopeType | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const shortenEventScopeSetting = accountSettings.get(
        CalendarCloudSettingType.ShortenEventScope.toLowerCase()
    );

    return shortenEventScopeSetting != null
        ? (shortenEventScopeSetting.value as ShortenEventScopeType)
        : undefined;
}
