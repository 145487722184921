import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import type { CollaboratorGroup } from 'owa-graph-schema';
import getCollaboratorTable from './getCollaboratorDb';
import { logGreyError } from 'owa-analytics';

/**
 * @param email: collaborator email
 * @param collaboratorType: collaborator type
 */
export async function setCollaboratorsInCache(
    emails: string[],
    collaboratorType: CollaboratorGroup
): Promise<void> {
    try {
        const ownerId = getUserExternalDirectoryId();
        const input = emails.map((email, index) => {
            return {
                uniqueId: email, // Temporarily use email for uniqueId. This doesn't make sense since multiple owners would want to share the same collaborator
                owner: ownerId,
                orderIndex: index, // Adding an order index for sorting later
                email,
            };
        });

        const collaboratorTable = getCollaboratorTable(collaboratorType);

        // Clear the existing cache
        await collaboratorTable.clear();

        // Add the new list of collaborators to the cache
        await collaboratorTable.bulkPut(input);
    } catch (e) {
        logGreyError('PlacesPeopleCache_setCollaboratorsInCache', e);
    }
}
