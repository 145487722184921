export enum GeneralFilters {
    All = 'All',
    Appointments = 'Appointments',
}

export enum MeetingsFilters {
    AllMeetings = 'AllMeetings',
    Organizer = 'Organizer',
    Attendee = 'Attendee',
    Canceled = 'Canceled',
    Drafts = 'Drafts',
}

export enum RecurrenceFilters {
    Single = 'Single',
    Series = 'Series',
}

export enum PrivacyFilters {
    Private = 'Private',
    NotPrivate = 'NotPrivate',
}

export enum InPersonFilters {
    Required = 'Required',
    NotRequired = 'NotRequired',
}

export enum CalendarEventsFilterType {
    General = 'General',
    Meetings = 'Meetings',
    Categories = 'Category',
    ShowAs = 'ShowAs',
    Recurrence = 'Recurrence',
    Privacy = 'Privacy',
    DeclinedEvents = 'DeclinedEvents',
    InPerson = 'InPerson',
}

export enum DeclinedEventsFilters {
    DeclinedEvents = 'DeclinedEvents',
}

export const CalendarEventsFilters = {
    ...GeneralFilters,
    ...MeetingsFilters,
    ...DeclinedEventsFilters,
};

export type CalendarEventsFilters =
    | GeneralFilters
    | MeetingsFilters
    | RecurrenceFilters
    | PrivacyFilters
    | DeclinedEventsFilters;

export const CalendarFiltersTypes = { ...GeneralFilters, ...CalendarEventsFilterType };
export type CalendarFiltersTypes = GeneralFilters | CalendarEventsFilterType;

/* V2 Section */
export enum MeetingsFiltersV2 {
    Organizer = 'Organizer',
    Drafts = 'Drafts',
    Accepted = 'Accepted',
    DeclinedEvents = 'DeclinedEvents',
    Followed = 'Followed',
    Maybe = 'Maybe',
    Canceled = 'Canceled',
    NotResponded = 'NotResponded',
}

export enum OrganizerMeetingFilters {
    Organizer = 'Organizer',
    Drafts = 'Drafts',
}

export enum AttendeeMeetingFilters {
    Accepted = 'Accepted',
    DeclinedEvents = 'DeclinedEvents',
    Followed = 'Followed',
    Maybe = 'Maybe',
    Canceled = 'Canceled',
    NotResponded = 'Not Responded',
}

export enum GeneralFiltersV2 {
    Appointments = 'Appointments',
}

export const CalendarEventsFiltersV2 = {
    ...GeneralFiltersV2,
    ...MeetingsFiltersV2,
};
export type CalendarEventsFiltersV2 =
    | GeneralFiltersV2
    | MeetingsFiltersV2
    | RecurrenceFilters
    | InPersonFilters;
