import { logUsage } from 'owa-analytics';
import type { CustomData } from 'owa-analytics-types';

/**
 * @function
 * To log Info, errors, exceptions and performance metrics through log telemetry request
 * from external renderer dependencies like me-renderer, office-start
 */
export const logTelemetry = (eventName: string, miscData: CustomData): void => {
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(eventName, miscData);
};
