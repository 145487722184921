import { placesFinderSingletonViewStateId } from 'hybridspace-common/lib/constants';
import { removePlacesFinderViewState } from 'hybridspace-places-finder';
import { lazyInitMeetingLocations } from 'owa-calendar-scheduling-service';
import { addDays } from 'owa-datetime';
import { orchestrator } from 'satcheljs';
import {
    backToAgendaView,
    onChangeCurrentDate,
    onCloseMapTab,
    onOpenAgendaPanel,
    onOpenMapTab,
    switchToPlacesFinder,
} from './actions';
import { setAgendaOrPlacesFinder, setCurrentDate, setIsInMapTab, setPlacesType } from './store';

import type { OwaDateRange } from 'owa-datetime-utils/lib/OwaDateRange';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(switchToPlacesFinder, ({ placesType }) => {
    setPlacesType(placesType);
    setAgendaOrPlacesFinder('placesFinder');
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(backToAgendaView, () => {
    removePlacesFinderViewState.importAndExecute(placesFinderSingletonViewStateId);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(onOpenMapTab, () => {
    setIsInMapTab(true);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(onCloseMapTab, () => {
    setIsInMapTab(false);
    removePlacesFinderViewState.importAndExecute(placesFinderSingletonViewStateId);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(onOpenAgendaPanel, () => {
    setAgendaOrPlacesFinder('agenda');
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(onChangeCurrentDate, ({ newDate }) => {
    setCurrentDate(newDate);

    // initialize meeting locations for new date in cache
    const initTimeSlot: OwaDateRange = {
        start: newDate,
        end: addDays(newDate, 1),
    };
    lazyInitMeetingLocations.importAndExecute(initTimeSlot);
});
