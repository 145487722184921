import Dexie from 'dexie';

import type { Building, CollaboratorGroup, WorkLocationType } from 'owa-graph-schema';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { OwaDate } from 'owa-datetime';

const INDEXED_DB_NAME = 'placesCache';
const TABLE_NAME = 'placesPeople';

export interface PeopleCacheInfo {
    uniqueId: string;
    owner: string;
    startOfDay: number;
    location: Building['id'] | WorkLocationType;
    email: UserEmailAddress;
    collaboratorType: CollaboratorGroup | 'Meeting';
    expirationTime: number;
}

export interface PeopleInfo {
    date: OwaDate;
    location: Building['id'] | WorkLocationType | 'Error';
    email: UserEmailAddress;
    collaboratorType: CollaboratorGroup | 'Meeting';
}

// Collaborator Cache
export interface CollaboratorCache {
    uniqueId: UserEmailAddress;
    owner: string;
    orderIndex: number;
}

export interface WorkGroupCache {
    workGroupId: string;
    name: string;
    iconPath?: string;
    members?: UserEmailAddress[];
}

export interface InternalWorkGroupCache extends WorkGroupCache {
    owner: string;
}
export interface PeopleTimezoneSettingsCache {
    uniqueId: string; // owner
    timezone: string;
}

// TODO: Add expiration date logic
// WI: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/268634
// TODO: Add in fixed types to other caches
export const db = new Dexie(INDEXED_DB_NAME);
/** uniqueId is the primary key
 * we want the uniqueId to be a combination of email, location, and collaboratorType
 * so we can easily query/put to cache */

db.version(5).stores({
    placesPeople: 'uniqueId,location,collaboratorType,startOfDay,owner',
    placesSavedCollaborators: 'uniqueId,owner',
    placesSuggestedCollaborators: 'uniqueId,owner',
    placesWorkGroups: 'workGroupId,owner,workGroupId',
    placesPeopleTimezoneSettings: 'uniqueId,timezone',
    placesUpsPeopleTable: null, // the one that added null value is 5, on the next version, remove this table.
});

export const placesPeopleTable = db.table(TABLE_NAME);

// Saved Collaborator Table
export const savedCollaboratorTable = db.table('placesSavedCollaborators');

// Suggested Collaborator Table
export const suggestedCollaboratorTable = db.table('placesSuggestedCollaborators');

// Work Groups Table
export const workGroupsTable = db.table<InternalWorkGroupCache, string>('placesWorkGroups');

// Cached timezone the people info is stored in
export const peopleTimezoneSettingsTable = db.table('placesPeopleTimezoneSettings');

export async function deletePlacesCacheDatabase() {
    if (await Dexie.exists(INDEXED_DB_NAME)) {
        await Dexie.delete(INDEXED_DB_NAME);
    }
}
