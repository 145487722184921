import { RoleContextProvider } from 'hybridspace-common/lib/context';
import React from 'react';
import usePlacesRouteLayout from '../usePlacesRouteLayout';
import HybridspaceNavHeader from './HybridspaceNavHeader';

export function PlacesAppHeaderWithRole() {
    // Request from MetaOs will have query strings: &ep=metaOS and &hostApp=xxxx (Teams, Monarch, etc)
    const { showCompactAppHeader } = usePlacesRouteLayout();
    return (
        <RoleContextProvider>
            <HybridspaceNavHeader showCompactAppHeader={showCompactAppHeader} />
        </RoleContextProvider>
    );
}
