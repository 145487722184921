import { today } from 'owa-datetime';
import { createWeekDateRange } from 'owa-datetime-utils';
import React from 'react';
import { getUserWeekStartDay } from '../utils/getUserWeekStartDay';

import type { DateRange } from 'owa-datetime-utils';
export const getDefaultWeekRange = () => {
    return createWeekDateRange(getUserWeekStartDay(), today());
};

// Context used to track the current week date range.
export const WeekRangeContext = React.createContext<{
    dateRange: DateRange;
}>({
    dateRange: getDefaultWeekRange(),
});

export const useWeekRangeContext = () => React.useContext(WeekRangeContext);
