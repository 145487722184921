import { differenceInSeconds, owaDate, userDate } from 'owa-datetime';
import { SECONDS_IN_DAY } from '../constants';

import type { CalendarEvent } from 'owa-graph-schema';

export default function isMultiDayEvent(calendarEvent: CalendarEvent): boolean {
    const startTime = userDate(
        owaDate(calendarEvent.start?.timeZone.name, calendarEvent.start?.dateTime)
    );
    const endTime = userDate(
        owaDate(calendarEvent.end?.timeZone.name, calendarEvent.end?.dateTime)
    );
    return differenceInSeconds(endTime, startTime) >= SECONDS_IN_DAY;
}
