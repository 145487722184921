import { action } from 'satcheljs';

import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { CollaboratorSource } from '../serviceTypes/CollaboratorSource';

/**
 * Action called when we have completed fetching the collaborators
 */
export const collaboratorsFetchedAction = action(
    'collaboratorsFetchedAction',
    (collaborators: UserEmailAddress[], category: CollaboratorSource) => ({
        collaborators,
        category,
    })
);
