import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { useLiveQuery } from 'dexie-react-hooks';
import { logGreyError } from 'owa-analytics';
import { endOfDay, getTimestamp, startOfDay } from 'owa-datetime';

import { placesPeopleTable, savedCollaboratorTable, suggestedCollaboratorTable } from '../database';

import type { DateRange } from 'owa-datetime-utils';

import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

/**
 *
 * @param collaboratorType: The collaborator type we want to grab from the cache
 * @param date: the date for which we want the people
 * @returns All the collaborators for the given date range in the form of {email, location, date}
 */
export function usePeopleFromCacheForDateRangeCount(dateRange: DateRange) {
    /** We check the current owner to ensure if multiple accounts are
     * logged in, we only get the data relevant for the current account
     */
    const currentOwner = getUserExternalDirectoryId();
    return useLiveQuery(
        async () => {
            try {
                // Fetch all collaborators from saved and suggested tables
                const savedCollaboratorsPromise = savedCollaboratorTable.toArray();
                const suggestedCollaboratorsPromise = suggestedCollaboratorTable.toArray();

                return Promise.all([savedCollaboratorsPromise, suggestedCollaboratorsPromise]).then(
                    ([savedCollaborators, suggestedCollaborators]) => {
                        // Combine emails from both tables into a Set to ensure uniqueness
                        const collaboratorEmails = new Set([
                            ...savedCollaborators.map(c => c.email),
                            ...suggestedCollaborators.map(c => c.email),
                        ]);

                        // Fetch data from placesPeopleTable based on the provided criteria
                        return placesPeopleTable
                            .where('startOfDay')
                            .between(
                                getTimestamp(startOfDay(dateRange.start)) /** lowerBound */,
                                getTimestamp(endOfDay(dateRange.end)) /** upperBound */,
                                true /** include lower bound */,
                                true /** include upper bound */
                            )
                            .and(row => row.owner === currentOwner)
                            .toArray()
                            .then(peopleData => {
                                // Filter peopleData to only include rows where the email is in collaboratorEmails
                                const filteredData = peopleData.filter(row =>
                                    collaboratorEmails.has(row.email)
                                );

                                // Map the filtered data to the desired shape
                                return filteredData.map(row => ({
                                    email: row.email as UserEmailAddress,
                                }));
                            });
                    }
                );
            } catch (e) {
                logGreyError('PlacesPeopleCache_usePeopleFromCacheForDateRangeCount', e);
                return [];
            }
        },
        [] /** deps */,
        [] /** default return value */
    );
}
