import {
    getDefaultShouldShortenEventDuration,
    getDefaultEndShortEventsBy,
    getDefaultEndLongEventsBy,
} from 'owa-calendar-cloud-settings';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Gets the number of minutes to shorten the events when the 'End events early'/'Shorten events duration' setting is on
 * @param eventDuration
 */
export default function getShortenEventsDurationBy(
    eventDuration: number,
    mailboxInfo: MailboxInfo
): number {
    if (!getDefaultShouldShortenEventDuration(mailboxInfo) || eventDuration === 0) {
        return 0;
    }

    // Short events
    if (eventDuration < 60) {
        return getDefaultEndShortEventsBy(mailboxInfo);
    }

    // Long events
    return getDefaultEndLongEventsBy(mailboxInfo);
}
