import { isRunningInMetaOSHub } from 'owa-config';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { type UTPageType } from 'owa-unified-telemetry';
import { getHostLocation } from 'owa-url/lib/hostLocation';
import { isPlacesExploreEnabled } from 'places-settings';
import { matchRoutes } from 'react-router-dom';
import { HomeRouteId } from '../components/PlacesRouter/HomeRouteId';

import type { RouteObject } from 'react-router-dom';

/**
 * Note, this function depends on route ids being defined in
 * HomeRouteId enum and that id being added in createPlacesRoutes
 */
export function getLoadedPageToLog(routes: RouteObject[]) {
    const baseName = (isRunningInMetaOSHub() ? '/hosted' : '') + '/places';
    const matchedRoutes = matchRoutes(routes, getHostLocation().pathname, baseName);
    const mailboxInfo = getModuleContextMailboxInfo();

    /** Mapping RouteMatch objects to their corresponding PageTypes
     * that we want to account for based on route id set in createPlacesRoutes */
    const matchesToPageType = matchedRoutes?.map(match => {
        const routeId = match.route.id;

        if (routeId) {
            if (routeId == HomeRouteId.HOME) {
                return isPlacesExploreEnabled(mailboxInfo) ? 'Explore' : 'People';
            } else if (routeId == HomeRouteId.PEOPLE || routeId == HomeRouteId.WORK_PLAN) {
                return 'People';
                //TODO: Add workgroups page handling here
            } else if (routeId == HomeRouteId.ADMIN_SPACE_MANAGEMENT) {
                return 'SpaceManagement';
            } else if (routeId == HomeRouteId.ADMIN_SPACE_ANALYTICS) {
                return 'SpaceAnalytics';
            } else if (routeId == HomeRouteId.ADMIN) {
                /** "/admin" redirects to "/admin/space-analytics" */
                return 'SpaceAnalytics';
            } else if (match.pathname.match(/^\/buildings\/(.*)/)?.length) {
                /** for the building case, the SKU check is taking longer than on app boot to load
                 *  so checking the pathname instead of relying on the route id
                 */
                return 'Explore';
            } else if (
                /**
                 * root and root-splat will always match - so excluding those.
                 * If there is a match other than root and root-splat, return "Other"
                 * because the page exisits, but we are not accounting for it.
                 */
                routeId != HomeRouteId.ROOT &&
                routeId != HomeRouteId.ROOT_SPLAT &&
                Object.values(HomeRouteId).includes(routeId)
            ) {
                return 'Other';
            }
        }
        return undefined;
    });

    /**
     * Once we mapped the RouteMatch to their PageTypes, we need to return the best match,
     * looking through the array in reverse order as best match will be at the end.
     * example: we want admin/space-management to map to /space-management not /admin.
     */
    const matchedToPage = matchesToPageType
        ?.slice()
        .reverse()
        .find(match => match != undefined);
    const bestMatch = matchedToPage ?? 'NotFound';

    return bestMatch;
}
