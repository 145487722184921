import { useConst, useForceUpdate } from '@fluentui/react-hooks';
import removeItem from './removeItem';

/**
 * Creates a simple list that can be used as component state
 * particularly when the items are not supposed to be stored in mobx.
 *
 * @returns A simple list, with add, remove and map methods.
 */
export function useSimpleList<T>() {
    // This hook avoids array-spread operations when adding or removing items to the list
    // while ensuring re-rendering of the parent component when the list changes.
    // This is inspired by Fluent's Toaster implementation. For reference, in
    // https://github.com/microsoft/fluentui/blob/master/packages/react-components/react-toast/
    // createToaster and useToaster, a Set is used to manage the list of toasts and forceUpdate is
    // passed as the onUpdate function to createToaster and its called when the set is updated.
    // The approach is similar to what is done here.
    // Note: Do not copy and paste this code without understanding it.
    const forceUpdate = useForceUpdate();
    return useConst(() => {
        const list: T[] = [];
        return {
            add(item: T) {
                list.push(item);
                forceUpdate();
            },
            remove(item: T) {
                removeItem(list, item);
                forceUpdate();
            },
            map<U>(callback: (item: T) => U) {
                return list.map(callback);
            },
        };
    });
}
