import { getCalendarAccountSources } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import { getStore } from '../store/store';

/**
 * Whether the store is initialized or not
 */
export default function getIsCloudSettingsStoreInitialized(mailboxInfo: MailboxInfo): boolean {
    const mapKey = getIndexerValueForMailboxInfo(mailboxInfo);
    return !!getStore().accounts.get(mapKey)?.isInitialized;
}

export function getAreAllAccountCloudSettingsStoreInitialized(): boolean {
    const accountSources = getCalendarAccountSources();
    const initStateMap = accountSources.map(accountSource =>
        getIsCloudSettingsStoreInitialized(accountSource.mailboxInfo)
    );
    return initStateMap.every(state => !!state);
}
