import { GET_PRESENCE_ENDPOINT, SET_WORK_LOCATION_ENDPOINT } from '../urls/PresenceEndpoints';
import { buildRequestHeader } from './helpers/buildRequestHeader';
import { getAuthToken } from './helpers/getAuthToken';
import { makeRequestWithRedirectHandling } from './helpers/makeRequestWithRedirectHandling';

import type { GetPresenceBodyType } from '../types/GetPresenceBodyType';
import type { SetWorkLocationRequest } from '../types/SetWorkLocationRequest';

interface IBasePresenceRequestParams {
    endpointId: string;
    correlationId: string;
}

interface IPresenceApi {
    makePresenceFetch: (
        { endpointId, correlationId }: IBasePresenceRequestParams,
        mris: GetPresenceBodyType[]
    ) => Promise<Response>;
    makeSetWorkLocationFetch: (
        { endpointId, correlationId }: IBasePresenceRequestParams,
        workLocation: SetWorkLocationRequest
    ) => Promise<Response>;
}

const makePresenceFetch = async (
    { endpointId, correlationId }: IBasePresenceRequestParams,
    mris: GetPresenceBodyType[]
) => {
    const token = await getAuthToken();
    const headers = buildRequestHeader(token, correlationId, endpointId);

    const options: RequestInit = {
        method: 'POST',
        body: JSON.stringify(mris),
        mode: 'cors',
        headers,
    };

    return makeRequestWithRedirectHandling(GET_PRESENCE_ENDPOINT, options);
};

const makeSetWorkLocationFetch = async (
    { endpointId, correlationId }: IBasePresenceRequestParams,
    workLocation: SetWorkLocationRequest
): Promise<Response> => {
    const token = await getAuthToken();
    const headers = buildRequestHeader(token, correlationId, endpointId);

    const options: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(workLocation),
        mode: 'cors',
        headers,
    };

    return makeRequestWithRedirectHandling(SET_WORK_LOCATION_ENDPOINT, options);
};

export const presenceServiceApi: IPresenceApi = {
    makePresenceFetch,
    makeSetWorkLocationFetch,
};
