import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { useLiveQuery } from 'dexie-react-hooks';
import { logGreyError } from 'owa-analytics';

import getCollaboratorTable from './getCollaboratorDb';

import type { CollaboratorGroup } from 'owa-graph-schema';

/**
 * @param collaboratorType: the people group you want to read from cache
 * @returns the list of people in the cache that correspond
 *          to the given collaboratorType
 */
export function useCollaboratorsFromCache(collaboratorType: CollaboratorGroup): string[] {
    /** We check the current owner to ensure if multiple accounts are
     * logged in, we only get the data relevant for the current account
     */
    const currentOwner = getUserExternalDirectoryId();
    const collabTable = getCollaboratorTable(collaboratorType);

    return useLiveQuery(
        () =>
            collabTable
                .where({
                    owner: currentOwner,
                })
                .toArray()
                .then(
                    data => {
                        return data.map(row => row.email);
                    },
                    e => {
                        logGreyError('PlacesPeopleCache_useCollaboratorsFromCache', e);
                        return [];
                    }
                ),
        [] /** deps */,
        [] /** default return value */
    );
}
