import { logPlacesEvent } from 'hybridspace-telemetry';
import microsoftPlacesLogo from 'owa-appbar-icons/lib/microsoftPlaces.svg';
import { observer } from 'owa-mobx-react';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { type UTPageType } from 'owa-unified-telemetry';
import { PLACES_BRANDING_NAME } from 'places-common-strings';
import { isPlacesExploreEnabled } from 'places-settings';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link, Subtitle1 } from '@fluentui/react-components';
import { logo, logoIcon } from './HybridspaceLogo.scss';
import mobile from 'is-mobile';
import { isRunningInMetaOSHub } from 'owa-config';

export default observer(function HybridspaceLogo({
    sourcePage,
}: {
    sourcePage: UTPageType | undefined;
}) {
    const title = PLACES_BRANDING_NAME;
    const mailboxInfo = getModuleContextMailboxInfo();
    const explorePage = isPlacesExploreEnabled(mailboxInfo);
    const id = 'PlacesLogo';
    const navigate = useNavigate();

    const onClick = React.useCallback(() => {
        navigate('/', { state: { origin: id } });
        const destinationPage = explorePage ? 'Explore' : 'People';
        if (sourcePage && sourcePage != destinationPage) {
            logPlacesEvent({
                eventName: 'PlacesNavigationClick',
                data: {
                    SourcePage: sourcePage,
                    DestinationPage: destinationPage,
                },
            });
        }
    }, [navigate, explorePage, sourcePage]);

    const isMetaosMobile = isRunningInMetaOSHub() && mobile();

    return (
        <Link className={logo} data-telemetry-id={id} onClick={onClick} appearance="subtle">
            <img className={logoIcon} src={microsoftPlacesLogo} aria-label={title} />
            {!isMetaosMobile && <Subtitle1>{title}</Subtitle1>}
        </Link>
    );
},
'HybridspaceLogo');
