import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import { createAccessorsForMapStore } from 'owa-viewstate-store';
import { type PlacesFinderLocationTypeFilter } from './schema/PlacesFinderViewState';

import type PlacesFinderViewState from './schema/PlacesFinderViewState';

export const getStore = createStore<{
    viewStates: ObservableMap<string, PlacesFinderViewState>;
}>('hybridspace-places-finder', {
    viewStates: new ObservableMap<string, PlacesFinderViewState>(),
});

export const {
    getSuggestions,
    setSuggestions,
    getSuggestionsSource,
    setSuggestionsSource,
    setSelectedBuildingId,
    getSelectedFloorId,
    setSelectedFloorId,
    getSelectedCapabilities,
    setSelectedCapabilities,
    getSelectedCapacity,
    setSelectedCapacity,
    getSelectedRoom,
    setSelectedRoom,
    setIsLoading,
    getIsLoading,
    getIsHostedInPlaces,
    setIsHostedInPlaces,
    getSelectedSection,
    setSelectedSection,
    getSelectedLocationType,
    setSelectedLocationType,
    getSectionsErrorState,
    setSectionsErrorState,
    getDefaultBuildingId,
} = createAccessorsForMapStore(
    'hybridspace-places-finder',
    () => getStore().viewStates,
    getInitViewStateValue
);

export function getInitViewStateValue(): PlacesFinderViewState {
    return {
        suggestions: [],
        suggestionsSource: 'none',
        selectedBuildingId: null,
        selectedFloorId: 'none',
        selectedCapabilities: [],
        selectedCapacity: null,
        selectedRoom: null,
        isLoading: true,
        isHostedInPlaces: false,
        selectedSection: null,
        selectedLocationType: 'All',
        sectionsErrorState: false,
        defaultBuildingId: null,
    };
}
