import type { Room } from 'owa-room-selection-types';
import type AttendeeType from 'owa-service/lib/contract/AttendeeType';
import type ResponseTypeType from 'owa-service/lib/contract/ResponseTypeType';

export default function addRoomToAttendeeArray(
    requiredAttendees: AttendeeType[],
    optionalAttendees: AttendeeType[],
    resources: AttendeeType[],
    room: Room
): RestAttendee[] {
    const attendeesArray = [];
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    requiredAttendees?.forEach(attendee => {
        attendeesArray.push(convertAttendeeTypeToRestAttendee(attendee, 'required'));
    });

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    optionalAttendees?.forEach(attendee => {
        attendeesArray.push(convertAttendeeTypeToRestAttendee(attendee, 'optional'));
    });

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    resources?.forEach(attendee => {
        attendeesArray.push(convertAttendeeTypeToRestAttendee(attendee, 'resource'));
    });

    const newRoomAttendee = {
        EmailAddress: {
            Name: room.name,
            Address: room.email,
        },
        Type: 'resource',
    } as RestAttendee;

    attendeesArray.push(newRoomAttendee);

    return attendeesArray;
}

function convertAttendeeTypeToRestAttendee(
    attendee: AttendeeType,
    participantType: string
): RestAttendee {
    return {
        Type: participantType,
        Status: {
            Response: convertResponseTypeToGraphEnum(attendee?.ResponseType as ResponseTypeType),
            Time: attendee?.LastResponseTime as string,
        },
        EmailAddress: {
            Name: attendee?.Mailbox?.Name as string,
            Address: attendee?.Mailbox?.EmailAddress as string,
        },
    } as RestAttendee;
}

type RestAttendee = {
    Type?: string;
    Status?: {
        Response: string;
        Time: string;
    };
    EmailAddress: {
        Name: string;
        Address: string;
    };
};

function convertResponseTypeToGraphEnum(responseType: ResponseTypeType | undefined): string {
    switch (responseType) {
        case 'Accept':
            return 'Accepted';
        case 'Tentative':
            return 'TentativelyAccepted';
        case 'Decline':
            return 'Declined';
        case 'Organizer':
            return 'Organizer';
        case 'NoResponseReceived':
            return 'NotResponded';
        case 'Unknown':
        default:
            return 'None';
    }
}
