import { addCalendarEvents, loadCalendarViewSucceeded } from 'places-calendar-view-store';
import { orchestrator } from 'satcheljs';

import { isCurrentDateRange } from '../utils/isCurrentDateRange';

orchestrator(loadCalendarViewSucceeded, ({ result, dateRange }) => {
    if (isCurrentDateRange(dateRange)) {
        addCalendarEvents(dateRange, result);
    }
});
