import type { Place } from 'owa-graph-schema';
import { GetPlacesDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getPlacesQuery.interface';
import { query } from 'owa-apollo';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';

/**
 * Get all the Places of type building in the user's tenant
 */
export async function getPlacesBuildingList(): Promise<Map<string, Place>> {
    const perfDatapoint = new PerformanceDatapoint('PlacesGetPlaces');

    const querystring = 'Type = "Building"';
    const { data, error } = await query(GetPlacesDocument, {
        variables: { placesRequest: { query: querystring } },
        fetchPolicy: 'cache-first',
    });

    if (!data) {
        const errorToThrow = new Error(
            'PlacesFailed: Could not get places for this tenant. perfDatapointName: PlacesGetPlaces'
        );
        perfDatapoint.addCustomData({ error: error?.message });
        perfDatapoint.endWithError(DatapointStatus.ServerError, errorToThrow);
        throw error;
    }

    const placeMap = new Map<string, Place>();
    (data?.places?.places || [])
        .filter(place => !!place?.id && !!place.name)
        .map(place => {
            if (place) {
                placeMap.set(place.id, place);
            }
        });

    perfDatapoint.end();
    return placeMap;
}
