//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetFlexibleWorkingHoursForEmployeeQueryVariables = Types.Exact<{
    input: Types.GetFlexibleWorkingHoursViewRequest;
}>;
export type GetFlexibleWorkingHoursForEmployeeQuery = {
    __typename?: 'Query';
    flexibleWorkingHoursView?: {
        __typename?: 'GetFlexibleWorkingHoursViewResponse';
        segments?: Array<{
            __typename?: 'FlexibleWorkingHoursView';
            id: string;
            workLocationType: Types.WorkLocationType;
            isConfirmed?: boolean | null;
            subject?: string | null;
            isAllDay?: boolean | null;
            start: {
                __typename?: 'ExtendedDateTime';
                dateTime: string;
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            };
            end: {
                __typename?: 'ExtendedDateTime';
                dateTime: string;
                timeZone: {
                    __typename?: 'TimeZoneWH';
                    name: string;
                };
            };
            occurrenceDetails?: {
                __typename?: 'OccurrenceDetails';
                seriesId?: string | null;
                seriesMasterId?: string | null;
            } | null;
            workLocationDetails?: {
                __typename?: 'Location';
                id?: string | null;
                idType?: Types.LocationIdType | null;
                displayName?: string | null;
                annotation?: string | null;
                locationType?: Types.LocationEntityType | null;
                locationUri?: string | null;
                locationSource?: Types.LocationSourceType | null;
            } | null;
        }> | null;
    } | null;
};
export const GetFlexibleWorkingHoursForEmployeeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getFlexibleWorkingHoursForEmployee' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GetFlexibleWorkingHoursViewRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flexibleWorkingHoursView' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'request' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'segments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLocationType' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'start' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dateTime',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'timeZone',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'end' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dateTime',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'timeZone',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'occurrenceDetails' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'seriesId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'seriesMasterId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isConfirmed' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workLocationDetails',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'idType' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'displayName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'annotation',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationUri',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationSource',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subject' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isAllDay' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetFlexibleWorkingHoursForEmployeeQuery,
    GetFlexibleWorkingHoursForEmployeeQueryVariables
>;
