import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspacePlacesFinderPane" */ './lazyIndex')
);

export const LazyHybridspacePlacesFinderPane = createLazyComponent(
    lazyModule,
    m => m.HybridspacePlacesFinderPane
);

export const LazyHybridspacePlacesFinderSectionPane = createLazyComponent(
    lazyModule,
    m => m.HybridspacePlacesFinderSectionPane
);

export const LazyPlacesFinderFilters = createLazyComponent(lazyModule, m => m.PlacesFinderFilters);

export { getSelectedRoom, getIsLoading, getSectionsErrorState } from './store/store';

export const setRoomSelectionFromRoomId = new LazyAction(
    lazyModule,
    m => m.setRoomSelectionFromRoomId
);

export const removePlacesFinderViewState = new LazyAction(
    lazyModule,
    m => m.removePlacesFinderViewState
);

export const initializePlacesFinder = new LazyAction(lazyModule, m => m.initializePlacesFinder);

export const setDefaultBuildingId = new LazyAction(lazyModule, m => m.setDefaultBuildingId);

export { getSuggestionsWithLocationType as getSuggestions } from './store/selectors';

export { usePlacesFinderPerformanceDatapoint } from './hooks/usePlacesFinderPerformanceDatapoint';
export { default as markPlacesFinderPerfCheckpoint } from './utils/markPlacesFinderPerfCheckpoint';
