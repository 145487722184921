import { mutatorAction } from 'satcheljs';
import { placesExploreDiagnosticsStore } from './store';

export const updateExploreDiagnosticsPageState = mutatorAction(
    '__DIAGNOSTICS_UPDATE_EXPLORE_DIAGNOSTICS_PAGE_STATE',
    (pageState: string) => {
        placesExploreDiagnosticsStore.devPageState = pageState;
    }
);

export const updateExploreDiagnosticsErrorState = mutatorAction(
    '__DIAGNOSTICS_UPDATE_EXPLORE_DIAGNOSTICS_ERROR_STATE',
    (errorState: string) => {
        placesExploreDiagnosticsStore.devErrorPageState = errorState;
    }
);

export const updateExploreDiagnosticsComponentState = mutatorAction(
    '__DIAGNOSTICS_UPDATE_EXPLORE_DIAGNOSTICS_COMPONENT_STATE',
    (componentState: string) => {
        placesExploreDiagnosticsStore.devComponentState = componentState;
    }
);
