import { isWithinRange, today } from 'owa-datetime';
import { observableTodayChanged } from 'owa-observable-datetime';
import { getCurrentDateRange, setCurrentDate } from 'places-user-session-stores';
import { orchestrator } from 'satcheljs';

orchestrator(observableTodayChanged, () => {
    const todaysDate = today();
    const dateRange = getCurrentDateRange();

    if (isWithinRange(todaysDate, dateRange.start, dateRange.end)) {
        setCurrentDate(todaysDate);
    }
});
