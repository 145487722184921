import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import { getTimestamp, startOfDay, today } from 'owa-datetime';
import { getCollaboratorsOfficeLocations } from 'places-people-store';

export function getCollaboratorsFromLocation(buildingId: string): UserEmailAddress[] | undefined {
    const collaboratorsLocationForAllWeek = getCollaboratorsOfficeLocations();
    const dateNumber = getTimestamp(startOfDay(today()));
    const collaboratorsLocations = collaboratorsLocationForAllWeek.get(dateNumber);

    if (!collaboratorsLocations) {
        return undefined;
    }
    const emailsFromLocation = (collaboratorsLocations.get(buildingId) as UserEmailAddress[]) || [];

    return emailsFromLocation
        .map(email => email.toLowerCase())
        .filter(email => email !== '') as UserEmailAddress[];
}
