import { createObservableOperation } from 'owa-observable-operation';
import fetchListUserFeatureAccess from '../services/fetchListUserFeatureAccess';
import { ListUserFeatureAccessKey } from '../constants/constants';

const {
    start: startListUserFeatureAccess,
    isInProgress: loadListUserFeatureAccessInProgress,
    getLastError: getListUserFeatureAccessLastError,
    onSucceeded: loadListUserFeatureAccessSucceeded,
} = createObservableOperation(
    'PLACES_ANALYTICS_LOAD_EVENTS_FOR_LIST_USER_FEATURE_ACCESS',
    () => ({}),
    () => ListUserFeatureAccessKey,
    () => fetchListUserFeatureAccess()
);

let hasLoadedListUserFeatureAccess = false;

const loadListUserFeatureAccess = () => {
    if (!hasLoadedListUserFeatureAccess) {
        startListUserFeatureAccess();
        hasLoadedListUserFeatureAccess = true;
    }
};

export {
    getListUserFeatureAccessLastError,
    loadListUserFeatureAccess,
    loadListUserFeatureAccessInProgress,
    loadListUserFeatureAccessSucceeded,
};
