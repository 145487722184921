import { SkipLinkControl } from 'owa-skip-link';
import { Module } from 'owa-workloads';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { PlacesAppHeaderWithRole } from '../HybridspaceAppHeader/PlacesAppHeaderWithRole';
import PlacesMessageBar from '../PlacesMessageBar/PlacesMessageBar';
import usePlacesMetaOS from '../PlacesMetaOS/usePlacesMetaOS';
import { appContainer } from './PlacesRootRouteLayout.scss';
import { MsalAuthInfoBar } from 'owa-app-msal-messagebar';

export function PlacesRootRouteLayout() {
    usePlacesMetaOS();

    return (
        <>
            <div className={appContainer}>
                <SkipLinkControl currentModule={Module.MicrosoftPlaces} />
                <PlacesAppHeaderWithRole />
                <PlacesMessageBar />
                <MsalAuthInfoBar />
                <Outlet />
            </div>
        </>
    );
}
