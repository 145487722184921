import { useCurrentRoute } from 'accelerator-common/lib/AccNavTabList';
import { AccTabList } from 'accelerator-common/lib/AccTabList';
import { ADMIN_ROOT, SPACE_ANALYTICS, SPACE_MANAGEMENT } from 'hybridspace-common/lib/constants';
import { useRoleContext, UserRoles } from 'hybridspace-common/lib/context';
import {
    isSpaceAnalyticAdminTabEnabled,
    isSpaceManagementAdminTabEnabled,
    isSpaceManagementPage,
    useHybridspaceAdminLinks,
} from 'hybridspace-common/lib/utils';
import { useWindowBreakpoints } from 'hybridspace-common/lib/utils/useWindowDimensions';
import { PEOPLE, useHybridspaceHomeLinks } from 'hybridspace-home';
import { isPlacesAnalyticsHomePage, isPlacesHomePage } from 'hybridspace-performance-datapoints';
import { logPlacesEvent } from 'hybridspace-telemetry';
import { getHostHub } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    type UTExploreCardType,
    type UTExploreInterationType,
    type UTPageType,
} from 'owa-unified-telemetry';
import { useListUserFeatureAccessSelector } from 'places-analytics-app-store';
import { PlacesSearchBar } from 'places-explore-search';
import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';
import { GlobalActionButton } from 'places-global-action-button';
import {
    isPlacesExploreEnabled,
    isPlacesPremiumEnabled,
    isPlacesSearchEnabled,
} from 'places-settings';
import { getShowBuildingFilterOptions } from 'places-user-session-stores';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Tooltip } from '@fluentui/react-components';
import { bundleIcon, SearchFilled, SearchRegular } from '@fluentui/react-icons';
import HybridspaceLogo from '../HybridspaceLogo';
import HybridspaceRoleSwitcher from '../HybridspaceRoleSwitcher/HybridspaceRoleSwitcher';
import PlacesMoreDropdown from '../PlacesMoreDropdown/PlacesMoreDropdown';
import { searchButtonAriaLabel } from './HybridspaceNavHeader.locstring.json';
import {
    divider,
    gabPadding,
    meControlPadding,
    metaOSPadding,
    navHeader,
    placesSearchBar,
    placesSearchBarActive,
    searchButton,
    settingsArea,
} from './HybridspaceNavHeader.scss';
import HybridspaceSuiteHeader from './HybridspaceSuiteHeader';
import SmallScreenNav from './SmallScreenNav';

import type { SearchSuggestion } from 'accelerator-search-bar';

const SearchIcon = bundleIcon(SearchFilled, SearchRegular);
const searchIcon = <SearchIcon />;

export default observer(function HybridspaceNavHeader({
    showCompactAppHeader,
}: {
    showCompactAppHeader: boolean;
}) {
    const hostHub = getHostHub();
    const isStandaloneApp = hostHub === undefined;

    const navigate = useNavigate();
    const location = useLocation().pathname;

    const mailboxInfo = getModuleContextMailboxInfo();
    const explorePage = isPlacesExploreEnabled(mailboxInfo);
    const searchEnabled = isPlacesSearchEnabled(mailboxInfo);

    const homeLinks = useHybridspaceHomeLinks();
    const adminLinks = useHybridspaceAdminLinks();
    const roleSwitcherDisabled = isFeatureEnabled('msplaces-disableRoleSwitcher');

    const links = React.useMemo(() => [...homeLinks, ...adminLinks], [homeLinks, adminLinks]);

    const defaultRoute = useCurrentRoute(links);

    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [selectedTab, setSelectedTab] = React.useState(defaultRoute);

    const { featureAccess, loading } = useListUserFeatureAccessSelector();
    const isSpaceAnalyticAdminEnabled = isSpaceAnalyticAdminTabEnabled(featureAccess);
    const isSpaceManagementAdminEnabled = isSpaceManagementAdminTabEnabled();
    const isAdminEnabled = isSpaceAnalyticAdminEnabled || isSpaceManagementAdminEnabled;
    const isAnalyticsHomePage = isPlacesAnalyticsHomePage(window?.location?.pathname || '');
    const isSpaceManagementAdminPage = isSpaceManagementPage(window?.location?.pathname || '');
    const isHomePage = isPlacesHomePage(window?.location?.pathname || '');
    const showGlobalActionButton =
        isFeatureEnabled('msplaces-showGlobalActionButtonOnNavHeader', mailboxInfo) &&
        !isFeatureEnabled('msplaces-explore-building-header-v2', mailboxInfo) &&
        isPlacesPremiumEnabled(mailboxInfo) &&
        getShowBuildingFilterOptions() &&
        explorePage &&
        isHomePage;

    React.useEffect(() => {
        if (!loading && isAnalyticsHomePage) {
            placesAnalyticsAppLoadAction({
                scenario: 'RBACSettingsFetched',
            });

            if (!isSpaceAnalyticAdminEnabled) {
                // if the user doesn't have access to admin analytics, stop Perf Marker as it is a valid scenario.
                placesAnalyticsAppLoadAction({
                    scenario: 'NonAdminRBACSettingsFetched',
                });
            }
        }
    }, [loading, isSpaceAnalyticAdminEnabled, isAnalyticsHomePage]);

    const { userRole, setUserRole } = useRoleContext();

    React.useEffect(() => {
        if (location.includes(ADMIN_ROOT)) {
            setUserRole(UserRoles.Admin);
        } else {
            setUserRole(UserRoles.Employee);
        }
    }, [location, userRole, setUserRole]);

    const [isActiveSearchBar, setIsActiveSearchBar] = React.useState(false);

    const { isScreenSmallDown, isScreenMediumDown, isScreenLargeDown, isScreenXLargeUp } =
        useWindowBreakpoints();

    const showTabList = !isScreenSmallDown;
    const searchingOnSmallScreen = isActiveSearchBar && isScreenSmallDown;
    const searchingOnMediumScreen = isActiveSearchBar && isScreenLargeDown;

    const routeToPageMap = React.useMemo(
        () =>
            new Map([
                //TODO: add workgroups path resolving here
                [`/${PEOPLE}`, 'People'],
                [`/${ADMIN_ROOT}`, 'AdminDashboard'],
                [`/${ADMIN_ROOT}/${SPACE_ANALYTICS}`, 'SpaceAnalytics'],
                [`/${ADMIN_ROOT}/${SPACE_MANAGEMENT}`, 'SpaceManagement'],
                ['/', explorePage ? 'Explore' : 'People'],
            ]),
        [explorePage]
    );

    const handleSelectedKeyChange = React.useCallback(
        (newSelectedKey: string) => {
            const sourcePage = routeToPageMap.get(selectedTab);
            const destPage = routeToPageMap.get(newSelectedKey);
            setSelectedTab(newSelectedKey);
            navigate(newSelectedKey);

            // Check that the source and destination pages are both defined and different before logging the event
            if (sourcePage && destPage && sourcePage != destPage) {
                logPlacesEvent({
                    eventName: 'PlacesNavigationClick',
                    data: {
                        SourcePage: sourcePage,
                        DestinationPage: destPage,
                    },
                });
            }
        },
        [routeToPageMap, selectedTab, navigate]
    );

    const onSearchButtonClick = React.useCallback(() => {
        logPlacesEvent({
            eventName: 'PlacesExploreCardInteraction',
            data: {
                CardType: 'SearchBar',
                InteractionType: 'SearchBar',
            },
        });
        setIsActiveSearchBar(true);
    }, []);

    const handleSearchBarOnBlur = React.useCallback((e: React.FocusEvent) => {
        if (
            !e.currentTarget.contains(e.relatedTarget) &&
            !isFeatureEnabled('msplaces-search-debug-css')
        ) {
            setIsActiveSearchBar(false);
        }
    }, []);

    // Force selected tab to update if route changes from somewhere that is not a tab click
    // i.e. if navigates via search bar
    React.useEffect(() => {
        setSelectedTab(defaultRoute);
    }, [defaultRoute]);

    const handleSuggestionClick = React.useCallback(
        (suggestion: SearchSuggestion, _searchProviderKey: string, _alternativeAction: boolean) => {
            setIsActiveSearchBar(false);
            if (suggestion.href) {
                // If suggestion navigates force selected tab to update
                setSelectedTab(defaultRoute);
            }
        },
        [defaultRoute]
    );

    const searchBar = React.useMemo(() => {
        return (
            (isActiveSearchBar || isScreenXLargeUp) && (
                <div
                    className={isActiveSearchBar ? placesSearchBarActive : placesSearchBar}
                    onBlur={handleSearchBarOnBlur}
                    data-telemetry-id={'SearchBar'}
                >
                    <PlacesSearchBar
                        onFocus={onSearchButtonClick}
                        autoFocus={isActiveSearchBar}
                        onSuggestionClicked={handleSuggestionClick}
                        isMediumScreen={isScreenMediumDown}
                    />
                </div>
            )
        );
    }, [
        isActiveSearchBar,
        isScreenXLargeUp,
        handleSearchBarOnBlur,
        onSearchButtonClick,
        handleSuggestionClick,
        isScreenMediumDown,
    ]);

    return (
        <div className={navHeader} data-telemetry-id="NavigationHeader">
            {!searchingOnSmallScreen && (
                <HybridspaceLogo sourcePage={routeToPageMap.get(selectedTab)} />
            )}
            {showTabList && !searchingOnMediumScreen && (
                <AccTabList
                    items={links}
                    selectedKey={selectedTab}
                    appearance={'subtle'}
                    onSelectedKeyChange={handleSelectedKeyChange}
                    responsiveTabs={true}
                    isAppFolderOverflowIcon={true}
                />
            )}

            {!isAnalyticsHomePage && !isSpaceManagementAdminPage && searchEnabled && (
                <>
                    {searchBar}
                    {!isActiveSearchBar && (
                        <Tooltip content={loc(searchButtonAriaLabel)} relationship="label">
                            <Button
                                className={searchButton}
                                icon={searchIcon}
                                appearance="subtle"
                                onClick={onSearchButtonClick}
                                data-telemetry-id={'SearchButton'}
                                aria-label={loc(searchButtonAriaLabel)}
                            ></Button>
                        </Tooltip>
                    )}
                </>
            )}

            {isAdminEnabled && !roleSwitcherDisabled && <HybridspaceRoleSwitcher />}

            <div className={settingsArea}>
                {!isAnalyticsHomePage && !isSpaceManagementAdminPage && showGlobalActionButton && (
                    <div className={!isScreenMediumDown ? gabPadding : ''}>
                        <GlobalActionButton />
                    </div>
                )}
                {!searchingOnSmallScreen &&
                    (showGlobalActionButton ||
                        searchEnabled ||
                        (isAdminEnabled && !roleSwitcherDisabled)) && (
                        <Divider vertical={true} className={divider} />
                    )}
                <SmallScreenNav
                    links={links}
                    navigate={handleSelectedKeyChange}
                    selectedRoute={selectedTab}
                    isSmallScreen={isScreenSmallDown}
                    isStandaloneApp={isStandaloneApp}
                />
                <PlacesMoreDropdown isStandaloneApp={isStandaloneApp} />
                <>
                    <div
                        className={isStandaloneApp ? meControlPadding : metaOSPadding}
                        data-telemetry-id="MeControl"
                    >
                        {isStandaloneApp && (
                            <HybridspaceSuiteHeader compact={showCompactAppHeader} />
                        )}
                    </div>
                </>
            </div>
        </div>
    );
},
'HybridspaceNavHeader');
