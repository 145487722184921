import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the PrimaryTimezoneLabel cloud setting
 */
export default function getPrimaryTimezoneLabelCloudSetting(
    mailboxInfo: MailboxInfo
): string | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const primaryTimezoneLabelSetting = accountSettings.get(
        CalendarCloudSettingType.PrimaryTimezoneLabel.toLowerCase()
    );

    return primaryTimezoneLabelSetting != null
        ? (primaryTimezoneLabelSetting.value as string)
        : undefined;
}
