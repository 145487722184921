import type { MailboxInfo } from 'owa-client-types';
import getPrimaryTimezoneLabelCloudSetting from '../selectors/getPrimaryTimezoneLabelCloudSetting';

const DEFAULT_PRIMARY_TIMEZONE_LABEL_FALLBACK = '';

/**
 * Gets the default value for the primary timezone label setting
 */
export default function getDefaultPrimaryTimezoneLabel(mailboxInfo: MailboxInfo): string {
    const primaryTimezoneLabel = getPrimaryTimezoneLabelCloudSetting(mailboxInfo);

    return primaryTimezoneLabel != null
        ? primaryTimezoneLabel
        : DEFAULT_PRIMARY_TIMEZONE_LABEL_FALLBACK;
}
