import React from 'react';

export type PlacesWorkGroupsContextType = {
    onDrawerIconClick: (() => void) | undefined;
    closeDrawer: (() => void) | undefined;
};

const defaultPlacesWorkGroupsContextValue: PlacesWorkGroupsContextType = {
    onDrawerIconClick: undefined,
    closeDrawer: undefined,
};

export const PlacesWorkGroupsContext = React.createContext<PlacesWorkGroupsContextType | null>(
    defaultPlacesWorkGroupsContextValue
);

export const usePlacesWorkGroupsContextProvider = () => {
    const context = React.useContext(PlacesWorkGroupsContext);
    if (context == undefined) {
        return defaultPlacesWorkGroupsContextValue;
    } else {
        return context;
    }
};
