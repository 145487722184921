import type { AccItem } from './AccItem';
import classNames from 'owa-classnames';
import DismissFilled from 'owa-fluent-icons-svg/lib/icons/DismissFilled';
import { FluentButton } from 'owa-fluent-v9-shims';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { Link } from '@fluentui/react/lib/Link';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { getValue, useAccItems } from './AccItem';
import strings from './AccMessageBar.locstring.json';
import {
    accInfoMessageBar,
    accMessageBar,
    accMessageBarActions,
    accMessageBarDismissIcon,
    accMessageBarIcon,
    accMessageBarLink,
    accMessageBarText,
} from './AccMessageBar.scss';

import type { ILinkStyles } from '@fluentui/react/lib/Link';
import type { IMessageBarStyles } from '@fluentui/react/lib/MessageBar';
import type { IButtonStyles } from '@fluentui/react/lib/Button';
import type { ResourceId, LocalizedString } from 'owa-localize';

const dismissIcon = { iconName: DismissFilled };

const messageBarStyles: IMessageBarStyles = {
    icon: accMessageBarIcon,
    text: accMessageBarText,
    actions: accMessageBarActions,
    dismissal: accMessageBarIcon,
};

const linkStyles: ILinkStyles = {
    root: accMessageBarLink,
};

const dismissButtonStyles: IButtonStyles = {
    root: accMessageBarIcon,
    icon: accMessageBarDismissIcon,
};

export interface AccMessageBarAction extends AccItem {
    onClick?: () => void;
    href?: string;
}

/** Renders a warning message with an optional set of actions that can be taken by the user. */
export const AccMessageBar = observer(function AccMessageBar({
    className,
    messageResourceId,
    message,
    actions,
    onDismiss,
    messageBarType = MessageBarType.warning,
    displayLinkAtEnd = true,
    target,
}: {
    className?: string;
    messageResourceId?: ResourceId;
    message?: LocalizedString | string;
    actions?: AccMessageBarAction[];
    onDismiss?: () => void;
    messageBarType?: MessageBarType;
    displayLinkAtEnd?: boolean;
    target?: string;
}) {
    const messageBarClassName = classNames(accMessageBar, className, {
        [accInfoMessageBar]: messageBarType === MessageBarType.info,
    });

    const links = useAccItems(actions || [], ({ key, textResourceId, disabled, onClick, href }) => (
        <Link
            key={key}
            styles={linkStyles}
            disabled={getValue(disabled)}
            onClick={onClick}
            href={href}
            target={target ? target : undefined}
        >
            {loc(getValue(textResourceId))}
        </Link>
    ));

    const actionsElement = React.useMemo(
        () => (
            <div>
                {displayLinkAtEnd && links}
                {onDismiss && (
                    <FluentButton
                        key="dismiss"
                        appearance="icon"
                        styles={dismissButtonStyles}
                        iconProps={dismissIcon}
                        aria-label={loc(strings.accMessageBarDismissLink)}
                        onClick={onDismiss}
                    />
                )}
            </div>
        ),
        [links, onDismiss, displayLinkAtEnd]
    );

    return (
        <MessageBar
            className={messageBarClassName}
            styles={messageBarStyles}
            messageBarType={messageBarType}
            isMultiline={true}
            role="alert"
            actions={actionsElement}
        >
            {message || loc(messageResourceId)}
            {!displayLinkAtEnd && links}
        </MessageBar>
    );
},
'AccMessageBar');
