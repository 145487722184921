import type { MailboxInfo } from 'owa-client-types';
import getLeftPanelInitStateCloudSetting from '../selectors/getLeftPanelInitStateCloudSetting';

const LEFT_PANE_INIT_STATE_FALLBACK = false;
/**
 * Gets the default value for the left pane init state
 * @param mailboxInfo the mailbox info
 * @returns the boolean value for the left pane init state
 */
export default function getLeftPanelInitOcsState(mailboxInfo: MailboxInfo): boolean {
    const leftPanelInitState = getLeftPanelInitStateCloudSetting(mailboxInfo);
    return leftPanelInitState != null ? leftPanelInitState : LEFT_PANE_INIT_STATE_FALLBACK;
}
