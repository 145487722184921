import { AccOutageMessageBar } from 'accelerator-common/lib/AccOutageMessageBar';
import { getApplicationSettings } from 'owa-application-settings';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { PlacesOutageMessage } from './PlacesMessageBar.locstring.json';

import type { ResourceId, LocalizedString } from 'owa-localize';
// Add here the loc strings that are used for different scenarios
// ECS flight:  https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1243172/iterations/4?showdiff=true
// the outageEnumIdMessage should be the index of the loc string in the array
const NotificationEnumToResourceIdMap: ResourceId[] = [PlacesOutageMessage];
const NotificationMessageBarType = [
    MessageBarType.severeWarning,
    MessageBarType.success,
    MessageBarType.info,
];

export default observer(function PlacesMessageBar() {
    const {
        hasOutage,
        outageEnumIdMessage,
        outageLinkUri,
        outageCustomMessage,
        typeMessageVariant,
    } = getApplicationSettings('PlacesOutage');

    const placesNotificationMessage: LocalizedString | string =
        outageCustomMessage === ''
            ? loc(NotificationEnumToResourceIdMap[outageEnumIdMessage])
            : outageCustomMessage;
    const placesNotificationType = NotificationMessageBarType[typeMessageVariant];
    return (
        <AccOutageMessageBar
            showOutageBar={hasOutage}
            outageMessage={placesNotificationMessage}
            outageLinkUri={outageLinkUri}
            target={'_blank'}
            customMessageBarType={placesNotificationType}
        />
    );
}, 'PlacesMessageBar');
