import getDefaultPrimaryTimezoneLabel from './getDefaultPrimaryTimezoneLabel';
import getDefaultRoamingTimezones from './getDefaultRoamingTimezones';
import getDefaultVisibleRoamingTimezones from './getDefaultVisibleRoamingTimezones';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Gets the time zone label based on the time zone id and index.
 * When onlyVisibleTimezones is true, we search for the timezoneId only through the list of visible time zones
 * that are set as visible in settings. This is used in the Surface scenario when we only show/use
 * the visible time zones from settings.
 * When onlyVisibleTimezones is false, we search for the timezoneId through all the time zones
 * (including the ones that are not visible). This is used everywhere else except for Surface
 * @param timezoneId
 * @param timezoneIndex
 * @param onlyVisibleTimezones
 */
export default function getTimezoneLabelForTimezoneId(
    timezoneId: string,
    mailboxInfo: MailboxInfo,
    timezoneIndex?: number,
    onlyVisibleTimezones?: boolean
): string | undefined {
    // Primary time zone
    if (
        timezoneId === getAccountScopeUserSettings(mailboxInfo)?.UserOptions?.TimeZone &&
        !timezoneIndex
    ) {
        return getDefaultPrimaryTimezoneLabel(mailboxInfo);
    }

    const visibleRoamingTimezones = getDefaultVisibleRoamingTimezones(mailboxInfo);
    const allRoamingTimezones = getDefaultRoamingTimezones(mailboxInfo);
    const roamingTimezones = onlyVisibleTimezones ? visibleRoamingTimezones : allRoamingTimezones;

    let timezoneLabel;

    if (
        timezoneIndex &&
        roamingTimezones.length >= timezoneIndex &&
        roamingTimezones[timezoneIndex - 1].timezoneId === timezoneId
    ) {
        timezoneLabel = roamingTimezones[timezoneIndex - 1]?.timezoneLabel;
    }

    return (
        timezoneLabel ?? allRoamingTimezones.find(tz => tz.timezoneId === timezoneId)?.timezoneLabel
    );
}
