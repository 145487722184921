import { HybridspaceError } from 'hybridspace-common/lib/components/HybridspaceError';
import { logGreyError } from 'owa-analytics';
import { scrubForPii } from 'owa-config';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { observer } from 'owa-mobx-react';
import { type UTPageLoadStatus, type UTPageType } from 'owa-unified-telemetry';
import { placesFwkPageLoadAction } from 'places-fwk-actions';
import React from 'react';
import {
    isRouteErrorResponse,
    useNavigate,
    useNavigation,
    useRevalidator,
    useRouteError,
} from 'react-router-dom';
import {
    goHomeButtonLabel,
    notFoundMessage,
    retryActionButtonLabel,
    retryMessage,
} from './PlacesErrorBoundary.locstring.json';
import { container } from './PlacesErrorBoundary.scss';

/**
 * PlacesErrorBoundary page for when the loader fails, Specific cases are set for NotFound and InternalServerError
 * wiki: https://dev.azure.com/outlookweb/MicrosoftPlaces/_wiki/wikis/MicrosoftPlaces.wiki/13460/How-To-Add-an-error-component
 */
export default observer(function PlacesErrorBoundary({
    errorName,
    errorStatus,
}: {
    errorName?: string;
    errorStatus?: HttpStatusCode;
}) {
    const revalidator = useRevalidator();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const routeError = useRouteError() as any;
    const isRouteError = isRouteErrorResponse(routeError);

    const status = !!errorStatus ? errorStatus : isRouteError ? routeError?.status : 500;

    const message = status === 404 ? notFoundMessage : retryMessage;

    const buttonTitle = status === 404 ? goHomeButtonLabel : retryActionButtonLabel;

    const reload = React.useCallback(() => {
        switch (status) {
            // If not found, navigate to the home page
            case 404:
                navigate('/');
                break;
            // Else we revalidate the route
            default:
                revalidator.revalidate();
                break;
        }
    }, [revalidator, navigate, status]);

    // UT Logs for for specific error names
    React.useEffect(() => {
        // Generic log for debugging.
        const serviceName = routeError?.statusText;
        const routeStatus = routeError?.status;
        logGreyError('PlacesErrorBoundary', routeError, {
            errorName,
            errorStatus,
            /* Check the status code sent from the loader, if it is 500, it is a service error
             * Otherwise we assumes its a client error. See PlacesBuildingLoader.ts for more details on how response is created
             */
            DataPointStatus: routeStatus === 500 ? 'ServiceError' : 'ClientError',
            /* Check the service name that is sent from the loader
             * PlacesBuildingLoader.ts will send PlacesGetPlace
             * PlacesHomeRouteLayoutLoader will send PlacesGetPlaces */
            ServiceName: serviceName ?? 'unknown',
            // If we are in the component, the page load has failed
            IsErrorMessageVisible: true,
            IsExplorePage: errorName === 'PlacesExplorePageRoot',
        });

        // when error exists, this will internally will log once.
        if (errorName && errorName === 'PlacesExplorePageRoot') {
            const errorMessage = routeError?.data ? scrubForPii(routeError?.data) : undefined;
            placesFwkPageLoadAction(
                'Explore',
                'end',
                status === 404 ? 'NotFound' : 'Error',
                errorMessage
            );
        }
    }, [status, errorName, routeError?.data, routeError, errorStatus]);

    const isLoading = navigation.state === 'loading' || revalidator.state != 'idle';

    return (
        <div className={container}>
            <HybridspaceError
                retry={reload}
                errorMessage={message}
                buttonLabel={buttonTitle}
                brandedButtonStyle={true}
                loading={isLoading}
            />
        </div>
    );
},
'PlacesErrorBoundary');
