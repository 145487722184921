import type { CalendarParticipant } from 'owa-graph-schema';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

const GET_SCHEDULE_LIMIT = 100;

/**
 * @param requiredAttendees the required attendees from a meeting
 * @param optionalAttendees the optional attendees from a meeting
 * @param filterOutCurrentUser to filter out the current user from returned list
 * @param limit the number of smtp addresses to include in the attendeeSmtpAddressWithLimit array
 * Returns attendeeSmtpAddressWithLimit which is the number of attendees with the limit and
 * allAttendeeSmtpAddress which is the full list of users that accepted the meeting
 */

export function getValidAttendeeSmtpAddressForGetSchedule(
    requiredAttendees: CalendarParticipant[],
    optionalAttendees: CalendarParticipant[],
    filterOutCurrentUser = true,
    limit = GET_SCHEDULE_LIMIT
) {
    const requiredAttending = requiredAttendees.filter((attendee: CalendarParticipant) => {
        return (
            attendee?.responseStatus?.responseType == 'Accept' ||
            attendee?.responseStatus?.responseType == 'Organizer'
        );
    });
    const optionalAttending = optionalAttendees.filter((attendee: CalendarParticipant) => {
        return attendee?.responseStatus?.responseType == 'Accept';
    });
    const attending = requiredAttending.concat(optionalAttending);
    const attendeeSmtpAddresses = attending.map(
        attendee => attendee?.userIdentity?.emailAddress || ''
    );
    // removing empty and duplicates values
    let validAttendeeSmtpAddresses = Array.from(
        new Set(
            attendeeSmtpAddresses.filter(function (smtpAddress) {
                return smtpAddress.length > 0;
            })
        )
    );

    if (filterOutCurrentUser) {
        // removing current user from facepile
        const currentUserEmail = getModuleContextMailboxInfo()?.userIdentity ?? '';
        validAttendeeSmtpAddresses = validAttendeeSmtpAddresses.filter(attendee => {
            return attendee != currentUserEmail;
        });
    }

    /**getSchedule can only take 100 email addresses at a time so limiting it here
     * WI: 206919 is tracking to batch these requests later on
     */
    return {
        attendeeSmtpAddressWithLimit:
            limit <= GET_SCHEDULE_LIMIT
                ? validAttendeeSmtpAddresses.splice(0, limit)
                : validAttendeeSmtpAddresses.splice(0, GET_SCHEDULE_LIMIT),
        allAttendeeSmtpAddress: validAttendeeSmtpAddresses,
    };
}
