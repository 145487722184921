import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

export default function getGlobalCalendarEnabledCloudSetting(mailboxInfo: MailboxInfo): boolean {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const globalCalendarEnabledSetting = accountSettings.get(
        CalendarCloudSettingType.GlobalCalendarEnabled.toLowerCase()
    );

    return globalCalendarEnabledSetting ? !!globalCalendarEnabledSetting.value : false;
}
