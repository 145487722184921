import PlacesExploreClient from './PlacesExploreClient';

let service: PlacesExploreClient | undefined;

export default function getPlacesService() {
    if (service === undefined) {
        service = new PlacesExploreClient();
    }
    return service;
}
