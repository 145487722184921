import initializeFilledCalendarEvent from 'hybridspace-common/lib/utils/initializeFilledCalendarEvent';
import { userDate, owaDate } from 'owa-datetime';
import attendeeType from 'owa-service/lib/factory/attendeeType';
import emailAddressWrapper from 'owa-service/lib/factory/emailAddressWrapper';

import type { CalendarEvent as GqlCalendarEvent, CalendarParticipant } from 'owa-graph-schema';
import type { default as OwaCalendarEvent } from 'owa-calendar-types/lib/types/CalendarEvent';
import type AttendeeType from 'owa-service/lib/contract/AttendeeType';
export default function convertGqlCalendarEventToOwaCalendarEvent(
    gqlCalendarEvent: GqlCalendarEvent
): OwaCalendarEvent {
    return {
        ...initializeFilledCalendarEvent({
            Start: userDate(
                owaDate(gqlCalendarEvent.start?.timeZone.name, gqlCalendarEvent.start?.dateTime)
            ),
            StartTimeZoneId: gqlCalendarEvent?.start?.timeZone.name,
            End: userDate(
                owaDate(gqlCalendarEvent.end?.timeZone.name, gqlCalendarEvent.end?.dateTime)
            ),
            EndTimeZoneId: gqlCalendarEvent?.end?.timeZone.name,
            IsAllDayEvent: gqlCalendarEvent.isAllDayEvent ?? false,
            RequiredAttendees: gqlCalendarEvent.meetingDetails?.requiredAttendees?.map(
                MapClientParticipantToAttendeeType
            ),
            OptionalAttendees: gqlCalendarEvent.meetingDetails?.optionalAttendees?.map(
                MapClientParticipantToAttendeeType
            ),
            FreeBusyType: gqlCalendarEvent.freeBusyType || undefined,
            Locations: [],
            IsOrganizer:
                gqlCalendarEvent.isOrganizer === null ? undefined : gqlCalendarEvent.isOrganizer,
            IsFollowableMeeting: gqlCalendarEvent.isFollowableMeeting,
        }),
    };
}

function MapClientParticipantToAttendeeType(participant: CalendarParticipant | null): AttendeeType {
    return attendeeType({
        Mailbox: emailAddressWrapper({
            Name: participant?.userIdentity?.name ?? undefined,
            EmailAddress: participant?.userIdentity?.emailAddress ?? undefined,
            RoutingType: participant?.userIdentity?.routingType ?? undefined,
        }),
    });
}
