import type { PerformanceDatapoint } from 'owa-analytics';
import { createStore } from 'satcheljs';
import { getDefaultValue } from '../utils/exploreStoreUtils';

export interface PlacesCopilotDebugInfo {
    timestamp: string;
    perfDatapoint: PerformanceDatapoint;
    responseBody: any;
    responseHeader: Headers | undefined;
}

interface PlacesCopilotDiagnosticsStore {
    debugInfoList: PlacesCopilotDebugInfo[];
}

const initializePlacesCopilotDiagnostics: PlacesCopilotDiagnosticsStore = {
    debugInfoList: [],
};

export const placesCopilotDiagnosticsStore = createStore(
    'PlacesCopilotDiagnostics',
    initializePlacesCopilotDiagnostics
)();

// Explore Page testing store
interface PlacesExploreDiagnosticsStore {
    devPageState: string | undefined;
    devErrorPageState: string | undefined;
    devComponentState: string | undefined;
}
const initializePlacesExploreDiagnosticsStore: PlacesExploreDiagnosticsStore = {
    devPageState: getDefaultValue('epageState'),
    devErrorPageState: getDefaultValue('epageError'),
    devComponentState: getDefaultValue('ecomponentState'),
};

export const placesExploreDiagnosticsStore = createStore<PlacesExploreDiagnosticsStore>(
    'PlacesExploreDiagnosticsStore',
    initializePlacesExploreDiagnosticsStore
)();
