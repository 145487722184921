import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { useLiveQuery } from 'dexie-react-hooks';
import { logGreyError } from 'owa-analytics';
import { getTimestamp, startOfDay } from 'owa-datetime';

import { placesPeopleTable } from '../database';

import type { OwaDate } from 'owa-datetime';
/**
 * @param email: the email of the person you want to read from cache
 * @param date: the date for which we want the persons info
 * @returns a person's location in the cache that corresponds to the given email and date
 */
export function useCollaboratorSchedule(email: string, date: OwaDate) {
    /** We check the current owner to ensure if multiple accounts are
     * logged in, we only get the data relevant for the current account
     */
    const currentOwner = getUserExternalDirectoryId();
    return useLiveQuery(
        () =>
            placesPeopleTable
                .where({
                    owner: currentOwner,
                    email,
                    startOfDay: getTimestamp(startOfDay(date)),
                })
                .limit(1) // Ensure only one result is returned
                .toArray()
                .then(
                    data => {
                        if (data.length === 0) {
                            return undefined; // Return null if no matching row is found
                        }
                        const row = data[0]; // Get the first (and only) matching row
                        return row.location as string;
                    },
                    e => {
                        logGreyError('PlacesPeopleCache_useCollaboratorSchedule', e);
                        return undefined;
                    }
                ),
        [] /** deps */,
        undefined /** default return value */
    );
}
