import { placesMapsTable, placesMapsDB } from '../database';
import { MAP_DATA_TTL_IN_DAYS, MAX_NUMBER_OF_MAPS } from '../constants';
import { now, getTimestamp, addDays } from 'owa-datetime';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

import type { PlacesMapsTableEntry } from '../database';
import type { Floor } from 'hybridspace-common/lib/serviceTypes';

export async function addFloorPlanToCache(floor: Floor) {
    if (!floor.floorId) {
        return;
    }

    const newEntry: PlacesMapsTableEntry = {
        floorId: floor.floorId,
        expirationTime: getTimestamp(addDays(now(), MAP_DATA_TTL_IN_DAYS)),
        floor,
    };

    try {
        await placesMapsDB.transaction('rw', placesMapsTable, async () => {
            await placesMapsTable.put(newEntry, 'floorId' /** primaryKey path */);

            const tableSize = await placesMapsTable.count();

            // Evict oldest entry if cache size exceeds the limit.
            if (tableSize > MAX_NUMBER_OF_MAPS) {
                const oldestFloor = await placesMapsTable.orderBy('expirationTime').first();

                if (oldestFloor) {
                    await placesMapsTable.delete(oldestFloor.floorId);
                }
            }
        });
    } catch (e) {
        debugErrorThatWillShowErrorPopupOnly('addFloorToCache', e);
    }
}
