import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesPersistentStore" */ './lazyIndex')
);

export const lazyDeletePlacesDatabase = new LazyAction(lazyModule, m => m.deletePlacesDatabase);
export const lazyGetDashboardTeamPlanSnapshot = new LazyAction(
    lazyModule,
    m => m.getDashboardTeamPlanSnapshot
);
export const lazySaveDashboardTeamPlanSnapshot = new LazyAction(
    lazyModule,
    m => m.saveDashboardTeamPlanSnapshot
);

export { DashboardTeamPlanSnapshot } from './DashboardTeamPlanSnapshot';
