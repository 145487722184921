import { getPropsWithCompliantTitleId } from './appIdUtils';
import type { LogModules } from './enum';
import { type LogLevel } from './enum';
import type { PropertiesType } from './types/PropertiesType';
import { logCoreUsage } from 'owa-analytics';

/**
 * @function
 * To log Error messages
 */
export const logError = (
    logModule: LogModules,
    logMessage: string,
    _appId: string,
    correlationId: string,
    properties?: PropertiesType
): void => {
    let propsWithCompliantTitleId: PropertiesType = {};
    if (properties) {
        propsWithCompliantTitleId = getPropsWithCompliantTitleId(properties);
    }
    logCoreUsage('message_extension_logs', {
        LogLevel: 'Error',
        LogModule: logModule,
        LogMessage: logMessage,
        CorrelationId: correlationId ? correlationId : '',
        ...propsWithCompliantTitleId,
    });
};

/**
 * @function
 * To log Warnings
 */
export const logWarning = (
    logModule: LogModules,
    logMessage: string,
    _appId: string,
    correlationId: string,
    properties?: PropertiesType
): void => {
    let propsWithCompliantTitleId: PropertiesType = {};
    if (properties) {
        propsWithCompliantTitleId = getPropsWithCompliantTitleId(properties);
    }
    logCoreUsage('message_extension_logs', {
        LogLevel: 'Warning',
        LogModule: logModule,
        LogMessage: logMessage,
        CorrelationId: correlationId ? correlationId : '',
        ...propsWithCompliantTitleId,
    });
};

/**
 * @function
 * To log Information
 */
export const logInfo = (
    logModule: LogModules,
    logMessage: string,
    _appId: string,
    correlationId: string,
    properties?: PropertiesType
): void => {
    let propsWithCompliantTitleId: PropertiesType = {};
    if (properties) {
        propsWithCompliantTitleId = getPropsWithCompliantTitleId(properties);
    }
    logCoreUsage('message_extension_logs', {
        LogLevel: 'Info',
        LogModule: logModule,
        LogMessage: logMessage,
        CorrelationId: correlationId ? correlationId : '',
        ...propsWithCompliantTitleId,
    });
};

/**
 * @function
 * To log user actions
 */
export const logAction = (
    logModule: LogModules,
    action: string,
    _appId: string,
    correlationId: string,
    properties?: PropertiesType
): void => {
    let propsWithCompliantTitleId: PropertiesType = {};
    if (properties) {
        propsWithCompliantTitleId = getPropsWithCompliantTitleId(properties);
    }
    logCoreUsage('message_extension_actions', {
        LogModule: logModule,
        Action: action,
        AppId: '',
        CorrelationId: correlationId ? correlationId : '',
        ...propsWithCompliantTitleId,
    });
};
