import { DialogProvider } from 'accelerator-dialog/lib/DialogProvider';
import { AppRouterProvider } from 'accelerator-router';
import { PlacesFluentProvider } from 'hybridspace-common/lib/PlacesFluentProvider';
import { ErrorBoundaryLogged, logAppBoot } from 'hybridspace-telemetry';
import { UnifiedTelemetryActionDomain } from 'owa-analytics-generic-action';
import microsoftPlacesLogo from 'owa-appbar-icons/lib/microsoftPlaces.svg';
import loc from 'owa-localize';
import strings from 'owa-locstrings/lib/strings/appMicrosoftPlaces.locstring.json';
import { observer } from 'owa-mobx-react';
import { NotificationBarHost } from 'owa-notification-bar';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import { useBodyMinWidth } from 'owa-react-hooks/lib/useBodyMinWidth';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { useDocumentIcon } from 'owa-react-hooks/lib/useDocumentIcon';
import { useDocumentTitle } from 'owa-react-hooks/lib/useDocumentTitle';
import { type UTActionDomain } from 'owa-unified-telemetry';
import usePlacesGlobalHotkey from 'places-hotkeys/lib/usePlacesGlobalHotkey';
import { isAppInCiriticalState } from 'places-user-session-stores';
import React from 'react';
import { getLoadedPageToLog } from '../utils/getLoadedPageToLog';
import { HybridspaceLoadingScreen } from './HybridspaceLoadingScreen';
import { mainModuleStyle } from './HybridspaceMainModule.scss';
import PlacesPermissionValidation from './PlacesPermissionValidation';
import { createPlacesRoutes } from './PlacesRouter/createPlacesRoutes';

import type { ErrorBoundaryLoggedData } from 'hybridspace-telemetry';

const mainModule: React.CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
};
// Fallback element so user does not see a blank screen while router does its work
const fallbackElement = <HybridspaceLoadingScreen />;

export default observer(function HybridspaceMainModule() {
    useBodyMinWidth('unset');
    useDocumentTitle(loc(strings.appMicrosoftPlaces));
    useDocumentIcon(microsoftPlacesLogo, 'image/svg+xml');

    // Request from MetaOs will have query strings: &ep=metaOS and &hostApp=xxxx (Teams, Monarch, etc)
    const hostApp = getQueryStringParameter('hostApp');
    usePlacesGlobalHotkey();

    const routes = useComputedValue(createPlacesRoutes, []);

    const logAppBootCallback = React.useCallback(
        (data: ErrorBoundaryLoggedData) => {
            const loadedPage = getLoadedPageToLog(routes);
            logAppBoot(loadedPage, data.ActionSuccess, data.ActionFailureInfo);
        },
        [routes]
    );

    return (
        <ErrorBoundaryLogged
            parentName={'MainModule'}
            runLogCallback={logAppBootCallback}
            // we want to reset and throw after catch to let OWA handle the exception
            resetAndThrowAfterCatch={true}
        >
            <UnifiedTelemetryActionDomain name={'Places'}>
                <PlacesFluentProvider style={mainModule} className={mainModuleStyle}>
                    <PlacesPermissionValidation hostApp={hostApp}>
                        {/* TODO: review 2nd loading screen */}
                        {!hasQueryStringParameter('devRmvMmLoadingScreen') &&
                            isAppInCiriticalState() && <HybridspaceLoadingScreen />}
                        <AppRouterProvider
                            basename="/places"
                            routes={routes}
                            fallbackElement={fallbackElement}
                        />
                        <NotificationBarHost hostId={'HybridspaceNotificationBarHost'} />
                        <NotificationBarHost hostId={'AddisonNotificationBarHost'} />
                    </PlacesPermissionValidation>
                    <DialogProvider />
                </PlacesFluentProvider>
            </UnifiedTelemetryActionDomain>
        </ErrorBoundaryLogged>
    );
}, 'HybridspaceMainModule');
