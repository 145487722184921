import { registerDiagnosticCallback } from 'accelerator-common/lib/registerDiagnosticCallback';
import { CopilotHubMainModule } from 'copilot-hub-main-module';
import { HybridspaceMainModule } from 'hybridspace-main-module';
import controlIconRegistration from 'owa-control-icons/lib/data/controlIconsRegistration';
import { getEnabledFlightsWithPrefix } from 'owa-feature-flags';
import { PLACES_APP_NAME } from 'places-common-strings';
import { type OwaWorkload } from 'owa-workloads';
import { lazySetupPlacesPostRender } from 'places-post-boot';
import React from 'react';
import { initializeState } from './initializeState';
import { getApplicationSettings } from 'owa-application-settings';

import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { BootstrapOptions } from 'owa-bootstrap';

export default function getBootstrapOptions(
    sessionPromise: Promise<SessionData | undefined>
): BootstrapOptions & {
    // This was added to enforce the BootstrapOptions.renderMainComponent type for owa-start type.
    renderMainComponent: () => JSX.Element;
} {
    return {
        initializeState,
        renderMainComponent: () =>
            showCopilotHub() ? <CopilotHubMainModule /> : <HybridspaceMainModule />,
        getModuleName: () => PLACES_APP_NAME,
        iconFonts: [controlIconRegistration],
        sessionPromise,
        workload: 16777216,
        swConfig: {
            app: 'places',
            expectedXAppNameHeader: 'Places',
        },
        postLazyAction: lazySetupPlacesPostRender,
    };
}

registerDiagnosticCallback(() => ({
    key: 'Flights',
    value: JSON.stringify(
        getEnabledFlightsWithPrefix('msplaces').concat(getEnabledFlightsWithPrefix('places'))
    ),
}));

function showCopilotHub() {
    return (
        getApplicationSettings('PrepareModule').enabled &&
        (window.location.pathname.indexOf('/places/copilothub') == 0 ||
            window.location.pathname.indexOf('/hosted/places/copilothub') == 0)
    );
}
