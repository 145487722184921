import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import { queryWithError } from 'hybridspace-graphql/lib/utils/queryWithError';
import { addDays, getISOString, startOfDay } from 'owa-datetime';
import { MAX_CALENDAR_VIEW_LIMIT } from '../constants/apiConstants';
import { CalendarViewDocument } from '../graphql/__generated__/CalendarViewQuery.interface';

import type { DateRange } from 'owa-datetime-utils';
export default async function getCalendarView(dateRange: DateRange) {
    const smtpAddress = getUserEmailAddress();

    const { data } = await queryWithError(CalendarViewDocument, {
        variables: {
            input: {
                dateRange: {
                    start: getISOString(startOfDay(dateRange.start)),
                    end: getISOString(addDays(startOfDay(dateRange.end), 1)),
                },
                calendarEntryData: {
                    calendarId: {
                        id: 'HybridspaceCalendarView', // this var is not required on the server side, but it needs to be non-nullable
                        mailboxInfo: {
                            mailboxSmtpAddress: smtpAddress,
                            userIdentity: smtpAddress,
                        },
                    },
                },
                maxResults: MAX_CALENDAR_VIEW_LIMIT,
            },
        },
    });

    return data;
}
