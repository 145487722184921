import { collaboratorUpdatedAction } from 'hybridspace-common/lib/shared-actions';
import { orchestrator } from 'satcheljs';
import {
    getSavedCollaborators,
    getSuggestedCollaborators,
    setSavedCollaborators,
    setSuggestedCollaborators,
} from './store';
import { setCollaboratorsInCache } from './selectors/setCollaboratorsInCache';

// when an update to a collaborator is made, we update the store & schedules
orchestrator(collaboratorUpdatedAction, ({ actionType, collaborator }) => {
    // Don't mutate the original observable values
    const unobservedCollaborator = { ...collaborator };
    const email = unobservedCollaborator.primarySmtpAddress;
    const savedCollaborators = [...getSavedCollaborators()];
    const suggestedCollaborators = [...getSuggestedCollaborators()];

    // whether they are added or removed we need to remove from suggested
    const index = suggestedCollaborators.findIndex(c => c.primarySmtpAddress === email);
    if (index > -1) {
        suggestedCollaborators.splice(index, 1);
    }

    if (actionType === 'added') {
        // If they already exist in savedCollaborators, we do not need to add them again
        if (savedCollaborators.some(c => c.primarySmtpAddress === email)) {
            return;
        }
        unobservedCollaborator.isSaved = true;
        savedCollaborators.push(unobservedCollaborator);
    } else if (actionType === 'removed') {
        unobservedCollaborator.isSaved = false;
        const removedIdex = savedCollaborators.findIndex(c => c.primarySmtpAddress === email);
        if (removedIdex > -1) {
            savedCollaborators.splice(removedIdex, 1);
        }
        // IF the original collaborator was suggested, we need to add them back
        const isSuggested = unobservedCollaborator.source === 'Suggested';
        if (isSuggested) {
            suggestedCollaborators.push(unobservedCollaborator);
        }
    }

    // update store and cache
    setSavedCollaborators(savedCollaborators);
    setCollaboratorsInCache(
        savedCollaborators.map(s => s.primarySmtpAddress),
        'Saved'
    );
    setSuggestedCollaborators(suggestedCollaborators);
    setCollaboratorsInCache(
        suggestedCollaborators.map(s => s.primarySmtpAddress),
        'Suggested'
    );
});
