import { getUPSBaseUrl, setUPSBaseUrl } from '../../urls/UPSBaseUrl';

import type { ApiEndpoints } from '../../urls/PresenceEndpoints';

export const makeRequestWithRedirectHandling = async (
    apiEndpoint: ApiEndpoints,
    options: RequestInit
): Promise<Response> => {
    const url = buildUrl(apiEndpoint);
    const response = await fetch(url, options);

    // HTTP Status Codes from UPS https://test.presence.services.sfb.trafficmanager.net/swagger/ui/index#!/Presence/Presence_GetPresenceByMri
    // 301 - Redirect user to another region
    // 400 - Mri batch size is invalid
    // 401 - Invalid user region (we also get this if token is invalid)
    // 404 - User not found - or override behavior for server redirect

    // Handle 404 when location is set (which means it is overriding 301 server redirect)
    // by using the full redirect location (including params) to fetch again
    // but then caching only the host for future requests (without params)
    if (response.status == 404) {
        const redirectLocation: string | null = response.headers.get('location');
        if (redirectLocation) {
            const baseUrl = redirectLocation.substring(0, redirectLocation.indexOf(apiEndpoint));
            setUPSBaseUrl(baseUrl);
            return makeRequestWithRedirectHandling(apiEndpoint, options);
        }
    }
    return response;
};

const buildUrl = (endpoint: ApiEndpoints): string => {
    return getUPSBaseUrl() + endpoint;
};
