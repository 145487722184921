import { query } from 'owa-apollo';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { PlacesSettingsDocument } from './__generated__/queryPlacesSettings.interface';

import type { MailboxInfo } from 'owa-client-types';

export default async function queryPlacesSettings(
    mailboxInfo: MailboxInfo,
    names: string[],
    shouldUseMainThread?: boolean
) {
    try {
        const userSettings = getAccountScopeUserSettings(mailboxInfo);
        return await query(PlacesSettingsDocument, {
            variables: {
                mailboxId: `${userSettings?.SessionSettings?.ExternalDirectoryUserGuid}@${userSettings?.SessionSettings?.ExternalDirectoryTenantGuid}`,
                names,
            },
            context: {
                requestOptions: {
                    mailboxInfo,
                },
                gatewayGraphRequest: !!shouldUseMainThread, // When true, send graphql request via main thread.
            },
        });
    } catch (error) {
        return { data: undefined, error };
    }
}
