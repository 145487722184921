import { placesExploreDiagnosticsStore } from './store';

export const getDevPageState = () => {
    return placesExploreDiagnosticsStore.devPageState;
};

export const getDevErrorPageState = () => {
    return placesExploreDiagnosticsStore.devErrorPageState;
};

export const getDevComponentState = () => {
    return placesExploreDiagnosticsStore.devComponentState;
};
