import type { Place, AppDefinition } from 'owa-graph-schema';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getAppDefinition } from 'owa-message-extension-common/lib/utils/getAppDefinition';
import { getGuid } from 'owa-guid';
import type { MailboxInfo } from 'owa-client-types';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';

export default async function getBuildingMosAppsDefinitions(
    mailboxInfo: MailboxInfo,
    building: Place
): Promise<AppDefinition[]> {
    const appsResourceLinks = building.resourceLinks?.filter(
        resourceLink => resourceLink.type.valueOf() === 'MetaosApp'
    );
    const mosAppsEnabled = isFeatureEnabled('msplaces-services-mos-apps-enabled');
    if (!mosAppsEnabled || !appsResourceLinks || appsResourceLinks.length == 0) {
        return [];
    }

    const perfDatapoint = new PerformanceDatapoint('getBuildingMosAppsDefinitions');

    try {
        const buildingServiceAppsPromises = await Promise.allSettled(
            appsResourceLinks.map(resourceLink => {
                // getAppDefinition triggers only one call to API acquisitions/get,
                // to get list of all the MOS Apps that are deployed.
                // AppIds for apps are expected to be found in the cached response from 1st call,
                // except some Apps that are not deployed/installed, using the admin instructions, that's a valid scenario.
                const appDefinition = getAppDefinition(
                    resourceLink.value /* appId */,
                    getGuid() /* correlationId */,
                    mailboxInfo /* getModuleContextMailboxInfo() */
                );

                return appDefinition;
            })
        );

        const successAppId: string[] = [];
        const notFoundAppId: string[] = [];
        const getAppDefinitionsSuccessful = buildingServiceAppsPromises.map(
            (promiseResult, index) => {
                // Check iif the  getAppDefinition call succeeded but the appId is not in the response
                // a valid scenario when admin didn't deploy the app using MAC.
                if (promiseResult.status === 'fulfilled' && promiseResult.value) {
                    successAppId.push(appsResourceLinks[index].value);
                    return promiseResult.value; // AppDefinition
                } else {
                    notFoundAppId.push(appsResourceLinks[index].value);
                    return null;
                }
            }
        );

        perfDatapoint.addCustomData({
            successAppId: successAppId.join(','),
            notFoundAppId: notFoundAppId.join(','),
        });

        if (notFoundAppId.length == 0) {
            perfDatapoint.end();
        } else {
            // In case same of the apps not found in the API acquisitions/get response,
            // log the error for telemetry.
            perfDatapoint.endWithError(
                DatapointStatus.UserError,
                new Error('Some apps not found in the API response')
            );
        }

        return getAppDefinitionsSuccessful.filter(app => app !== null);
    } catch (e) {
        // Log the acquisitions/get API server error for telemetry, this is the only server error.
        perfDatapoint.endWithError(DatapointStatus.ServerError, e);
        // Return empty array in case of API error
        return [];
    }
}
