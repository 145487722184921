/**
 * Calendar cloud setting source types
 */
export enum CalendarCloudSettingSourceType {
    TenantDefault = 'TenantDefault',
    TenantForced = 'TenantForced',
    UserOverride = 'UserOverride',
    SystemDefault = 'SystemDefault',
    GroupDefault = 'GroupDefault',
    Unknown = 'Unknown',
}
