import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { ExternalDirectoryIdWithPrefix } from 'accelerator-aad/lib/ExternalDirectoryIdWithPrefix';
import type { LocationDayInfo } from 'hybridspace-shared-types';

export type DashboardTeamPlanSnapshot = {
    collaboratorId: ExternalDirectoryIdWithPrefix;
    collaboratorSmtpAddress: UserEmailAddress;
    locationDayInfo: LocationDayInfo[];
}[];

export const DashboardTeamPlanSchema = {
    dashboardTeamPlan: 'ownerId',
};

export const DashboardTeamPlanTableName = 'dashboardTeamPlan';
