import type { Place } from 'owa-graph-schema';
import { getWorkLocationInfo, getRecentBuildings } from '../store';
import { getPlaces } from 'places-place-store';

/**
 * If we have the building Id recent buildings and it exist in the places map, return the place object that
 * corresponds with the building Id - otherwise return undefined
 * If we don't have places, there is case we have not gotten the places yet, so we check the user's work location
 * If nothing is there, we return undefined
 */
export function getCurrentPlace(): Place | undefined {
    const places = getPlaces();
    const recentBuildings = getRecentBuildings();
    const id = recentBuildings && recentBuildings.length > 0 ? recentBuildings[0] : undefined;
    const userWorkLocation = getWorkLocationInfo()?.place;

    if (places && id) {
        return places.get(id);
    } else if (id && userWorkLocation?.id === id) {
        return userWorkLocation;
    } else {
        return undefined;
    }
}
