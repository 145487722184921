import { getTenantHasBuildings } from 'places-place-store';
import { loadPlacesAsync } from 'places-explore-operations';

export async function getTenantBuildingsExist() {
    const tenantHasPlaces = getTenantHasBuildings();
    if (tenantHasPlaces === undefined) {
        await loadPlacesAsync();
    }
    return getTenantHasBuildings();
}
