//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RecurringFlexibleWorkingHoursFragmentFragment = {
    __typename?: 'FlexibleWorkingHours';
    id: string;
    workLocationType: Types.WorkLocationType;
    start: {
        __typename?: 'ExtendedDateTime';
        dateTime: string;
        timeZone: {
            __typename?: 'TimeZoneWH';
            name: string;
        };
    };
    end: {
        __typename?: 'ExtendedDateTime';
        dateTime: string;
        timeZone: {
            __typename?: 'TimeZoneWH';
            name: string;
        };
    };
    recurrenceDetailsWH?: {
        __typename?: 'RecurrenceDetailsWH';
        recurrence: {
            __typename?: 'PatternedRecurrenceWH';
            recurrencePattern?: {
                __typename?: 'RecurrencePatternWH';
                weeklyRecurrencePattern: {
                    __typename?: 'WeeklyRecurrencePatternWH';
                    interval: number;
                    firstDayOfWeek: Types.DayOfWeek;
                    daysOfWeek: Array<Types.DayOfWeek>;
                };
            } | null;
            recurrenceRange?: {
                __typename?: 'RecurrenceRangeWH';
                endDateRecurrenceRange?: {
                    __typename?: 'EndDateRecurrenceRangeWH';
                    start?: {
                        __typename?: 'ExtendedDateTime';
                        dateTime: string;
                        timeZone: {
                            __typename?: 'TimeZoneWH';
                            name: string;
                        };
                    } | null;
                    end?: {
                        __typename?: 'ExtendedDateTime';
                        dateTime: string;
                        timeZone: {
                            __typename?: 'TimeZoneWH';
                            name: string;
                        };
                    } | null;
                } | null;
                noEndRecurrenceRange?: {
                    __typename?: 'NoEndRecurrenceRangeWH';
                    start?: {
                        __typename?: 'ExtendedDateTime';
                        dateTime: string;
                        timeZone: {
                            __typename?: 'TimeZoneWH';
                            name: string;
                        };
                    } | null;
                } | null;
            } | null;
        };
        timeZone: {
            __typename?: 'TimeZoneWH';
            name: string;
        };
    } | null;
    workLocationDetails?: {
        __typename?: 'Location';
        id?: string | null;
        idType?: Types.LocationIdType | null;
        displayName?: string | null;
        annotation?: string | null;
        locationType?: Types.LocationEntityType | null;
        locationUri?: string | null;
        locationSource?: Types.LocationSourceType | null;
    } | null;
};
export const RecurringFlexibleWorkingHoursFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RecurringFlexibleWorkingHoursFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'FlexibleWorkingHours' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeZone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeZone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'workLocationType' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurrenceDetailsWH' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'recurrence' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'recurrencePattern' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'weeklyRecurrencePattern',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'interval',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'firstDayOfWeek',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'daysOfWeek',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'recurrenceRange' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'endDateRecurrenceRange',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'start',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'dateTime',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'timeZone',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'end',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'dateTime',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'timeZone',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'noEndRecurrenceRange',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'start',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'dateTime',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'timeZone',
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections:
                                                                                            [
                                                                                                {
                                                                                                    kind: 'Field',
                                                                                                    name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeZone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workLocationDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'idType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'annotation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locationType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locationUri' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locationSource' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RecurringFlexibleWorkingHoursFragmentFragment, unknown>;
