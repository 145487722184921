import type { FlexibleWorkingHoursView, FlexibleWorkingHours } from 'owa-graph-schema';
import type { OwaDate } from 'owa-datetime';
import type { CurrentSegmentInfo } from '../types/CurrentSegmentInfo';
import { isWithinRange, startOfDay } from 'owa-datetime';
import { getOwaDateFromExtendedDateTime } from 'owa-datetime-utils';
import { observableNow } from 'owa-observable-datetime';

import getCurrentSegmentWorkLocationDetails from './getCurrentSegmentWorkLocationDetails';

/**
 * Grabs the current segment in today's FWH
 * Will try to return:
 * 1. current segment
 * -> if none, return the next segment
 * -> if none, will return the last segment that occurred
 */
export default function getCurrentSegmentLocation(
    employeeFWH: FlexibleWorkingHoursView[] | FlexibleWorkingHours[],
    date: OwaDate
): CurrentSegmentInfo {
    const currentTime = observableNow();
    /*Check for the information if is present or future*/
    let newSegmentLocation = employeeFWH.find(segment => {
        const startDateTime = getOwaDateFromExtendedDateTime(segment.start);
        const endDateTime = getOwaDateFromExtendedDateTime(segment.end);
        return isWithinRange(currentTime, startDateTime, endDateTime);
    });

    if (newSegmentLocation?.workLocationType) {
        return {
            locationDetails: getCurrentSegmentWorkLocationDetails(newSegmentLocation),
            workLocation: newSegmentLocation.workLocationType,
            id: newSegmentLocation.id,
        };
    }
    /*Check if is in the past, date is today's date without the time*/
    newSegmentLocation = employeeFWH.find(segment => {
        const startDateTime = getOwaDateFromExtendedDateTime(segment.start);
        const endDateTime = getOwaDateFromExtendedDateTime(segment.end);
        return isWithinRange(date, startOfDay(startDateTime), endDateTime);
    });
    if (newSegmentLocation?.workLocationType) {
        return {
            locationDetails: getCurrentSegmentWorkLocationDetails(newSegmentLocation),
            workLocation: newSegmentLocation.workLocationType,
            id: newSegmentLocation.id,
        };
    }
    return {
        locationDetails: null,
        workLocation: 'Unspecified',
        id: null,
    };
}
