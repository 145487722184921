import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { addDays, startOfWeek, subWeeks } from 'owa-datetime';
import type { OwaDate } from 'owa-datetime';

import { createDateRange } from 'owa-datetime-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { loadAllPeopleInsights } from 'places-people-insights-operations';
import { orchestrator } from 'satcheljs';
import { getCurrentDate } from '../store';
import { getUserConfiguration } from 'owa-session-store';

/**
 * Load buildings visited in the past two weeks, starting from the beginning of the current week.
 * For example, if the user's week starts on Monday and today is Wednesday,
 * the range will be from Monday two weeks ago to the most recent Monday.
 * We then collect all buildings visited during this period by checking their schedules.
 */
orchestrator(collaboratorsFetchedAction, ({ collaborators, category }) => {
    if (category === 'collaborator' && isFeatureEnabled('msplaces-explore-people-rare-visits')) {
        const currentDate = getCurrentDate();
        // Get start of week, and subtract one day
        const firstDayOfWeek = addDays(getStartOfWeek(currentDate), -1);
        // Set the past date to two weeks before the start of the week
        const pastDateStart = subWeeks(firstDayOfWeek, 2); // Go back 14 days from the start of week
        // Create the date range from pastDateStart, for 14 days (2 weeks span)
        const dateRange = createDateRange(pastDateStart, 14);
        loadAllPeopleInsights(dateRange, collaborators);
    }
});

function getStartOfWeek(date: OwaDate) {
    const userStartOfWeek = getUserConfiguration().UserOptions?.WeekStartDay || 0;
    return startOfWeek(date, userStartOfWeek);
}
