import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';

export const getStore = createStore('hybridspace-isAttendeeRoomState', {
    // Contains the cache of places emailIds and whether it is a room or not
    isAttendeeARoomMap: new ObservableMap<string, boolean>(),
});

export function isAttendeeCached(email: string): boolean {
    return getStore().isAttendeeARoomMap.has(email);
}

export function isAttendeeARoom(email: string): boolean {
    return !!getStore().isAttendeeARoomMap.get(email);
}
