import { logGreyError, logUsage } from 'owa-analytics';
import { isRunningInMetaOSHub } from 'owa-config';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import { getNewMetaOSDeeplinkUrl } from './getNewMetaOSDeeplinkUrl';

export async function handleMetaOSDeepLinkRedirection() {
    if (!isRunningInMetaOSHub()) {
        return;
    }
    try {
        const devSubPageId =
            hasQueryStringParameter('devSubPageId') && isFeatureEnabled('fwk-devTools')
                ? decodeURIComponent(getQueryStringParameter('devSubPageId'))
                : null;
        const context = await lazyGetContext.importAndExecute();
        const newUrl = getNewMetaOSDeeplinkUrl(devSubPageId || context?.page.subPageId || '');
        if (newUrl) {
            logUsage('PlacesMetaOsDeepLinkRedirect', { contentUrl: scrubForPii(newUrl) });
            history.replaceState(
                history.state,
                '' /** unused (exists for browser historical reasons. check https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState ) */,
                newUrl
            );
        }
    } catch (e) {
        logGreyError('[MetaOSDeeplinkRedirect] Failed to get deeplink subpath', e);
    }
}
