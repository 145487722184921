import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import { SkipLinkRegion } from 'owa-skip-link/lib/components/SkipLinkRegion';
import React from 'react';
import { Outlet, useNavigation, useRevalidator } from 'react-router-dom';

import { mergeClasses, Spinner } from '@fluentui/react-components';

import { placesMainContent, placesSkipToMainContent } from './PlacesRootRouteLayout.locstring.json';
import { loadingBackground, outletContainer, spinner } from './PlacesRootRouteOutletContainer.scss';
import { PlacesTimeZoneDialog, isUserTimeZoneSet } from 'places-timezone-helpers';
import { AccNetworkLostToast } from 'accelerator-common/lib/AccNetworkLostToast';

export function PlacesRootRouteOutletContainer() {
    const navigation = useNavigation();
    const revalidator = useRevalidator();
    const isLoading = navigation.state === 'loading' || revalidator.state != 'idle';

    // We show the time zone dialog if the user has not set the time zone and the feature flight is enabled
    // or if the dev feature flag is enabled.
    const showSetTimeZone =
        (!isUserTimeZoneSet() && isFeatureEnabled('msplaces-show-timeZone-dialog')) ||
        isFeatureEnabled('msplaces-dev-show-timeZone-dialog');

    return (
        <>
            <AccNetworkLostToast />
            <SkipLinkRegion
                role="main"
                skipLinkName={loc(placesSkipToMainContent)}
                regionName={loc(placesMainContent)}
                className={mergeClasses(isLoading && loadingBackground, outletContainer)}
            >
                <Outlet />
            </SkipLinkRegion>
            {showSetTimeZone && <PlacesTimeZoneDialog />}
            {isLoading && (
                <div className={spinner}>
                    <Spinner appearance="primary" />
                </div>
            )}
        </>
    );
}
