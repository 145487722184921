import { isGuid } from 'owa-guid';
import { getItem, removeItem } from 'owa-local-storage';
import { setRecentBuildings } from '../store';
import { getBuildingCacheKey } from './getBuildingCacheKey';

export function setRecentBuildingsFromCache() {
    //retrieve the recent buildings from the cache
    const recentBuildingsFromCache = getItem(window, getBuildingCacheKey());
    if (recentBuildingsFromCache) {
        //deserialize the map and update the store
        try {
            const deserializedArray = JSON.parse(recentBuildingsFromCache);
            if (
                Array.isArray(deserializedArray) &&
                deserializedArray.every(item => typeof item === 'string' && isGuid(item)) //Check id is a string and GUID format
            ) {
                const recentBuildingsArray: string[] = deserializedArray;

                // update the store
                setRecentBuildings(recentBuildingsArray);
            }
        } catch (e) {
            // if the cache is corrupted, clear the cache and return
            removeItem(window, getBuildingCacheKey());
            return;
        }
    }
}
