import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';

import { buildingsTable } from './database';
import type { Place } from 'owa-graph-schema';
import { GetPlacesDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getPlacesQuery.interface';
import { writeQuery } from 'owa-apollo';
import { today, subWeeks, getTimestamp } from 'owa-datetime';
import { logGreyError } from 'owa-analytics';

export async function writePlaceCacheToApollo() {
    const ownerId = getUserExternalDirectoryId();

    try {
        const buildingData = await buildingsTable
            .where({
                owner: ownerId,
            })
            .toArray();

        const sevenDaysAgo = getTimestamp(subWeeks(today(), 1));

        if (buildingData.length > 0) {
            buildingData.map(async building => {
                const place = JSON.parse(building.building);
                const valid = validateValidPlace(place); // make sure required fields are present
                if (!valid) {
                    return;
                }

                // Check if the timestamp is more than 7 days old
                if (building.timestamp && building.timestamp < sevenDaysAgo) {
                    buildingsTable.delete(building.id); // Delete outdated cache entry
                    return;
                }

                const querystring = `Id = "${place.id}"`;
                writeQuery(GetPlacesDocument, {
                    variables: { placesRequest: { query: querystring } },
                    data: { places: { places: [place as Place] } },
                });
            });
        }
    } catch (e) {
        logGreyError('WritePlaceCacheToApollo', e);
    }
}

function validateValidPlace(building: any): boolean {
    const id = building.id;
    const name = building.name;
    const type = building.type;
    return !!id && !!name && !!type;
}
