import React from 'react';
import { useCollaboratorSchedule } from './selectors/useCollaboratorSchedule';
import { useCollaboratorsFromCache } from './selectors/useCollaboratorsFromCache';
import { usePeopleFromCacheForDateRange } from './selectors/usePeopleFromCacheForDateRange';
import { usePeopleFromCacheForDateRangeCount } from './selectors/usePeopleFromCacheForDateRangeCount';
import {
    getCollaboratorScheduleLocations,
    getCollaboratorsOfficeLocations,
    getSavedCollaborators,
    getServiceCallsCompleted,
    getSuggestedCollaborators,
} from './store';

export type PlacesPeopleStoreContextType = {
    getPeopleScheduleLocations: typeof getCollaboratorScheduleLocations;
    getPeopleOfficeLocations: typeof getCollaboratorsOfficeLocations;
    getSavedCollaborators: typeof getSavedCollaborators;
    getSuggestedCollaborators: typeof getSuggestedCollaborators;
    getServiceCallsCompleted: typeof getServiceCallsCompleted;
    usePersonSchedule: typeof useCollaboratorSchedule;
    usePeopleFromCache: typeof useCollaboratorsFromCache;
    usePeopleFromCacheForDateRange: typeof usePeopleFromCacheForDateRange;
    usePeopleFromCacheForDateRangeCount: typeof usePeopleFromCacheForDateRangeCount;
    shouldUseCollaboratorsOperations: boolean;
};

const defaultContextValue: PlacesPeopleStoreContextType = {
    getPeopleScheduleLocations: getCollaboratorScheduleLocations,
    getPeopleOfficeLocations: getCollaboratorsOfficeLocations,
    getSavedCollaborators,
    getServiceCallsCompleted,
    getSuggestedCollaborators,
    usePersonSchedule: useCollaboratorSchedule,
    usePeopleFromCache: useCollaboratorsFromCache,
    usePeopleFromCacheForDateRange,
    usePeopleFromCacheForDateRangeCount,
    shouldUseCollaboratorsOperations: true,
};

export const PlacesPeopleStoreContext = React.createContext<PlacesPeopleStoreContextType | null>(
    defaultContextValue
);

export const usePlacesPeopleStoreContextProvider = () => {
    const context = React.useContext(PlacesPeopleStoreContext);
    if (context == undefined) {
        return defaultContextValue;
    } else {
        return context;
    }
};
