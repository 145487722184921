import loc from 'owa-localize';
import {
    workgroupsAtLeastTwoAdministratorsErrorMessageNS,
    workgroupsNameErrorMessageNS,
    workgroupsNoDLsAcceptedInRoleWellsNS,
    workgroupsUserHasToBeAdministratorErrorMessageNS,
    workgroupsWarnAboutExternalEmailsInWellNS,
    workgroupsWarnAboutInvalidEmailsInWellNS,
    workgroupsWarnAboutTooManyInRoleInWellNS,
    xUsersCouldNotBeAddedBecauseOfAdministrator,
} from './ComposeForm.locstring.json';

import type WorkGroupsComposeErrors from '../store/schema/WorkGroupsComposeErrors';

export default function initializeWorkGroupsComposeErrors(): WorkGroupsComposeErrors {
    return {
        needsTwoAdministrators: {
            errorStrings: [loc(workgroupsAtLeastTwoAdministratorsErrorMessageNS)],
            hasError: false,
            scrollIntoView: false,
        },
        hasTooManyInRole: {
            errorStrings: [loc(workgroupsWarnAboutTooManyInRoleInWellNS)],
            hasError: false,
            scrollIntoView: false,
        },
        hasDLsInAdministratorRoleWell: {
            errorStrings: [loc(workgroupsNoDLsAcceptedInRoleWellsNS)],
            hasError: false,
            scrollIntoView: false,
        },
        hasInvalidRecipientAdministratorEmail: {
            errorStrings: [loc(workgroupsWarnAboutInvalidEmailsInWellNS)],
            hasError: false,
            scrollIntoView: false,
        },
        hasExternalAdministratorEmail: {
            errorStrings: [loc(workgroupsWarnAboutExternalEmailsInWellNS)],
            hasError: false,
            scrollIntoView: false,
        },
        xUsersCouldNotBeAddedBecauseOfAdministratorAdminsWell: {
            errorStrings: [loc(xUsersCouldNotBeAddedBecauseOfAdministrator, 0)],
            hasError: false,
            scrollIntoView: false,
        },
        xUsersCouldNotBeAddedBecauseOfAdministratorMemberWell: {
            errorStrings: [loc(xUsersCouldNotBeAddedBecauseOfAdministrator, 0)],
            hasError: false,
            scrollIntoView: false,
        },
        hasDLsInMemberRoleWell: {
            errorStrings: [loc(workgroupsNoDLsAcceptedInRoleWellsNS)],
            hasError: false,
            scrollIntoView: false,
        },
        hasInvalidRecipientMemberEmail: {
            errorStrings: [loc(workgroupsWarnAboutInvalidEmailsInWellNS)],
            hasError: false,
            scrollIntoView: false,
        },
        hasExternalMemberEmail: {
            errorStrings: [loc(workgroupsWarnAboutExternalEmailsInWellNS)],
            hasError: false,
            scrollIntoView: false,
        },
        needsName: {
            errorStrings: [loc(workgroupsNameErrorMessageNS)],
            hasError: false,
            scrollIntoView: false,
        },
        needsUserToBeAdministrator: {
            errorStrings: [loc(workgroupsUserHasToBeAdministratorErrorMessageNS)],
            hasError: false,
            scrollIntoView: false,
        },
    };
}
