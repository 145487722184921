import { lazyRegisterAddDiagnosticCallback } from 'owa-diagnostic-report';

export function registerDiagnosticCallback(
    diagnosticCallback: () => {
        key: string;
        value: string;
    }
) {
    lazyRegisterAddDiagnosticCallback
        .import()
        .then(registerAddDiagnosticCallback => registerAddDiagnosticCallback(diagnosticCallback));
}
