import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the ShouldShowWorkingHoursOnSurface cloud setting
 */
export default function getShouldShowWorkingHoursOnSurfaceCloudSetting(
    mailboxInfo: MailboxInfo
): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const shouldShowWorkingHoursOnSurfaceSetting = accountSettings.get(
        CalendarCloudSettingType.ShouldShowWorkingHoursOnSurface.toLowerCase()
    );

    return shouldShowWorkingHoursOnSurfaceSetting != null
        ? !!shouldShowWorkingHoursOnSurfaceSetting.value
        : undefined;
}
