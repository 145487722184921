import { AccErrorPage } from 'accelerator-common/lib/AccErrorPage';
import { getIsDarkTheme } from 'owa-fabric-theme';
import loc, { formatToArray } from 'owa-localize';
import { lazySignout } from 'owa-signout';
import React from 'react';
import { Link } from '@fluentui/react-components';
import PlacesIcon from '../assets/Places.svg';
import {
    hybridspaceErrorAccessDeniedCallToAction,
    hybridspaceErrorAccessDeniedCallToActionHyperlinkText,
    hybridspaceErrorAccessDeniedSignOut,
    hybridspaceErrorAccessDeniedSubtitle,
    hybridspaceErrorAccessDeniedTitle,
} from './AccessDenied.locstring.json';
import { noAccessLabelDarkMode } from './AccessDenied.scss';

const microsoftPlacesLink = 'https://www.microsoft.com/microsoft-places';

export default function AccessDenied({ showSignOut }: { showSignOut: boolean }) {
    const detailMessageElement = React.useMemo(() => {
        return [
            <>
                {loc(hybridspaceErrorAccessDeniedSubtitle)}
                <br />
                <br />
                {formatToArray(
                    loc(hybridspaceErrorAccessDeniedCallToAction),
                    <Link
                        href={microsoftPlacesLink}
                        target="_blank"
                        data-telemetry-id="PlacesAccessDeniedLearnMoreLink"
                        className={getIsDarkTheme() ? noAccessLabelDarkMode : undefined}
                    >
                        {loc(hybridspaceErrorAccessDeniedCallToActionHyperlinkText)}
                    </Link>
                )}
            </>,
        ];
    }, []);
    return (
        <AccErrorPage
            customizedIcon={PlacesIcon}
            mainMessageResourceId={hybridspaceErrorAccessDeniedTitle}
            detailMessageResourceId={hybridspaceErrorAccessDeniedSubtitle}
            detailMessageElement={detailMessageElement}
            clickToActionMessageResourceId={
                showSignOut ? hybridspaceErrorAccessDeniedSignOut : undefined
            }
            onClickToActionClick={showSignOut ? onSignOut : undefined}
        />
    );
}

function onSignOut() {
    lazySignout.importAndExecute(location);
}
