import { placesFwkBootPostStartAction } from 'hybridspace-performance-datapoints';
import { logPlacesEvent } from 'hybridspace-telemetry';
import { bootstrap as appBootstrap } from 'owa-bootstrap/lib/bootstrap';
import { setupErrorHandler } from 'owa-exception-handler-devtools';
import type { UTInvalidTimeZoneReason } from 'owa-unified-telemetry';
import { isUserTimeZoneSet } from 'places-timezone-helpers';
import { setModuleStateSafe } from 'places-user-session-stores';

import getBootstrapOptions from './getBootstrapOptions';

import type { SessionData } from 'owa-service/lib/types/SessionData';

/** Bootstrap Microsoft Places in standalone mode */
export default function bootstrap(sessionPromise: Promise<SessionData | undefined>) {
    return appBootstrap(getBootstrapOptions(sessionPromise)).then(() => {
        setupErrorHandler();
        setModuleStateSafe('postBoot');
        placesFwkBootPostStartAction();

        // Logs to investigate how many users face undefined mailbox timezone
        isUserTimeZoneSet(logInvalidTimeZone);
    });
}

function logInvalidTimeZone(reason: UTInvalidTimeZoneReason): void {
    logPlacesEvent({
        eventName: 'PlacesUserHasInvalidTimeZone',
        data: {
            InValidTimeZoneReason: reason,
        },
    });
}
