import getShortenEventsDurationBy from './getShortenEventsDurationBy';
import { getDefaultShouldShortenEventDurationStartLate } from 'owa-calendar-cloud-settings';
import type { OwaDate } from 'owa-datetime';
import { addMinutes, differenceInMinutes, isBefore } from 'owa-datetime';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Update the event start time when the 'End events early'/'Shorten events duration' setting is on
 * @param originalStart
 * @param originalEnd
 */
export default function getConfiguredEventStartTime(
    originalStart: OwaDate,
    originalEnd: OwaDate,
    mailboxInfo: MailboxInfo
): OwaDate {
    if (!getDefaultShouldShortenEventDurationStartLate(mailboxInfo)) {
        return originalStart;
    }

    const eventDuration = differenceInMinutes(originalEnd, originalStart);
    const shortenEventsDurationBy = getShortenEventsDurationBy(eventDuration, mailboxInfo);
    const updatedStartTime = shortenEventsDurationBy
        ? addMinutes(originalStart, shortenEventsDurationBy)
        : originalStart;

    if (isBefore(updatedStartTime, originalEnd)) {
        return updatedStartTime;
    }

    return originalStart;
}
