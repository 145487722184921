export const DefaultReminderOffsets = {
    // No ReminderOptionType
    NO_REMINDER: -1,

    // At time of event.
    AT_TIME_OF_EVENT: 0,

    // 5 minutes before
    MINUTES_BEFORE_5: 5,

    // 15 minutes before
    MINUTES_BEFORE_15: 15,

    // 30 minutes before
    MINUTES_BEFORE_30: 30,

    // 1 hour before
    HOURS_BEFORE_1: 60,

    // 2 hours before
    HOURS_BEFORE_2: 120,

    // 12 hours before
    HOURS_BEFORE_12: 60 * 12,

    // 1 day before
    DAYS_BEFORE_1: 60 * 24,

    // 1 week before
    DAYS_BEFORE_7: 60 * 24 * 7,

    // Day of event at 9:00 AM
    DAY_OF_EVENT_MIN: -1 * 60 * 9,

    // Day before at 9:00 AM
    DAY_BEFORE_MORNING_MIN: 60 * 15,

    // Day before at 5:00 PM
    DAY_BEFORE_MIN: 60 * 7,

    // 1 week before at 9:00 AM
    WEEK_BEFORE_MIN: 60 * 24 * 7 - 60 * 9,

    // Custom reminder
    CUSTOM_REMINDER_KEY: -2,
};
