import type { MailboxInfo } from 'owa-client-types';
import getEndShortEventsByCloudSetting from '../selectors/getEndShortEventsByCloudSetting';

const DEFAULT_END_SHORT_EVENTS_BY_FALLBACK = 5;

/**
 * Gets the default value for the duration to reduce the short events (less than one hour)
 */
export default function getDefaultEndShortEventsBy(mailboxInfo: MailboxInfo): number {
    const endShortEventsBy = getEndShortEventsByCloudSetting(mailboxInfo);

    return endShortEventsBy != null ? endShortEventsBy : DEFAULT_END_SHORT_EVENTS_BY_FALLBACK;
}
