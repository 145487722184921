import { getGlobalSettingsAccount } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { getApp } from 'owa-config';
import { getGuid } from 'owa-guid';
import { InteractionType, lazyLoginUserMsal, lazySetIsSigninRequired } from 'owa-msaljs';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';

export async function onSignInClick() {
    const correlationId = getGuid();
    logUsage('PlacesMsal-InteractionNeededSignInClick', {
        correlationId,
    });

    const account = getGlobalSettingsAccount();
    const response = await lazyLoginUserMsal.importAndExecute(
        account.sourceType,
        InteractionType.Popup,
        undefined /* msalAccount */,
        account.mailboxInfo,
        undefined /* username */,
        undefined /* resource */,
        undefined /* scope */,
        undefined /* promptValue */,
        undefined /* claims */,
        correlationId
    );

    if (!!response && response.TokenResultCode === 0) {
        logUsage('PlacesMsal-InteractionNeededSignInSucceeded');
        lazySetIsSigninRequired.importAndExecute(false);
    } else {
        logUsage('PlacesErrorBoundaryMsal-InteractionNeededSignInFailure', {
            isResponseAvailable: !!response,
            tokenResultCode: response?.TokenResultCode,
            errorCode: response?.SubErrorCode,
            sourceType: account.sourceType,
            correlationId,
            app: getApp(),
        });
    }
}
