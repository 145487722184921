import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import type RoamingTimezone from '../store/schema/RoamingTimezone';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the RoamingTimezones cloud setting
 */
export default function getRoamingTimezonesCloudSetting(
    mailboxInfo: MailboxInfo
): RoamingTimezone[] | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);

    const roamingTimezonesSetting = accountSettings.get(
        CalendarCloudSettingType.RoamingTimezones.toLowerCase()
    );

    return roamingTimezonesSetting != null
        ? (roamingTimezonesSetting.value as RoamingTimezone[])
        : undefined;
}
