import type { GraphUsersResponse } from '../types/GraphUsersResponse';

let count = 0;
const testPrefix = 'test-mri-';
export async function mockMRIs(smtps: string[]): Promise<GraphUsersResponse[]> {
    return new Promise(resolve => {
        setTimeout(function () {
            const result: GraphUsersResponse[] = [];
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            smtps.forEach(smtp => {
                result.push({ id: `${testPrefix}-id${(count++).toString()}`, mail: smtp });
            });
            resolve(result);
        }, 300);
    });
}
