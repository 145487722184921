import type { MailboxInfo } from 'owa-client-types';
import getEnableOnlineMeetingsByDefaultCloudSetting from '../selectors/getEnableOnlineMeetingsByDefaultCloudSetting';

const ENABLE_ONLINE_MEETINGS_BY_DEFAULT_FALLBACK = false;

/**
 * Gets the default value for the online meetings enabled by default option
 */
export default function getDefaultOnlineMeetingsEnabled(mailboxInfo: MailboxInfo): boolean {
    const enableOnlineMeeting = getEnableOnlineMeetingsByDefaultCloudSetting(mailboxInfo);

    return enableOnlineMeeting != null
        ? enableOnlineMeeting
        : ENABLE_ONLINE_MEETINGS_BY_DEFAULT_FALLBACK;
}
