import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { logGreyError } from 'owa-analytics';
import { getUserTimeZone } from 'owa-session-store';

import { peopleTimezoneSettingsTable } from '../database';

export function addTimezoneSettingsToCache(): void {
    try {
        const ownerId = getUserExternalDirectoryId();
        const userTimeZone = getUserTimeZone();
        const uniqueId = `${ownerId}`;

        const input = {
            uniqueId,
            timezone: userTimeZone,
        };
        peopleTimezoneSettingsTable.put(input);
    } catch (e) {
        logGreyError('PlacesPeopleCache_addTimezoneSettingsToCache', e);
    }
}
