import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the calendar left panel state cloud setting
 * @param mailboxInfo the mailbox info
 * @returns the value for the calendar left panel state cloud setting
 */
export default function getLeftPanelInitStateCloudSetting(
    mailboxInfo: MailboxInfo
): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const leftPanelInitState = accountSettings.get(
        CalendarCloudSettingType.LeftPanelInitState.toLowerCase()
    );

    return leftPanelInitState != null ? !!leftPanelInitState.value : undefined;
}
