import { renderOCVFeedbackPanel } from 'accelerator-ocv';
import { lazyOpenDiagnostics, lazyOpenFeatureFlags } from 'hybridspace-diagnostics';
import { getApplicationSettings } from 'owa-application-settings';
import { getLogicalRing } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import React from 'react';
import { MenuItem } from '@fluentui/react-components';
import {
    bundleIcon,
    EmojiFilled,
    EmojiRegular,
    FlagFilled,
    FlagRegular,
    PersonChatFilled,
    PersonChatRegular,
    QuestionCircleFilled,
    QuestionCircleRegular,
    SettingsFilled,
    SettingsRegular,
    WrenchScrewdriverFilled,
    WrenchScrewdriverRegular,
} from '@fluentui/react-icons';
import renderFeedbackPanel from '../../utils/FeedbackPanel';
import renderHelpPanel from './HelpPane';
import { menuItem } from './MoreMenuListItems.scss';
import {
    diagnosticsButtonMenuLabel,
    featureFlagsButtonMenuLabel,
    feedbackButtonMenuLabel,
    helpButtonMenuLabel,
    settingsButtonMenuLabel,
} from './PlacesMoreDropdown.locstring.json';

const DiagnosticsIcon = bundleIcon(WrenchScrewdriverFilled, WrenchScrewdriverRegular);
const diagnosticsIcon = <DiagnosticsIcon />;
const FeedbackIcon = bundleIcon(EmojiFilled, EmojiRegular);
const feedbackIcon = <FeedbackIcon />;
const FlagIcon = bundleIcon(FlagFilled, FlagRegular);
const flagIcon = <FlagIcon />;
const OCVFeedbackIcon = bundleIcon(PersonChatFilled, PersonChatRegular);
const ocvFeedbackIcon = <OCVFeedbackIcon />;
const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
const settingsIcon = <SettingsIcon />;
const HelpIcon = bundleIcon(QuestionCircleFilled, QuestionCircleRegular);
const helpIcon = <HelpIcon />;

export default observer(function MoreMenuListItems({
    telemetryParentId,
}: {
    telemetryParentId?: string; // for scoping a popover element, the value should be a data-telemetry-id of another element
}) {
    const onClickFeedback = React.useCallback(() => {
        renderFeedbackPanel();
    }, []);

    const showDiagnostics = getApplicationSettings('Diagnostics').panel;

    const isSdfUser = getLogicalRing() === 'Dogfood';
    const showSmileyFeedback = isSdfUser || isFeatureEnabled('msplaces-dev-smileyFeedback');

    const showOCV = !isSdfUser || isFeatureEnabled('msplaces-dev-OCVFeedback');
    const showFlags = isFeatureEnabled('fwk-devTools');

    return (
        <>
            {showOCV && (
                <MenuItem
                    onClick={renderOCVFeedbackPanel}
                    icon={ocvFeedbackIcon}
                    data-telemetry-id="OCVFeedbackMenuItem"
                    data-telemetry-parentid={telemetryParentId}
                    className={menuItem}
                >
                    {loc(feedbackButtonMenuLabel)}
                </MenuItem>
            )}

            {showSmileyFeedback && (
                <MenuItem
                    onClick={onClickFeedback}
                    icon={feedbackIcon}
                    data-telemetry-id="SmileyFeedbackMenuItem"
                    data-telemetry-parentid={telemetryParentId}
                    className={menuItem}
                >
                    {loc(feedbackButtonMenuLabel)}
                </MenuItem>
            )}

            {showDiagnostics && (
                <MenuItem
                    onClick={lazyOpenDiagnostics.importAndExecute}
                    icon={diagnosticsIcon}
                    data-telemetry-id="DiagnosticsMenuItem"
                    data-telemetry-parentid={telemetryParentId}
                    className={menuItem}
                >
                    {loc(diagnosticsButtonMenuLabel)}
                </MenuItem>
            )}

            {showFlags && (
                <MenuItem
                    onClick={lazyOpenFeatureFlags.importAndExecute}
                    icon={flagIcon}
                    data-telemetry-id="RolloutOverridesMenuItem"
                    data-telemetry-parentid={telemetryParentId}
                    className={menuItem}
                >
                    {loc(featureFlagsButtonMenuLabel)}
                </MenuItem>
            )}

            <MenuItem
                onClick={showSettings}
                icon={settingsIcon}
                data-telemetry-id="SettingsMenuItem"
                data-telemetry-parentid={telemetryParentId}
                className={menuItem}
            >
                {loc(settingsButtonMenuLabel)}
            </MenuItem>

            <MenuItem
                onClick={renderHelpPanel}
                icon={helpIcon}
                data-telemetry-id="HelpMenuItem"
                data-telemetry-parentid={telemetryParentId}
                className={menuItem}
            >
                {loc(helpButtonMenuLabel)}
            </MenuItem>
        </>
    );
}, 'MoreMenuListItems');

function showSettings(): void {
    lazyMountAndShowFullOptions.importAndExecute(
        'calendar',
        'workSchedule' /*shows the work hours and location tab in OWA settings*/,
        undefined /*option*/,
        window,
        getModuleContextMailboxInfo()
    );
}
