import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "WorkLocationSurfaceItem" */ './lazyIndex')
);

// Lazy-load components
export const WorkLocationsSurfaceItem = createLazyComponent(
    lazyModule,
    m => m.WorkLocationsSurfaceItem
);

export const lazyGetWorkingHoursViewGraphQL = new LazyAction(
    lazyModule,
    m => m.getWorkingHoursViewGraphQL
);

export const lazyIsWorkLocationControlTypeEnabled = new LazyAction(
    lazyModule,
    m => m.isWorkLocationControlTypeEnabled
);

export const AgendaEventWorkLocationItem = createLazyComponent(
    lazyModule,
    m => m.AgendaEventWorkLocationItem
);
