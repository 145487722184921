import {
    lazyNotifySuccess,
    lazyNotifyAppLoaded,
    lazyRegisterOnLoadHandler,
    lazyRegisterBeforeUnloadHandler,
} from 'owa-metaos-app-bootstrap';
import type { OnTabLoaded, OnTabUnLoad } from 'owa-metaos-app-bootstrap';
import { isRunningInMetaOSHub } from 'owa-config';
import { useEffect } from 'react';

export function useMetaOSAppLoaded(onTabLoadedObj?: OnTabLoaded, onBeforeUnloadObj?: OnTabUnLoad) {
    useEffect(() => {
        // MetaOS APIs are only fired when we're in metaos context. for OWA apps, metaos apps are using /hosted route.
        // hostappfeature is deprecated as mentioned in flagDefaultsDeprecated.ts.
        if (isRunningInMetaOSHub()) {
            lazyNotifyAppLoaded.importAndExecute();
            lazyNotifySuccess.importAndExecute();
            lazyRegisterOnLoadHandler.importAndExecute(onTabLoadedObj);
            lazyRegisterBeforeUnloadHandler.importAndExecute(onBeforeUnloadObj);
        }
    }, [onTabLoadedObj, onBeforeUnloadObj]);
}
