import getDefaultRoamingTimezones from './getDefaultRoamingTimezones';
import type RoamingTimezone from '../store/schema/RoamingTimezone';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Gets the default value for the visible roaming timezones setting
 */
export default function getDefaultVisibleRoamingTimezones(
    mailboxInfo: MailboxInfo
): RoamingTimezone[] {
    return getDefaultRoamingTimezones(mailboxInfo).filter(timezone => timezone.isVisible);
}
