import type { ResourceId } from 'owa-localize';

/**
 * Interface that describes a generic item in Accelerator controls.
 * Individual controls map AccItem to their respective UX elements.
 *
 * NOTE: Do not add arbitrary fields here. For the most part, AccItem
 * should be generic enough to be used by a variety of controls and it
 * should make no assumptions about how the data is rendered.
 */
export interface AccItem {
    /** A key that uniquely identifies this item in a set. */
    key: string;

    /** Name of the SVG icon that represents this item. */
    icon?: string;

    /** Name of the SVG icon that represents this item in RTL. */
    iconRTL?: string;

    /** Resource ID of the text displayed in the label and aria label of this item. */
    textResourceId: ResourceId | (() => ResourceId);

    /** Optional unlocalized string to display in place of Resource ID*/
    unlocalizedString?: string;

    /** Resource ID of the aria-label to show on this item. */
    ariaLabelResourceId?: ResourceId;

    /** Resource ID of the tooltip to show on this item. */
    tooltipResourceId?: ResourceId | (() => ResourceId);

    /** Indicates if the item should be hidden in the UI. */
    hidden?: boolean | (() => boolean | undefined);

    /** Indicates if the item should be disabled in the UI. */
    disabled?: boolean | (() => boolean | undefined);
}

/**
 * Hook to transform non-hidden AccItems into other objects, for use in Accelerator controls.
 * The mapping function receives non-hidden items and the index they will be in the final map (not their original index).
 */
export function useAccItems<T, TItem extends Pick<AccItem, 'hidden'>>(
    items: TItem[],
    map: (item: TItem, index: number) => T | null | undefined
) {
    return items.reduce((a, item) => {
        if (!getValue(item.hidden)) {
            const mappedItem = map(item, a.length);
            if (mappedItem) {
                a.push(mappedItem);
            }
        }
        return a;
    }, [] as T[]);
}

export function getValue<T extends ResourceId | string | boolean | number>(
    value: T | (() => T | undefined) | undefined
): T | undefined {
    return typeof value === 'function' ? value() : value;
}
