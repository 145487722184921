import { DatapointStatus, logUsage, PerformanceDatapoint } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { trace } from 'owa-trace';
import queryPlacesSettings from './queryPlacesSettings';
import shouldFallbackToPlacesEnabledOnly from './shouldFallbackToPlacesEnabledOnly';

import type { MailboxInfo } from 'owa-client-types';
import type { PlacesSettings } from './types/PlacesSettings';
import type { PlacesTenantSettingComplete } from './types/PlacesTenantSettingCompleteCallback';

const defaultPlacesSettings: PlacesSettings = {
    placesWebAppEnabled: false,
    placesPremiumEnabled: false,
    placesCopilotEnabled: false,
    placesFinderEnabled: false,
    placesHybridGuidanceEnabled: false,
};

/**
 * Retrieve settings from the service.
 * For details, see the README file.
 * If you make changes to the flow, update the README diagram.
 */
export default async function fetchPlacesSettings(
    mailboxInfo: MailboxInfo,
    shouldUseMainThread?: boolean,
    onPlacesTenantSettingComplete?: PlacesTenantSettingComplete
): Promise<PlacesSettings> {
    const datapoint = new PerformanceDatapoint('FETCH_PLACES_SETTINGS');

    // Gradually rolling out license check to avoid regression.
    // Once this flight catches up with places-web-app-enabled, this flight can be removed.
    // See https://exp.microsoft.com/a/feature/5f1f2039-76ac-42a2-b884-2712236ede03?workspaceId=272e8f3b-4b43-4bac-9ca8-30f98bb3a3e4&group=/outlookprod/OutlookWeb
    // TODO clean up task: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/309554
    const shouldUsePlacesLicensing = isFeatureEnabled('msplaces-enable-license-check', mailboxInfo);
    datapoint.addCustomData({ isUsingLicense: shouldUsePlacesLicensing });

    try {
        // Block Consumer accounts or business accounts in regions where places-web-app-enabled is not yet true (ex, GCC/DoD/Gallatin).
        // Once places-web-app-enabled graduates to ALL environments, this check can be removed.
        // See https://exp.microsoft.com/a/feature/98ee59f0-e88b-43b6-b908-b898ef39ed00?workspaceId=272e8f3b-4b43-4bac-9ca8-30f98bb3a3e4

        const isBusinessUser = isBusiness(mailboxInfo);
        const isPlacesWebAppEnabled = isFeatureEnabled('places-web-app-enabled', mailboxInfo);
        if (!isBusinessUser || !isPlacesWebAppEnabled) {
            datapoint.addCustomData({
                reason: `isBusinessUser=${isBusinessUser} isPlacesWebAppEnabled=${isPlacesWebAppEnabled}`,
            });
            setPlacesUserSettingUnifiedTelemetryData(datapoint, false /** actionSuccess */);
            onPlacesTenantSettingComplete?.(
                defaultPlacesSettings,
                'Not a business user or places-web-app-enabled is false'
            );
            return defaultPlacesSettings;
        }

        // Gradually rolling out licensing check via feature flag.
        const PlacesLicensingArgs = shouldUsePlacesLicensing ? ['IsPlacesPremiumEnabled'] : [];

        // Query the settings, asking for EnablePlacesWebApp and PlacesEnabled.
        const queryResults = await queryPlacesSettings(
            mailboxInfo,
            [
                'Places.EnablePlacesWebApp',
                'Places.PlacesEnabled',
                'Places.PlacesFinderEnabled',
                'Places.AllowInConnectionsList', // Used as a catchall for hybrid guidance
                'Places.EnableHybridGuidance',
                ...PlacesLicensingArgs,
            ],
            shouldUseMainThread
        );

        // If we failed to fetch EnablePlacesWebApp because we hit an older server and we're allowed to fallback, retry with just PlacesEnabled and EnablePlacesWebApp.
        // TODO clean up task: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/309554
        const fallback = shouldFallbackToPlacesEnabledOnly(queryResults);
        const { data, error } = fallback
            ? (logUsage('FETCH_PLACES_SETTINGS_FALLBACK'),
              await queryPlacesSettings(
                  mailboxInfo,
                  [
                      'Places.PlacesEnabled',
                      'Places.EnablePlacesWebApp',
                      'Places.PlacesFinderEnabled',
                      'Places.AllowInConnectionsList',
                      'Places.EnableHybridGuidance',
                  ],
                  shouldUseMainThread
              ))
            : queryResults;

        // If we truly failed the network call, throw an error, which allows the application to retry.
        if (error) {
            datapoint.addCustomData({ fallback });
            throw error;
        }

        // If we didn't fail the network call but we got no settings, throw an error too.
        // Not sure if a retry would help since this is truly unexpected, but who knows, maybe it could happen due to a server hiccup.
        // NOTE: this check is not explicitly called out in the README diagram, but you can think of it as part of CASE 9 and 10,
        //       as if the check was done in queryPlacesSettings and it would return an error. I left the code in there
        //       where it is easier to check and add the custom data to the datapoint and decided to leave the diagram cleaner.
        //       This is defense in depth, since we don't expect the server to actually do this ever.
        const rawSettings = data?.batchGetEffectiveSettings?.settings;
        if (!rawSettings) {
            datapoint.addCustomData({ noSettings: true });
            throw new Error('No Places Settings data');
        }

        // Map the incoming array to a simple record.
        const settings = rawSettings.reduce((a, setting) => {
            a[setting.key] = !!setting.value.boolValue;
            return a;
        }, {} as Record<string, boolean>);
        datapoint.addCustomData(settings);

        const enablePlacesWebAppSetting = !!settings['Places.EnablePlacesWebApp'];
        const placesEnabled = !!settings['Places.PlacesEnabled']; /** Previous Premium Behavior */
        const isPrivatePreviewCustomer = isFeatureEnabled(
            'msplaces-private-preview-tenants',
            mailboxInfo
        ); // For CASE 11/12
        const privateOverride = isPrivatePreviewCustomer && placesEnabled;

        // Log to identify how many customers are going through private preview code flow
        if (!enablePlacesWebAppSetting && privateOverride) {
            logUsage('FETCH_PLACES_SETTINGS_PP_OVERRIDE');
        }
        const enablePlacesWebApp = enablePlacesWebAppSetting || privateOverride;

        const placesWebAppEnabled = enablePlacesWebApp; /** CASE 3, 4, 8, 11, 12*/
        const placesPremiumEnabled =
            !!settings['IsPlacesPremiumEnabled'] /** CASE 6*/ ||
            (placesEnabled && enablePlacesWebApp); /** CASE 7, 11*/

        const placesFinderEnabled =
            !!settings['Places.PlacesFinderEnabled'] && placesPremiumEnabled;
        const placesHybridGuidanceEnabled =
            !!settings['Places.AllowInConnectionsList'] &&
            !!settings['Places.EnableHybridGuidance'] &&
            placesPremiumEnabled;

        setPlacesUserSettingUnifiedTelemetryData(
            datapoint,
            true /** actionSuccess */,
            placesWebAppEnabled,
            placesPremiumEnabled,
            false /** placesCopilotEnabled */,
            placesFinderEnabled
        );

        const placesSettings = {
            placesWebAppEnabled,
            placesPremiumEnabled,
            placesCopilotEnabled: false, // TODO: update when we know what to do about copilot
            placesFinderEnabled,
            placesHybridGuidanceEnabled,
        };
        onPlacesTenantSettingComplete?.(
            placesSettings,
            undefined /** error */,
            shouldUsePlacesLicensing
        );
        return placesSettings;
    } catch (e) {
        setPlacesUserSettingUnifiedTelemetryData(datapoint, false /** actionSuccess */);
        onPlacesTenantSettingComplete?.(
            {
                placesWebAppEnabled: false,
                placesPremiumEnabled: false,
                placesCopilotEnabled: false,
                placesFinderEnabled: false,
                placesHybridGuidanceEnabled: false,
            },
            e.message,
            shouldUsePlacesLicensing
        );
        datapoint.endWithError(DatapointStatus.ServerError, e);
        trace.info('fetchPlacesSettings failed: ' + e.diagnosticInfo);
        throw e;
    } finally {
        if (!datapoint.hasEnded) {
            datapoint.end();
        }
    }
}

function setPlacesUserSettingUnifiedTelemetryData(
    datapoint: PerformanceDatapoint,
    actionSuccess: boolean,
    placesWebAppEnabled?: boolean,
    placesPremiumEnabled?: boolean,
    placesCopilotEnabled?: boolean,
    placesFinderEnabled?: boolean
) {
    datapoint.addUnifiedTelemetryData({
        eventName: 'PlacesUserSettings',
        data: {
            ActionSuccess: actionSuccess,
            PlacesWebAppEnabled: placesWebAppEnabled,
            PlacesPremiumEnabled: placesPremiumEnabled,
            PlacesCopilotEnabled: placesCopilotEnabled,
            PlacesFinderEnabled: placesFinderEnabled,
        },
    });
}
