import { getDefaultWeekRange } from 'hybridspace-common/lib/context';
import { getUserWeekStartDay } from 'hybridspace-common/lib/utils';
import { setCurrentDate as setCurrentAgendaDate } from 'hybridspace-places-finder-store';
import { asDate, isBefore, today, userDate } from 'owa-datetime';
import { createWeekDateRange } from 'owa-datetime-utils';
import { setCurrentDate, updateCurrentDateRange } from 'places-user-session-stores';
import { getEventsGroupFullChildEventsNextOccurrence } from 'places-work-groups-store';

import type { OwaDate } from 'owa-datetime';
import type { LoaderFunction } from 'react-router-dom';

export const setPeopleDate: LoaderFunction = async ({ params, request }) => {
    const url = new URL(request.url);
    const startDateParam = url.searchParams.get('date');
    const workgroupId = params['workgroupId'];
    const isWorkgroupRoute = !!workgroupId;
    //TODO: add error handling, page status
    const guidanceId = isWorkgroupRoute && url.searchParams.get('guidanceId');

    if (!startDateParam && !guidanceId) {
        return setDatesToDefault(isWorkgroupRoute);
    }

    let date = getDateFromStartDate(startDateParam);

    // if there is no provided startDate OR if the provided startDate is in the past,
    // then we need to get the next occurrence of the guidance event if it exists.
    if (isWorkgroupRoute && guidanceId && (!date || isBefore(date, today()))) {
        date = await getDateFromGuidance(workgroupId, guidanceId);
    }

    // set date and dateRange, but only if the calculation yielded a result.
    if (date) {
        setCurrentDate(date);

        const dateRange = createWeekDateRange(getUserWeekStartDay(), date);
        updateCurrentDateRange(dateRange, !isWorkgroupRoute);

        setCurrentAgendaDate(date);
    } else {
        setDatesToDefault(isWorkgroupRoute);
    }

    return null;
};

function setDatesToDefault(isWorkGroupRoute: boolean) {
    // Workgroups shouldn't have this special handling because they load needed data correctly.
    if (isWorkGroupRoute) {
        return null;
    }

    setCurrentDate(today());

    updateCurrentDateRange(getDefaultWeekRange(), !isWorkGroupRoute);

    setCurrentAgendaDate(today());

    return null;
}

function getDateFromStartDate(startDateParam: string | null) {
    if (!startDateParam) {
        return null;
    }

    try {
        return userDate(startDateParam);
    } catch (e) {
        return null;
    }
}

async function getDateFromGuidance(
    workgroupId: string,
    guidanceId: string
): Promise<OwaDate | null> {
    try {
        const guidances = await getEventsGroupFullChildEventsNextOccurrence(workgroupId);
        const matchingGuidance = guidances?.find(guidance => guidance.master?.id === guidanceId);

        const date = matchingGuidance?.instance.startEndDateTime.start ?? null;
        if (!date) {
            return null;
        }

        // hack because the date is already converted to user timezone but kind is UTC.
        return userDate(asDate(date).toDateString());
    } catch (e) {
        return null;
    }
}
