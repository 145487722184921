import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets Event Set Reminder cloud setting
 */
export default function getEventSetReminderCloudSetting(
    mailboxInfo: MailboxInfo
): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const eventSetReminderSetting = accountSettings.get(
        CalendarCloudSettingType.EventSetReminder.toLowerCase()
    );

    return eventSetReminderSetting != null ? !!eventSetReminderSetting.value : undefined;
}
