import type { Room } from 'owa-room-selection-types';
import type { EnhancedLocation } from 'owa-graph-schema';

// TODO: Remove declined locations from the locations array
export default function addRoomToLocationsArray(
    locations: EnhancedLocation[],
    room: Room
): RestLocation[] {
    const locationsArray = locations?.map(location => {
        const locationType =
            location.LocationType == 'Unknown' ? 'Default' : (location.LocationType as string);

        return {
            DisplayName: location.DisplayName as string,
            LocationType: locationType,
            LocationUri: location.PostalAddress?.LocationUri as string,
            UniqueId: location.Id as string,
            UniqueIdType: location.IdType as string,
            Address: {
                Street: location.PostalAddress?.street as string,
                City: location.PostalAddress?.city as string,
                State: location.PostalAddress?.state as string,
                CountryOrRegion: location.PostalAddress?.country as string,
                PostalCode: location.PostalAddress?.postalCode as string,
            },
            Coordinates: {
                Accuracy: location.PostalAddress?.Accuracy as number,
                Altitude: location.PostalAddress?.Altitude as number,
                AltitudeAccuracy: location.PostalAddress?.AltitudeAccuracy as number,
                Latitude: location.PostalAddress?.Latitude as number,
                Longitude: location.PostalAddress?.Longitude as number,
            },
        } as RestLocation;
    });

    const newRoomLocation = {
        DisplayName: room.name,
        LocationType: 'ConferenceRoom',
        LocationUri: room.email,
    } as RestLocation;

    locationsArray?.push(newRoomLocation);

    return locationsArray;
}

type RestLocation = {
    DisplayName: string;
    LocationType: string;
    LocationUri: string;
    UniqueId: string;
    UniqueIdType: string;
    Address: {
        Street: string;
        City: string;
        State: string;
        CountryOrRegion: string;
        PostalCode: string;
    };
    Coordinates: {
        Accuracy: number;
        Altitude: number;
        AltitudeAccuracy: number;
        Latitude: number;
        Longitude: number;
    };
};
