import { useCallback } from 'react';

export function useOnlineStatusChange(
    callback: (online: boolean) => void,
    targetWindow: Window = window
) {
    const onStatusChanged = useCallback(() => {
        callback(navigator.onLine);
    }, [callback]);
    targetWindow.addEventListener('online', onStatusChanged);
    targetWindow.addEventListener('offline', onStatusChanged);

    return () => {
        targetWindow.removeEventListener('online', onStatusChanged);
        targetWindow.removeEventListener('offline', onStatusChanged);
    };
}
