import getCollaborators from 'hybridspace-common/lib/services/getCollaborators';
import getScheduleWithLocation from 'hybridspace-common/lib/services/getScheduleWithLocation';
import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { getRanking } from 'hybridspace-common/lib/utils';
import { PlacesDashboardLoadE2ECheckmark } from 'hybridspace-performance-datapoints';

import type { DateRange } from 'owa-datetime-utils';
import type { CollaboratorGroup, HybridspaceScheduleInformation } from 'owa-graph-schema';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { Collaborator } from 'hybridspace-common/lib/serviceTypes';

export interface CollaboratorScheduleWithLocation {
    collaborator: Collaborator;
    schedule?: HybridspaceScheduleInformation;
    rank: number;
}

/**
 * Retrieves collaborators and their schedules with location.
 * Rank priority:
 *  - Has workspace
 *  - Rank
 *
 * More ranking levels TBD
 * @param dateRange time window to lookup for collaborators and their schedules
 * @param groupsToInclude will only return collaborators that are in the groups specified
 */
export async function getCollaboratorsWithLocation(
    dateRange: DateRange,
    groupsToInclude?: CollaboratorGroup[]
): Promise<{
    collaboratorScheduleWithLocation: CollaboratorScheduleWithLocation[];
    savedCollaborators: Collaborator[];
    suggestedCollaborators: Collaborator[];
}> {
    try {
        workPlanCheckPoints('CollaboratorStarted');
        const collaborators = await getCollaborators(dateRange, groupsToInclude);
        workPlanCheckPoints('CollaboratorEnded');
        if (!collaborators) {
            throw new Error('Collaborators not found');
        }

        const savedCollaborators: Collaborator[] = [];
        const suggestedCollaborators: Collaborator[] = [];

        collaborators.map(collaborator => {
            if (collaborator.source === 'Saved') {
                savedCollaborators.push(collaborator);
            } else if (collaborator.source === 'Suggested') {
                suggestedCollaborators.push(collaborator);
            }
        });

        const collaboratorEmails = collaborators
            .map(collaborator => collaborator.primarySmtpAddress)
            .filter(email => email);

        // call to trigger specific calls for UPS and rare insights for now, can be used
        // to trigger calls that are related to the collaborators without needed the schedule
        // information
        collaboratorsFetchedAction(collaboratorEmails as UserEmailAddress[], 'collaborator');

        workPlanCheckPoints('SchedulesStarted');
        const schedules = await getScheduleWithLocation(collaboratorEmails, dateRange);
        workPlanCheckPoints('SchedulesEnded');

        const collaboratorScheduleWithLocation = collaborators.map(collaborator => {
            const schedule = schedules?.find(
                (scheduleOption: HybridspaceScheduleInformation) =>
                    scheduleOption.scheduleId === collaborator.primarySmtpAddress
            );
            return {
                collaborator,
                schedule,
                rank: getRanking(schedule),
            };
        });

        if (!collaboratorScheduleWithLocation) {
            throw new Error('Schedule with location not found');
        }

        return {
            collaboratorScheduleWithLocation,
            savedCollaborators,
            suggestedCollaborators,
        };
    } catch (error) {
        if (error.message === 'Collaborators not found') {
            throw new Error('Collaborators not found in getCollaboratorsWithLocation');
        } else {
            throw new Error('Schedule with location not found in getCollaboratorsWithLocation');
        }
    }
}

/**
 * Checkpoints for the work plan collaborator and schedules
 * 1. CollaboratorStarted - Collaborator data is being fetched
 * 2. CollaboratorEnded - Collaborator data has been fetched
 * 3. SchedulesStarted - Schedules data is being fetched
 * 4. SchedulesEnded - Schedules data has been fetched
 */
function workPlanCheckPoints(
    checkPoint: 'CollaboratorStarted' | 'CollaboratorEnded' | 'SchedulesStarted' | 'SchedulesEnded'
) {
    switch (checkPoint) {
        case 'CollaboratorStarted':
            PlacesDashboardLoadE2ECheckmark('crs');
            break;
        case 'CollaboratorEnded':
            PlacesDashboardLoadE2ECheckmark('cre');
            break;
        case 'SchedulesStarted':
            PlacesDashboardLoadE2ECheckmark('srs');
            break;
        case 'SchedulesEnded':
            PlacesDashboardLoadE2ECheckmark('sre');
            break;
    }
}
