import type { MailboxInfo } from 'owa-client-types';
import getHybridRSVPEnabledSetting from '../selectors/getHybridRSVPEnabledSetting';

const HYBRID_RSVP_ENABLED_FALLBACK = true;

/**
 * Gets the default value for the hybrid rsvp enabled option
 */
export default function getHybridRSVPEnabled(mailboxInfo: MailboxInfo): boolean {
    const hybridRSVPEnabled = getHybridRSVPEnabledSetting(mailboxInfo);
    return hybridRSVPEnabled != null ? hybridRSVPEnabled : HYBRID_RSVP_ENABLED_FALLBACK;
}
