import { getClientVersion, getHostHub } from 'owa-config';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';

import { getUserAgent } from '../../utils/getUserAgent';

export const buildRequestHeader = (
    token: string,
    correlationId: string,
    endpointId: string
): Record<string, string> => {
    const headers: Record<string, string> = {
        Authorization: buildAuthorizationHeader(token),
        BehaviorOverride: 'redirectAs404',
        'x-ms-client-user-agent': getUserAgent(),
        'x-ms-correlation-id': correlationId,
        'x-ms-client-version': getClientVersion(),
        'x-ms-endpoint-id': endpointId,
        'Content-Type': 'application/json',
    };

    const hostHub = getHostHub();
    if (hostHub) {
        headers['x-ms-client-type'] = hostHub;
    }

    return headers;
};
