import type { Place } from 'owa-graph-schema';
import { GetPlacesDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getPlacesQuery.interface';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { query } from 'owa-apollo';
import { isGuid } from 'owa-guid';
import { getDevErrorPageState } from 'places-diagnostics';

import { addPlaceToCache } from '../cache/addPlaceToCache';

/**
 * get the Place for a specific building Id
 */
export default async function getPlace(buildingId: string): Promise<Place> {
    const perfDatapoint = new PerformanceDatapoint('PlacesGetPlace');
    perfDatapoint.addCustomData({ buildingId });

    const querystring = `Id = "${buildingId}"`;

    let placesResponse;

    /** For a tenant w/o buildings have seen some collaborators with FWH that has work location ==
     *  'CustomOption_Office' (OFFICE_OPTION_ID), in that case skip getBuildingPlaceQuery
     * Response will be not found or RoutingNullForKey when tenant hasn't enabled directory
     * FWH bug to avoid setting that value: 278460  */
    if (!isGuid(buildingId)) {
        const error = new Error('Invalid buildingId, should by a GUID but is not');
        const message = `Invalid buildingId, should by a GUID but is: ${buildingId}`;
        perfDatapoint.addCustomData({ message });
        perfDatapoint.endWithError(DatapointStatus.ClientError, error);
        throw error;
    }

    const simulateError = getDevErrorPageState() === 'Place';

    try {
        const { data } = await query(GetPlacesDocument, {
            variables: { placesRequest: { query: querystring } },
            // cache-first fetch policy to avoid unnecessary network calls as building data doesn't change often
            fetchPolicy: simulateError ? 'cache-only' : 'cache-first',
        });
        if (data?.places?.places?.[0]) {
            placesResponse = data.places.places[0];
        }
    } catch (error) {
        const errorToThrow = new Error(
            'ServerError Could not get place information. PerfDatapointName: PlacesGetPlace'
        );
        perfDatapoint.endWithError(DatapointStatus.ServerError, error.message);
        throw errorToThrow;
    }
    if (placesResponse == undefined) {
        const error = new Error(
            'NotFound: Could not find place information. PerfDatapointName: PlacesGetPlace'
        );
        perfDatapoint.endWithError(DatapointStatus.ClientError, error);
        throw error;
    }

    perfDatapoint.end();
    addPlaceToCache(placesResponse);
    return placesResponse;
}
