import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { useLiveQuery } from 'dexie-react-hooks';
import { logGreyError } from 'owa-analytics';
import { getTimestamp, owaDate, startOfDay } from 'owa-datetime';
import { getUserTimeZone } from 'owa-session-store';

import { placesPeopleTable } from '../database';
import { fetchCollaborators } from '../utils/fetchCollaborators';
import { fetchTimezoneSettings } from '../utils/fetchTimezoneSettings';

import type { OwaDate } from 'owa-datetime';

import type { PeopleInfo } from '../database';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
/**
 * @param collaboratorType: The collaborator type we want to grab from the cache
 * @param date: the date for which we want the people
 * @returns All the collaborators for the given date range in the form of {email, location, date}
 */
export function usePeopleFromCacheForDateRange(
    date: OwaDate,
    filterPeopleDataFunc?: (peopleInfo: PeopleInfo) => boolean
) {
    const currentOwner = getUserExternalDirectoryId();

    return useLiveQuery(
        async () => {
            try {
                const timezoneSettings = await fetchTimezoneSettings(currentOwner);

                if (timezoneSettings?.timezone !== getUserTimeZone()) {
                    await placesPeopleTable.clear();
                    return undefined;
                }

                const collaboratorEmails = await fetchCollaborators();

                const peopleData = await fetchPeopleDataForDateRange(
                    currentOwner,
                    date,
                    collaboratorEmails,
                    filterPeopleDataFunc
                );

                return peopleData.map(row => ({
                    email: row.email as UserEmailAddress,
                    location: row.location,
                    date: owaDate(getUserTimeZone(), row.startOfDay),
                }));
            } catch (e) {
                logGreyError('PlacesPeopleCache_usePeopleFromCacheForDateRange', e);
                return [];
            }
        },
        [],
        []
    );
}

/**
 * Fetch people data for a specific date range and filter by the given criteria.
 */
async function fetchPeopleDataForDateRange(
    currentOwner: string,
    date: OwaDate,
    collaboratorEmails: string[],
    filterPeopleDataFunc?: (peopleInfo: PeopleInfo) => boolean
) {
    const peopleData = await placesPeopleTable
        .where({
            owner: currentOwner,
            startOfDay: getTimestamp(startOfDay(date)),
        })
        .toArray();

    const seen = new Set();
    return peopleData
        .filter(item => {
            const key = `${item.email}-${item.date}`;
            if (seen.has(key)) {
                return false;
            } else {
                seen.add(key);
                return true;
            }
        })
        .filter(row => collaboratorEmails.includes(row.email))
        .filter(filterPeopleDataFunc ?? (() => true));
}
