import { getISOString, startOfDay } from 'owa-datetime';
import { createObservableOperation } from 'owa-observable-operation';

import getPeopleInMeetingsFromCalendar from '../services/getPeopleInMeetingsFromCalendar';

import type { DateRange } from 'owa-datetime-utils';
import type { OwaDate } from 'owa-datetime';

const {
    start: loadPeopleInMeetings,
    isInProgress: isPeopleInMeetingsInProgress,
    onSucceeded: loadPeopleInMeetingsSucceeded,
    getLastError: getPeopleInMeetingsLastError,
} = createObservableOperation(
    'PLACES_LOAD_PEOPLE_IN_MEETINGS_LOCATIONS',
    (dateRange: DateRange, dateToCompareFWH: OwaDate) => ({
        dateRange,
        dateToCompareFWH,
        key: getKey(dateRange),
    }),
    ({ key }) => key,
    ({ dateRange, dateToCompareFWH }) =>
        getPeopleInMeetingsFromCalendar(dateRange, dateToCompareFWH)
);

/**
 * Gets the key to store to read data in peopleInMeetings
 */
export function getKey(dateRange: DateRange) {
    return `${getISOString(startOfDay(dateRange.start))}_${getISOString(
        startOfDay(dateRange.end)
    )}`;
}

export {
    loadPeopleInMeetings,
    isPeopleInMeetingsInProgress,
    loadPeopleInMeetingsSucceeded,
    getPeopleInMeetingsLastError,
};
