import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getAccessTokenforResource } from 'owa-tokenprovider';

export const getAuthToken = async (): Promise<string> => {
    const presenceAuthToken = 'https://presence.teams.microsoft.com';

    const token = await getAccessTokenforResource(
        presenceAuthToken, // resource
        'presence', // apiName
        undefined, // requestId
        undefined, // targetTenantId
        undefined, // wwwAuthenticateHeader
        false, // preferIdpToken
        getModuleContextMailboxInfo() // mailboxInfo
    );

    if (!token) {
        throw new Error('Presence: No access token for presence service');
    }

    return token;
};
