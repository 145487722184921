import React from 'react';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { AccMessageBar } from './AccMessageBar';
import { clickForMoreLabel } from './AccOutageMessageBar.locstring.json';
import { messageBar } from './AccOutageMessageBar.scss';

import type { LocalizedString } from 'owa-localize';
import type { AccMessageBarAction } from './AccMessageBar';

export function AccOutageMessageBar({
    showOutageBar,
    outageMessage,
    outageLinkUri,
    target = undefined,
    customMessageBarType,
}: {
    showOutageBar: boolean;
    outageMessage?: LocalizedString | string;
    outageLinkUri?: string;
    target?: string;
    customMessageBarType?: MessageBarType;
}) {
    const actions: AccMessageBarAction[] = React.useMemo(
        () =>
            outageLinkUri
                ? [
                      {
                          key: 'moreDetails',
                          textResourceId: clickForMoreLabel,
                          href: outageLinkUri,
                      },
                  ]
                : [],
        [outageLinkUri]
    );

    const barType = customMessageBarType ?? MessageBarType.severeWarning;

    return showOutageBar ? (
        <AccMessageBar
            className={messageBar}
            messageBarType={barType}
            message={outageMessage}
            displayLinkAtEnd={false}
            actions={actions}
            target={target}
        />
    ) : null;
}
